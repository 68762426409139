import {Alert, Button, Image, Input, message} from "antd";
import Password from "antd/es/input/Password";
import React, {useEffect, useState} from "react";
import {EyeInvisibleOutlined, EyeTwoTone, PoweroffOutlined} from '@ant-design/icons';
import {GlobalUtil} from "../utils/GlobalText";
import {UserInfoManager} from "../utils/UserInfoManager";
import {useNavigate} from "react-router-dom";
import Api from "../utils/Api";
import {isValidEmail} from "../utils/Utils";
import {StyleUtils} from "../utils/Style";
import {ApiCode} from "../data/common";
import events from "../utils/events";
import {AppEvent, AppMessageType} from "../data/events";
import {AntdButton, AntdInput} from "../views/commonview";
import LabelInput from "../components/LabelInput";
import "./register.css"

const ForgetStep1Page: React.FC = () => {
    const navigate = useNavigate();
    let [email, setEmail] = useState("")
    let [loading, setLoading] = useState(false)
    const height = window.innerHeight * 0.8
    return (
        <div className="top-doc " style={{
            background: `url(${StyleUtils.getImage("top_bg.png")}) no-repeat center center`,
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            padding: `${StyleUtils.getHeight(36)} 0`,
            justifyContent: "center"
        }}>
            <div className={"registercard"} style={{
                borderRadius: StyleUtils.getWidth(12),
                minHeight: StyleUtils.getWidth(220),
                background: StyleUtils.theme().boardBg,
            }}>
                <div style={{
                    color: StyleUtils.theme().fontColor_2,
                    fontSize: StyleUtils.getFont(28),
                   
                }}>
                    {GlobalUtil.t("forget1.title")}
                </div>
                <LabelInput
                    topMargin={9} label={GlobalUtil.t("forget1.emailTip")} inputType={"text"}
                    placeholder={GlobalUtil.t("forget1.emailTip")} onChange={(value) => setEmail(value)}/>
                {email && !isValidEmail(email) && <div style={{
                    fontSize: 12,
                    color: "red",
                    marginTop: StyleUtils.getHeight(2)
                }}>{GlobalUtil.t('forget1.emaliErrorTip')}</div>
                }


                <AntdButton>
                    <Button
                        size={"large"}
                        style={{
                            width: "80%", padding: 0, marginTop: StyleUtils.getHeight(23), fontSize: "1rem"
                        }}
                        type="primary"
                        loading={loading} disabled={!isValidEmail(email)} onClick={async () => {
                        setLoading(true)
                        try {
                            let res = await Api.sendForgetCode(email)
                            if (res.code === ApiCode.SUCCESS) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.SUCCESS,
                                    message: GlobalUtil.t("forget1.successTip")
                                })
                            } else {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("forget1.errorTip")
                                })
                            }
                        } catch (error) {

                        }
                        setLoading(false)
                    }}>{GlobalUtil.t('forget1.sendCode')}</Button>
                </AntdButton>

                <div style={{
                        marginTop: StyleUtils.getHeight(2),
                        position: "relative",
                        display: "flex", justifyContent: "space-between",
                        height: "50px"
                    }}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: 'center'}}>
                        <div style={{
                            fontSize: StyleUtils.getFont(12),
                            cursor: "pointer",
                            display: "flex",
                            left: "0",
                            color: StyleUtils.theme().mainButtonColor
                        }} onClick={() => {
                            navigate("/user/login")
                        }}>{GlobalUtil.t("forget1.backBignIn")}</div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default ForgetStep1Page;