import React, { CSSProperties, useState } from "react";
import events, { useEventBus } from "./events";
import { AppEvent } from "../data/events";


export interface Theme {
    mainColor: string  //全局背景色
    mainButtonColor: string  //主按钮颜色
    mainItemColor: string  //下拉用户item选中背景色
    disabledButtoinColor: string //禁用按钮颜色
    mainButtonDisableColor: string
    mainButtonColorFont_Disable: string
    fontColor_1: string  //普通文字
    fontColor_2: string  //标题级别
    fontColor_3: string  //灰字
    fontColor_placeholder: string  //placeholder
    dividerColor: string //分割线
    boardBg: string   //卡片背景
    boardB2: string   //卡片背景
    appIcon: string  //app icon
    echart_line_color_1: string
    work_group_bg_color: string
    work_group_border_color: string
    work_group_normal_border_color: string
    home_guide_item_color_1: string
    home_guide_item_color_2: string
    user_item_left_color: string
    users_box_shadow: string
    line_color: string
}

export class DarkTheme implements Theme {
    line_color = "#DDDDDD";
    home_guide_item_color_1 = "#2F3133";
    home_guide_item_color_2 = "#090A0A";
    fontColor_3 = "#899199";
    mainButtonDisableColor = "#1A2031";
    work_group_bg_color = "#090A0A";
    work_group_border_color = "#4374FF";
    work_group_normal_border_color = "#FFFFFF20"
    mainButtonColorFont_Disable = "#899199";
    echart_line_color_1 = "#4374FF";
    appIcon = require("../images/icon.png");
    boardBg = "#17181A";
    boardB2 = "#17181A";
    mainColor = "#090A0A"; //F5F7F9
    mainButtonColor = "#4374FF";
    mainItemColor = "#17181A";
    disabledButtoinColor = "#090A0A"
    fontColor_1 = "#DDDDDD"
    fontColor_2 = "#FFFFFF"; //#222426
    fontColor_placeholder = "#aaaaaac6"
    dividerColor = "#2F3133"
    user_item_left_color = "#2F3133"
    users_box_shadow = "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)"

}
export class LightTheme implements Theme {
    line_color = "#E7E9EA";
    home_guide_item_color_1 = "#E6E6E6";
    home_guide_item_color_2 = "#FFFFFF";
    fontColor_3 = "#899199";
    mainButtonDisableColor = "#2F65FF";
    work_group_border_color = "#4374FF";
    work_group_normal_border_color = "#FFFFFF20"
    work_group_bg_color = "#E1E8FA";
    mainButtonColorFont_Disable = "#899199";
    echart_line_color_1 = "#2F65FF";
    appIcon = require("../images/icon.png");
    boardBg = "white";
    boardB2 = "#E9EFFF";
    mainColor = "#F5F7F9" //F5F7F9
    mainButtonColor = "#2F65FF"
    mainItemColor = "#F5F9FF"
    disabledButtoinColor = "#E6E6E6"
    fontColor_1 = "#2E3033"
    fontColor_2 = "#222426"; //#222426
    fontColor_placeholder = "#aaaaaac6"
    dividerColor = "#E6E6E6";
    user_item_left_color = "#E6E6E6"
    users_box_shadow = "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)"
}


export class StyleUtils {
    public static isDark = localStorage.getItem("style") === "true"
    static ICON = require("../images/icon.png")
    static ICON_EXPAND_DOWN = require("../images/icon_expand_down.png")
    static ICON_EXPAND_UP = require("../images/icon_expand_up.png")
    static BG_TOP = StyleUtils.getImage("top_bg.png")
    static ICON_INCOME_PAYOUT = require("../images/icon_icome_payout.png")
    static ICON_INCOME_ALL = require("../images/icon_income_all.png")
    static ICON_INCOME_BALANCE = require("../images/icon_income_balance.png")
    static ICON_INCOME_yesterday = require("../images/icon_income_yesterday.png")
    static LG_FONT_COLOR = "linear-gradient(90deg, #2FF1D9 0%, #1EA9E8 17%, #2E66FF 49%, #5E42EE 84%, #B151F2 100%)"
    private static light_theme = new LightTheme()
    private static dark_theme = new DarkTheme()
    public static widthRatio = document.body.clientWidth / 1080
    private static heightRatio = 1;//window.screen.height / 766
    public static WIDHT = document.body.clientWidth
    public static HEIGHT = document.body.clientHeight
    public static isInit = false
    public static init() {
        if (this.isInit) {
            return
        }
        this.isInit = true
        this.setCssStyle()
        window.onresize = () => {
            StyleUtils.WIDHT = document.body.clientWidth
            StyleUtils.HEIGHT = document.body.clientHeight
            StyleUtils.widthRatio = StyleUtils.WIDHT / 1080
            if (this.widthRatio < 0.6) {
                this.widthRatio = 0.6
            }
            StyleUtils.heightRatio = StyleUtils.widthRatio;// StyleUtils.HEIGHT/766
            events.emit(AppEvent.ChangeStyle, "")
        }
    }

    public static setCssStyle() {
        var root = document.querySelector(":root") as HTMLElement;
        root.style.setProperty('--board-color', StyleUtils.theme().boardBg)
        root.style.setProperty('--fontColor_2', StyleUtils.theme().fontColor_2)
        root.style.setProperty('--lineColor', StyleUtils.theme().line_color)

    }
    public static changeStyle() {
        StyleUtils.isDark = !StyleUtils.isDark
        localStorage.setItem("style", StyleUtils.isDark + "")
        this.setCssStyle()
        events.emit(AppEvent.ChangeStyle, "")
    }
    public static theme(): Theme {
        if (StyleUtils.isDark) {
            return StyleUtils.dark_theme
        }
        return StyleUtils.light_theme
    }
    public static getImage(name: string): string {
        if (StyleUtils.isDark) {
            return StyleUtils.getDarkImage(name)
        }
        return StyleUtils.getLightImage(name)
    }
    public static getLightImage(name: string): string {
        return require("../images/" + name)
    }
    public static getDarkImage(name: string): string {
        return require("../images/dark/" + name)
    }
    public static textWrap(): CSSProperties {
        return { display: "1", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }
    }

    public static fontStyle_1(): CSSProperties {
        return { color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(0.88), fontWeight: "400" }
    }
    public static fontStyle_2(): CSSProperties {
        return { color: StyleUtils.theme().fontColor_2, fontSize: StyleUtils.getFont(1), fontWeight: "500" }
    }
    public static fontStyle_3(): CSSProperties {
        return { color: StyleUtils.theme().fontColor_3, fontSize: "1rem", fontWeight: "400" }
    }
    public static getBorderColor(): string {
        if (StyleUtils.isDark) {
            return "#2F3133"
        }
        return "#E6E6E6"
    }
    public static getWidth(width: number): string {
        return (width * StyleUtils.widthRatio).toFixed(0) + "px"
    }
    public static getFontRem(size: number): string {
        //rem  =  size* /12*widthRatio
        return size + "rem"
    }
    public static getFont(size: number): string {
        //rem  =  size* /12*widthRatio
        let rem = size * 0.11 // * (12 / 16);
        // console.log("size to rem ", size, rem, StyleUtils.widthRatio)
        // return rem + "rem"
        // return (size * this.widthRatio*(16 / 12)) + "px"
        return size + "px"
    }
    public static getHeight(height: number): string {
        return (height * StyleUtils.widthRatio).toFixed(0) + "px"
    }

    public static EAreaColor = [
        // 线条色               top色                           bottom色
        ["rgba(47,101,255)", "rgba(47,101,255,0.33)", "rgba(47,101,255,0)"],
        ["rgba(99, 238, 84)", "rgba(99, 238, 84, 0.33)", "rgba(99, 238, 84,0)"],
        ["rgba(248, 217, 41)", "rgba(248, 217, 41, 0.33)", "rgba(248, 217, 41,0)"],
        ["rgba(240, 32, 59)", "rgba(240, 32, 59, 0.33)", "rgba(240, 32, 59,0)"],
        ["rgba(239, 101, 9)", "rgba(239, 101, 9, 0.33)", "rgba(239, 101, 9,0)"],
        ["rgba(79, 67, 146)", "rgba(79, 67, 146, 0.33)", "rgba(79, 67, 146,0)"],
        // ["rgba(210, 84, 238)", "rgba(210, 84, 238, 0.33)", "rgba(210, 84, 238,0)"],
        // ["rgba(210, 84, 238)", "rgba(210, 84, 238, 0.33)", "rgba(210, 84, 238,0)"],
        // ["rgba(210, 84, 238)", "rgba(210, 84, 238, 0.33)", "rgba(210, 84, 238,0)"],
        // ["rgba(210, 84, 238)", "rgba(210, 84, 238, 0.33)", "rgba(210, 84, 238,0)"],
    ]
}


export {

}