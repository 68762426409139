import { Button, Input, Modal, message, Image, Checkbox, Select, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Account, ApiCode, BaseResponse, Currency, GoogleBindInfo, OnlyReadData, ServerUserWalletStatus } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { AntdInput, AntdSelect } from '../views/commonview';
import { AppButton } from '../views/commonview';
import { AntdButton } from '../views/commonview';
import CheckUtils from '../utils/checkUtils';
const CheckboxGroup = Checkbox.Group;

export interface DoubleCheckProps {
    show: boolean
    onClick: (ok: boolean) => void
    data: OnlyReadData | null | undefined
}

const AddOrUpdateOnlyReadModel: React.FC<DoubleCheckProps> = (props) => {
    const PERMISSION_1 = GlobalUtil.t("onlyread.permission_1")
    const PERMISSION_2 = GlobalUtil.t("onlyread.permission_2")
    const PERMISSION_3 = GlobalUtil.t("onlyread.permission_3")
    const plainOptions = [PERMISSION_1, PERMISSION_2, PERMISSION_3];
    const updateData = props.data
    const [loading, setLoading] = useState(false)
    let [desc, setDesc] = useState(updateData?.name)

    let [syncAccount, setSyncAccount] = useState<string[] | null | undefined>(updateData?.accounts?.split(","))
    let [syncCurrency, setSyncCurrency] = useState<string[] | null | undefined>(updateData?.currencies?.split(","))
    let [permission, setPermission] = useState<string[] | null | undefined>(updateData?.accessPermission?.split(",").flatMap((v) => {
        if (v === "1") {
            return PERMISSION_1
        }
        if (v === "2") {
            return PERMISSION_2
        }
        if (v === "3") {
            return PERMISSION_3
        }
        return ""
    }))
    const disable = !desc || !syncAccount?.length || !permission?.length || !syncCurrency?.length || CheckUtils.checkRemarks(desc) !== ""
    const clear = () => {
        setDesc("")
        setPermission([])
        setSyncCurrency([])
        setSyncAccount([])
    }
    useEffect(() => {
        setDesc(updateData?.name)
        setPermission(updateData?.accessPermission?.split(",").flatMap((v) => {
            if (v === "1") {
                return PERMISSION_1
            }
            if (v === "2") {
                return PERMISSION_2
            }
            if (v === "3") {
                return PERMISSION_3
            }
            return ""
        }))
        setSyncCurrency(updateData?.currencies?.split(","))
        setSyncAccount(updateData?.accounts?.split(","))
    }, [props.data])
    const handleCurrencyChange = (value: string[]) => {
        setSyncCurrency(value)
    };
    const handleAccountChange = (value: string[]) => {
        setSyncAccount(value)
    };
    const handlePermissionChange = (value: CheckboxValueType[]) => {
        setPermission(value.flatMap(v => v.toString()))
    };
    return <Modal
        width={"50%"}
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            clear()
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("onlyread.title")}</div>
            <Row align={"bottom"} style={{ columnGap: '.5rem' }}>
                <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>
                    {GlobalUtil.t("onlyread.remark")}
                </div>
                {desc && CheckUtils.checkRemarks(desc) &&
                    <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t("onlyread.remarkTip")}</div>
                }
            </Row>
            <div style={{ marginTop: StyleUtils.getHeight(10) }}>
                <AntdInput>
                    <Input
                        value={desc}
                        style={{}}
                        onChange={(e) => {
                            setDesc(e.target.value.trim())
                        }}
                        placeholder={GlobalUtil.t("onlyread.remarkTip")}
                    />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('onlyread.account')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10), }}>
                <AntdSelect>
                    <Select onChange={handleAccountChange} mode="multiple" style={{ width: "100%" }} value={syncAccount} options={UserInfoManager.accounts.flatMap((v) => { return { "value": v.account, label: v.account } })} />
                </AntdSelect>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('onlyread.currency')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10), }}>
                <AntdSelect>
                    <Select onChange={handleCurrencyChange} mode="multiple" style={{ width: "100%" }} value={syncCurrency} options={UserInfoManager.currencyList.flatMap((v) => { return { "value": v.code, label: v.name } })} />
                </AntdSelect>
            </div>

            <div style={{ marginTop: StyleUtils.getHeight(10) }}>
                <CheckboxGroup options={plainOptions} value={(permission ?? [])} onChange={handlePermissionChange} />
            </div>
            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let syncAccounts = ""
                                syncAccount?.forEach((v) => {
                                    if (syncAccounts.length) {
                                        syncAccounts += ","
                                    }
                                    syncAccounts += v
                                })
                                let syncCurrencies = ""
                                syncCurrency?.forEach((v) => {
                                    if (syncCurrencies.length) {
                                        syncCurrencies += ","
                                    }
                                    syncCurrencies += v
                                })
                                let permissions = ""
                                permission?.forEach((v) => {
                                    let index = 0
                                    if (v === PERMISSION_1) {
                                        index = 1
                                    } else if (v === PERMISSION_2) {
                                        index = 2
                                    } else {
                                        index = 3
                                    }
                                    if (permissions.length) {
                                        permissions += ","
                                    }
                                    permissions += index.toString()
                                })
                                let res: BaseResponse<string>
                                if (updateData) {
                                    res = await Api.updateOnlyRead(updateData.id, syncAccounts, syncCurrencies, desc ?? "", permissions)
                                } else {
                                    res = await Api.addOnlyRead(syncAccounts, syncCurrencies, desc ?? "", permissions)
                                }
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    clear()
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{updateData ? GlobalUtil.t("onlyread.update") : GlobalUtil.t("onlyread.create")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default AddOrUpdateOnlyReadModel;