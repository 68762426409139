import { Card, Dropdown, Input, MenuProps, Row, Space } from "antd";
import { useState } from "react";
import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { UserInfoManager } from "../utils/UserInfoManager";
import { ServerAccountListInfoWithHashrate } from "../data/common";
import { StyleUtils } from "../utils/Style";
import { GlobalUtil } from "../utils/GlobalText";
import { WorkInfoManager } from "../utils/WorkData";
import { useNavigate } from "react-router-dom";
import { AntdInput } from "../views/commonview";
import { EllipsisMiddle } from "../components";
import events from "../utils/events";
import { AppEvent } from "../data/events";

const UserItem = (v: ServerAccountListInfoWithHashrate, onclick: () => void) => {
    let isSelect = v.account === UserInfoManager.shareAccount
    return <div style={{ display: "flex", padding: "8px 24px ", columnGap: "2rem", background: isSelect ? "#F5F9FF" : StyleUtils.theme().mainColor, marginBottom: StyleUtils.getWidth(2) }} onClick={() => {
        onclick()
    }}>
        {/* <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "500", color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {v.account}</div>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "400", marginTop: StyleUtils.getHeight(2), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> (备注: {v.description})</div>
        </div>
        <div style={{ display: "flex", width: "100%", ...StyleUtils.textWrap(), paddingTop: StyleUtils.getHeight(8) }}>
            <div style={{ justifyContent: "right", alignContent: "center", display: "flex", fontSize: StyleUtils.getFont(14), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {v.hashrate}</div>
        </div> */}
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "500", color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {EllipsisMiddle({suffixCount: 4, children: v?.account || ''})}</div>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "400", whiteSpace: "nowrap", marginTop: StyleUtils.getHeight(2), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> ({GlobalUtil.t("account.desc")}: {EllipsisMiddle({suffixCount: 4, children: v?.description || ''})})</div>
        </div>
        <div style={{ display: "flex", width: "100%", ...StyleUtils.textWrap(), overflow: "inherit", paddingTop: StyleUtils.getHeight(8) }}>
            <div style={{ justifyContent: "right", alignContent: "center", display: "flex", fontSize: StyleUtils.getFont(14), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {v.hashrate}</div>
        </div>
    </div>
}
const ShareUserView: React.FC = () => {
    const [isOpen, setOpen] = useState(false)
    const navigate = useNavigate();
    const [searchValuse, setSearchValuse] = useState("")
    const [refresh, setRefresh] = useState(false)

    const handleSearchValue = (v: string) => {
        setSearchValuse(v)
        UserInfoManager.setAccountInfoWithHashrate(v)
    }

    events.on(AppEvent.ON_ACCOUNT_INFO_UPDATE, () => {
        setRefresh(!refresh)
    })
    
    if (!UserInfoManager.shareAccountsWithHashrate.length) {

        return <Row className="flex-grow">
            <div onClick={(e) => {
                navigate("/user/login")
            }} style={{ cursor: "pointer", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>

                <Space className="user-login" style={{ color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(12) }}>
                    {GlobalUtil.t('appbar.login')}
                </Space>

            </div>
        </Row>
    }
    return <Dropdown
        dropdownRender={() => {
            return <Card
                bodyStyle={{ padding: 0 }}
                style={{
                    borderColor: StyleUtils.theme().boardBg,
                    background: StyleUtils.theme().boardBg,
                    padding: 0
                }}
                hoverable
            >
                <div style={{}}>
                    <AntdInput>
                        <Input
                            style={{
                                fontSize: "0.88rem",
                                display: "flex",
                                flex: "auto",
                                maxWidth: "270px",
                                borderRadius: "8px",
                                // border: `1px solid ${StyleUtils.theme().mainColor}`,
                                border: "none",
                                boxShadow: "none",
                                padding: "4px 24px"
                            }}
                            value={searchValuse}
                            autoComplete="off"
                            placeholder={GlobalUtil.t("onlyread.searchTip")}
                            suffix={
                                <SearchOutlined style={{ color: "#899199" }} />
                            }
                            onChange={(e) => {
                                handleSearchValue(e.target.value.trim())
                            }}
                        />
                    </AntdInput>
                    {UserInfoManager.shareAccountsWithHashrate.map((v, index) => {
                        return UserItem(v, () => {
                            setOpen(false)
                            UserInfoManager.updateShareAccout(v.account ?? "")
                        })
                    })}
                </div>
            </Card>
        }}
        open={isOpen}
        placement={"bottomRight"}
        trigger={['click']} onOpenChange={(open) => {
            if(open){
                UserInfoManager.getShareAccountInfo(WorkInfoManager.shareUrl)
            }
            setOpen(open)
        }}>
        <Row className="flex-grow">
            <div onClick={(e) => {
                if (!UserInfoManager.shareAccountsWithHashrate.length) {
                    return
                }
                e.preventDefault()
            }} style={{ cursor: "pointer", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>
                <Space className="user-login" style={{ color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(13), }}>
                    {EllipsisMiddle({suffixCount: 4, children: UserInfoManager.shareAccount || ''})}
                    {isOpen ? <UpOutlined /> : <DownOutlined />}
                </Space>
            </div>
        </Row>
    </Dropdown >
}

export default ShareUserView