import { Button, Card, Checkbox, Col, ConfigProvider, Image, List, Row, Select, Switch, Tooltip } from "antd"
import { StyleUtils } from "../../../../utils/Style";
import { AntdCard } from "../../../../views/commonview";
import Api from "../../../../utils/Api";
import { useEffect, useState } from "react";
import events from "../../../../utils/events";
import { AppEvent, AppMessageType } from "../../../../data/events";
import { ApiCode, ReportListItem } from "../../../../data/common";
import { UserInfoManager } from "../../../../utils/UserInfoManager";
import confirm from "antd/es/modal/confirm";
import { GlobalUtil } from "../../../../utils/GlobalText";

interface SimpleListViewProps {
    style?: React.CSSProperties;
    dataSource?: ReportListItem[];
    renderItem: (item: ReportListItem, index: number) => React.ReactNode;
    itemLayout?: string
}


const SimpleListView: React.FC<SimpleListViewProps> = (props) => {
    return <div style={{ WebkitOverflowScrolling: "touch", display: "flex", flexDirection: "column",rowGap:".75rem", overflow: "auto", overflowX: "auto", paddingBottom: "15px", ...props.style }}>
        {
            props.dataSource?.flatMap((v, i) => {
                return props.renderItem(v, i)
            })
        }
    </div>
}

export const ReportListView = ({ week }: any) => {
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState<ReportListItem[]>([])
    events.on(AppEvent.REPORT_UPDATE_LIST, () => {
        setUpdate(!update)
    })
    const loadData = async () => {
        try {
            let res = await Api.reportList()
            if (res.code === ApiCode.SUCCESS) {
                setData(res.data ?? [])
            }
        } catch (error) {

        }
    }
    const deleteData = async (item: ReportListItem) => {
        confirm({
            title: GlobalUtil.t("delete"),
            content: GlobalUtil.t("report.deleteTip"),
            okText: GlobalUtil.t("confirm"),
            type: "confirm",
            cancelText: GlobalUtil.t("cancel"),
            onOk: async () => {
                try {
                    let res = await Api.deleteReport(item.id ?? "")
                    if (res.code === ApiCode.SUCCESS) {
                        events.emit(AppEvent.REPORT_UPDATE_LIST)
                        events.emit(AppEvent.SHOW_MESSAGE, {
                            type: AppMessageType.ERROR,
                            message: res.msg ?? GlobalUtil.t("successTip")
                        })
                    } else {
                        events.emit(AppEvent.SHOW_MESSAGE, {
                            type: AppMessageType.ERROR,
                            message: res.msg ?? GlobalUtil.t("errorTip")
                        })
                    }

                } catch (e) {
                    events.emit(AppEvent.SHOW_MESSAGE, {
                        type: AppMessageType.ERROR,
                        message: GlobalUtil.t("errorTip")
                    })
                }
            },
            onCancel() {
            },
        });
    }
    useEffect(() => {
        loadData()
    }, [update])
    let dataSource = data.filter((value) => {
        return value.type === (week ? 1 : 2)
    })
    if (!dataSource.length) {
        return <div />
    }
    const title = week ? GlobalUtil.t("report.week") : GlobalUtil.t("report.month")
    return <div style={{ marginTop: "20px" }}>
        <SimpleListView
            dataSource={dataSource}
            renderItem={(item, index) => {
                return <ConfigProvider
                    theme={{
                        "token": {
                            colorBorderSecondary: StyleUtils.theme().mainColor,
                            colorPrimary: StyleUtils.theme().mainButtonColor,
                            colorBorder: "",
                            colorBgContainer: StyleUtils.theme().mainColor,
                        }
                    }}
                ><Card bodyStyle={{ padding: "0.9rem", display: "flex", flexDirection: "row" }} style={{ width: "100%" }}>
                        <div style={{
                            display: "flex",
                            marginRight: ".5rem"
                        }}>
                            <Image style={{ display: "flex" }} preview={false} height={20} width={20} src={(UserInfoManager.currencyList ?? []).find((v) => { return v.code === item.currency })?.icon ?? ""} />

                        </div>
                        <Col flex={1}>
                            <Row justify={"space-between"} align={"middle"} style={{}}>

                                <Row align={"middle"} style={{ columnGap: ".5rem" }}>

                                    <div style={{ marginLeft: StyleUtils.getWidth(2), color: StyleUtils.theme().fontColor_2, fontSize: "1rem", fontWeight: "500" }}>{item.currency}{title}</div>
                                    <Row align={"bottom"} onClick={() => {
                                        events.emit(AppEvent.REPORT_EDIT_ITEM, item)
                                    }}>

                                        <Tooltip title={GlobalUtil.t("edit")}>
                                            {
                                                StyleUtils.isDark ?
                                                    <img width={20} src="/assets/dark/icon_edit.svg" alt="" />
                                                    :
                                                    <img width={20} src="/assets/icon_edit.svg" alt="" />
                                            }
                                        </Tooltip>

                                    </Row>
                                    <Row align={"bottom"} onClick={() => {
                                        deleteData(item)
                                    }}>
                                        <Tooltip title={GlobalUtil.t("delete")}>
                                            {
                                                StyleUtils.isDark ?
                                                    <img width={20} src="/assets/dark/icon_delete.svg" alt="" />
                                                    :
                                                    <img width={20} src="/assets/icon_delete.svg" alt="" />
                                            }
                                        </Tooltip>
                                    </Row>

                                </Row>
                                <Switch onChange={async (checked) => {
                                    try {
                                        let res = await Api.updatePushStatus(item.id ?? "", checked)
                                    } catch (error) {

                                    }
                                }} defaultChecked={item.isPushNotice} />

                            </Row>
                            <div style={{ marginTop: ".5rem", ...StyleUtils.fontStyle_3(), }}>
                                {GlobalUtil.t("report.pushAccount")}: {item.pushAccounts}
                            </div>
                        </Col>
                    </Card>
                </ConfigProvider>
            }}
        />
    </div >
}