import { Dropdown, MenuProps, Space } from "antd";
import React, { useState } from "react";
import { ServerGroup } from "../../../data/common";
import { AppEvent } from "../../../data/events";
import events from "../../../utils/events";
import { WorkInfoManager } from "../../../utils/WorkData";
import { StyleUtils } from "../../../utils/Style";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { GlobalUtil } from "../../../utils/GlobalText";

const WorkGroupDropItemView = ({ item, click, showArrow, isOpen }: any) => {
    if (!item) {
        return <div />
    }
    let data = item as ServerGroup
    return <div onClick={click} className="downGroup" style={{
        cursor: "pointer",
        alignItems: "center",
        // width: "100%",
        display: "flex",
        background: StyleUtils.isDark ? StyleUtils.theme().work_group_bg_color : "#fff",
        flexDirection: "row",
        borderRadius: "8px",
        border: `1px solid ${StyleUtils.isDark ? "" : "#f5f7f9"}`,
        padding: "4px 12px",
    }}>
        <div style={{ width: "100%", display: "flex", overflow: "auto", flexDirection: "row", justifyContent: "space-between", gap: "1.5rem" }}>
            <div style={{ color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", display: "flex", flexDirection: "row" }}>
                {data.groupName ?? GlobalUtil.t("work.all")}({data.allSize})
            </div>
            <div style={{ justifyContent: "flex-end", alignItems: "center", columnGap: ".5rem", display: "flex", flexDirection: "row" }}>
                <div style={{ color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", ...StyleUtils.textWrap() }}>
                    {data.groupHashrate}
                </div>
                {showArrow && <div style={{ display: "flex" }}> {isOpen ? <UpOutlined style={{ color: StyleUtils.theme().fontColor_2 }} /> : <DownOutlined style={{ color: StyleUtils.theme().fontColor_2 }} />}</div>}
            </div>
        </div>


    </div>
}

export const WorkGroupDropView: React.FC = () => {
    const [selectGroup, setSelectGroup] = useState(0)
    let groupData = (WorkInfoManager.getGroupData()?.group ?? [])
    const [update, setUpdate] = useState(false);
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setUpdate(!update)
    })
    let selectData = groupData[selectGroup];
    const [isOpen, setOpen] = useState(false)
    const items: MenuProps['items'] = groupData.map((item, index) => {
        return {
            key: index,
            label: <WorkGroupDropItemView click={() => {
                setOpen(false)
                setSelectGroup(index)
                WorkInfoManager.updateGroupId(item.groupId)
            }} item={item}/>,
        }
    })
    return (
        <Dropdown
            overlayClassName="group"
            menu={{ items }}
            open={isOpen}
            arrow={true}
            trigger={['click']}
            onOpenChange={(open) => {
                setOpen(open)
            }}
        >
            <Space>
                <WorkGroupDropItemView showArrow={true} open={isOpen} click={(e: any) => {
                    setOpen(true)
                }} item={selectData} />
            </Space>
        </Dropdown>
    )
}
