import { Alert, Button } from "antd";
import React, { useState } from "react";
import { GlobalUtil } from "../utils/GlobalText";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../utils/Api";
import { StyleUtils } from "../utils/Style";
import events from "../utils/events";
import { AppEvent, AppMessageType } from "../data/events";
import { ApiCode } from "../data/common";
import { AntdButton } from "../views/commonview";
import LabelInput from "../components/LabelInput";
import "./register.css"
import { isValidPwd } from "../utils/Utils";

const ForgetStep2Page: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let [isLoading, setLoading] = useState(false)
    let [error, setError] = useState("")
    let [pwd, setPwd] = useState("")
    let [confirmPwd, setConfirmPwd] = useState("")
    let registerContinue = (pwd === confirmPwd && (pwd !== "")) && isValidPwd(pwd)
    const height = window.innerHeight * 0.8
    return (
        <div className="top-doc " style={{
            background: `url(${StyleUtils.getImage("top_bg.png")}) no-repeat center center`,
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            padding: `${StyleUtils.getHeight(36)} 0`,
            justifyContent: "center"
        }}>
            <div className={"registercard"} style={{
                borderRadius: StyleUtils.getWidth(12),
                minHeight: StyleUtils.getWidth(220),
                background: StyleUtils.theme().boardBg,
            }}>

                <div style={{
                    color: StyleUtils.theme().fontColor_2,
                    fontSize: StyleUtils.getFont(28),
                    fontWeight: "500"
                }}>
                    {GlobalUtil.t("forget2.title")}
                </div>

                {error && <Alert message={error} type="error" />}


                <LabelInput
                    topMargin={9} inputType={"password"}
                    placeholder={GlobalUtil.t("forget2.password")} onChange={(value) => setPwd(value)}
                    label={
                        <>
                            {GlobalUtil.t("forget2.password")}
                            {pwd && !isValidPwd(pwd) &&
                                <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t('register.pwdTip')}</div>
                            }
                        </>
                    }
                />
                <LabelInput
                    topMargin={9} inputType={"password"}
                    placeholder={GlobalUtil.t("forget2.confirmPwd")} onChange={(value) => setConfirmPwd(value)}
                    label={
                        <>
                            {GlobalUtil.t("forget2.confirmPwd")}
                            {confirmPwd !== pwd &&
                                <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t('forget2.pwdErrorTip')}</div>
                            }
                        </>
                    }
                />
                <AntdButton>
                    <Button
                        size={"large"}
                        style={{
                            width: "80%", padding: 0, marginTop: StyleUtils.getHeight(23), fontSize: "1rem"
                        }}
                        type="primary"
                        disabled={!registerContinue}
                        loading={isLoading}
                        onClick={() => {
                            setError("")
                            setLoading(true)
                            setTimeout(async () => {
                                try {
                                    let search = location.search.replaceAll("&amp;", "&")
                                    const queryParams = new URLSearchParams(search)
                                    let token = queryParams.get("token")
                                    if (token) {
                                        let res = await Api.getResetPasswordInfo(token)
                                        if (res.code !== ApiCode.SUCCESS) {
                                            events.emit(AppEvent.SHOW_MESSAGE, {
                                                type: AppMessageType.ERROR,
                                                message: GlobalUtil.t("forget2.errorTip")
                                            })

                                            setLoading(false)
                                            return
                                        }
                                    } else {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.ERROR,
                                            message: GlobalUtil.t("forget2.errorTip")
                                        })

                                        setLoading(false)
                                        return
                                    }

                                    let res = await Api.resetPassword(pwd)
                                    if (res.code === ApiCode.SUCCESS) {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.SUCCESS,
                                            message: GlobalUtil.t("forget2.successTip")
                                        })
                                        navigate("/user/login")
                                    } else {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.ERROR,
                                            message: GlobalUtil.t("forget2.errorTip2")
                                        })
                                    }
                                } catch (e) {}
                                setLoading(false)
                            }, 2000)
                        }}
                    >
                        {GlobalUtil.t("forget2.btnConfirm")}
                    </Button>
                </AntdButton>
            </div>
        </div>
    );
};

export default ForgetStep2Page;