import { useEffect, useState } from "react"
import { ServerPayoutInfo } from "../../data/common"
import Api from "../../utils/Api"
import { AppEvent, AppMessageType } from "../../data/events"
import events from "../../utils/events"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import moment from "moment"
import { ConfigProvider, Dropdown } from "antd"
import CheckPayoutModal from "./CheckPayoutModal"
import { StyleUtils } from "../../utils/Style"
import { AntdTable } from "../../views/commonview"
import { WorkInfoManager } from "../../utils/WorkData"
import { UserInfoManager } from "../../utils/UserInfoManager"
import { GlobalUtil } from "../../utils/GlobalText"


interface PayoutTableProps {
  shareUrl: string
}
export const PayoutTableView: React.FC<PayoutTableProps> = (props) => {
  const [data, setData] = useState<ServerPayoutInfo[]>([])
  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const loadData = async () => {
    try {
      let res = await Api.getPayoutList(props.shareUrl,tableParams.current,tableParams.pageSize)
      if (res.code) {
        setTableParams({
          ...tableParams,
          total: res.data?.total,
        });
        setData(res.data?.records ?? [])
      }
    } catch (error) {

    }

  }
  const [update, setUpdate] = useState(false)
  const handleTableChange = (
    pagination: TablePaginationConfig,
  ) => {
    setTableParams(pagination);

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams?.pageSize) {
      setData([]);
    }
  };
  useEffect(() => {
    loadData();
  }, [JSON.stringify(tableParams)]);

  events.on(AppEvent.REFRES, () => {
    setUpdate(!update)
  })
  useEffect(() => {
    loadData()
  }, [update])
  const columns: ColumnsType<ServerPayoutInfo> = [
    {
      title: GlobalUtil.t("income.time"),
      dataIndex: 'payoutDate',
      render: (value: number) => {
        return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
          {moment(value).format('YYYY-MM-DD')}
        </div>
      }
    },
    {
      title: GlobalUtil.t("income.money"),
      dataIndex: "amount",
      render(value, record, index) {
        return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
          {value} {UserInfoManager.getShowCurrency()}
        </div>
      },
    },
    {
      title: GlobalUtil.t("income.address"),
      dataIndex: 'address',
      render(value, record, index) {
        return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
          {value}
        </div>
      },
    },
    {
      title: GlobalUtil.t("income.payStatus"),
      dataIndex: "",
      render(value, record, index) {
        let status = ""
        if (record.status === 1) {
          status = GlobalUtil.t("income.payWait")
        } else if (record.status === 2) {
          status = GlobalUtil.t("income.paySuccess")
        } else {
          status = GlobalUtil.t("income.payFail")
        }
        return <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>{status}</div>
          <Dropdown
            placement={"topCenter"}
            dropdownRender={() => {
              return <div style={{ background: "white", borderRadius: StyleUtils.getWidth(6), padding: StyleUtils.getWidth(10) }}>
                <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}> {record.transactionId}</div>
                <div
                  style={{ fontSize: "0.88rem", color: StyleUtils.theme().mainButtonColor, fontWeight: "400" }}
                  onClick={() => {
                    navigator.clipboard.writeText(record.transactionId ?? "")
                    events.emit(AppEvent.SHOW_MESSAGE, {
                      type: AppMessageType.SUCCESS,
                      message: GlobalUtil.t('successTip')
                    })
                  }}>{GlobalUtil.t('copy')}</div>
              </div>

            }}>
            <div>
              <div style={{ marginLeft: StyleUtils.getWidth(20), fontSize: "0.88rem", color: StyleUtils.theme().mainButtonColor, fontWeight: "400" }}>{GlobalUtil.t("income.tId")}</div>
            </div>
          </Dropdown>

        </div>
      },

    }
  ];
  return <div style={{ width: "100%", paddingTop: WorkInfoManager.shareUrl ? "1rem" : "0rem", background: StyleUtils.theme().boardBg }}>
    <AntdTable
    >
      <Table
        pagination={tableParams}
        onChange={handleTableChange}
        className={StyleUtils.isDark ? "tableD" : "tableL"}
        style={{ borderRadius: WorkInfoManager.shareUrl ? "0" : "", marginLeft: WorkInfoManager.shareUrl ? StyleUtils.getWidth(12) : 0, marginRight: WorkInfoManager.shareUrl ? StyleUtils.getWidth(12) : 0 }}
        columns={columns}
        dataSource={data}
      />
    </AntdTable>
  </div>
}