import React, { useEffect, useState } from "react";
import Api from "../../utils/Api";
import { Card, Row } from "antd";
import { useEventBus } from "../../utils/events";
import { AppEvent } from "../../data/events";
import { IncomeTableView } from "./IncomeTableView";
import { PayoutTableView } from "./PayoutTableView";

import { StyleUtils } from "../../utils/Style";
import { GlobalUtil } from "../../utils/GlobalText";
import ExportModel from "../../modal/ExportModel";
import './index.css'
import { IncomeCardView } from "./components/IncomeCardView";
import { UserInfoManager } from "../../utils/UserInfoManager";



const IncomePage: React.FC = () => {
  const [update, setUpdate] = useState(true)
  useEventBus(AppEvent.ChangeStyle, (changeType: string) => {
    setUpdate(!update)
  })
  useEventBus(AppEvent.REFRES, () => {
    setUpdate(!update)
  })
  const [mode, setMode] = useState("1")
  const [exports, setExport] = useState(false)
  const selectIncome = mode === "1"
  return (
    <div style={{ display: "flex", justifyContent: "center", zIndex: 100, paddingTop: StyleUtils.getWidth(36), paddingBottom: StyleUtils.getWidth(36), width: "100%", height: "80%", backgroundColor: StyleUtils.theme().mainColor }}>
      <ExportModel mode={mode} show={exports} onClick={() => {
        setExport(false)
      }} />
      <div style={{ width: "100%", height: "100%" }}>
        <IncomeCardView shareUrl="" />
        <Card style={{ background: StyleUtils.theme().boardBg, borderColor: StyleUtils.theme().boardBg, marginTop: StyleUtils.getHeight(36), display: "flex", flexDirection: "column", }}>
          <Row justify={"space-between"} align={"middle"} style={{ marginBottom: StyleUtils.getWidth(14) }}>
            <Row align={"middle"} style={{gap:".55rem"}}>
              <div style={{ height: "1.0rem", width: "0.3rem", background: "#2F65FF", alignItems: "center", borderRadius: "8px" }} />
              <div style={{ color: StyleUtils.theme().fontColor_2, fontSize: "1rem",fontWeight: "500" }}>{UserInfoManager.getShowCurrency()}</div>
              <div style={{ display: "flex", flexDirection: "row", background: StyleUtils.theme().mainColor, borderRadius: "8px" }}>
                <div style={{ padding: "4px 12px", cursor: "pointer", background: (!selectIncome) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: "8px", color: !selectIncome ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
                  setMode("1")
                }}>{GlobalUtil.t("income.income")}</div>
                <div style={{ padding: "4px 12px", cursor: "pointer", background: (selectIncome) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: "8px", color: selectIncome ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
                  setMode("2")
                }}>{GlobalUtil.t("income.payout")}</div>
              </div>
            </Row>
            <div onClick={() => {
              setExport(true)
            }} style={{ padding: "4px 12px", marginLeft: "0.66rem", width: "4.5rem", background: StyleUtils.theme().mainColor, borderRadius: "8px", cursor: "pointer", color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} >
              {GlobalUtil.t("income.export")}
            </div>
          </Row>

          <div style={{ display: "flex", width: "100%" }}>
            {
              mode === "1" ? <IncomeTableView shareUrl={""} /> : <PayoutTableView shareUrl={""} />
            }
          </div>
        </Card>
      </div >
    </div >
  );
};



export default IncomePage;
