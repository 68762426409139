import { Row, Col, Divider, Image } from "antd"
import { Link } from "react-router-dom"
import { StyleUtils } from "../../utils/Style"

export const PcFooter = () => {
    return (
        <Row className="footer">
            <Row className="footer-nav-box">
                <Col
                // span={8}
                >
                    <img width={146} height={32} src="/assets/white_logo.png" alt="" />
                </Col>
                {/* <Col span={5} className="footer-nav-col">
                    <span>
                        About
                    </span>
                    <Link to={""}>HashPool</Link>
                    <Link to={""}>API</Link>
                    <Link to={""}>Quick Start</Link>
                </Col>
                <Col span={6} className="footer-nav-col">
                    <span >
                        Help Center
                    </span>
                    <Link to={""}>Announcement</Link>
                    <Link to={""}>FAQ</Link>
                </Col> */}
     
                <Col style={{
                    ...StyleUtils.fontStyle_2(),
                    color: "#fff",
                    fontSize: '16px',
                    display: "flex",
                    alignItems: "center",
                }}>
                    2024 by <Link to={""}>&nbsp; drpool.io &nbsp;</Link> All Right Reserved
                </Col>
                <Col
                    // span={5} 
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ whiteSpace: 'nowrap', marginRight: ".5rem" }}>
                        Contact Us
                    </span>
                    <ul className="footer-icon">
                        <li><a href="https://twitter.com/DRpool_Services" target='blank' ><img style={{
                            padding: "0.125rem",
                            height: "1.25rem",
                            marginRight: ".75rem"
                        }} src="/assets/twitter.svg" alt="" /></a></li>

                        <li><a href="https://discord.gg/r2zNGKmT" target='blank'><img style={{
                            padding: "0.125rem",
                            height: "1rem",
                            marginRight: ".75rem"
                        }} src="/assets/discord.svg" alt="" /></a></li>
                        <li><a href="mailto:drpool_service@outlook.com" target='blank' ><img style={{
                            padding: "0.125rem",
                            height: "1rem",
                            marginRight: ".75rem"
                        }} src="/assets/email.svg" alt="" /></a></li>
                    </ul>
                </Col>

            </Row>
            {/* <Divider style={{ borderBlockColor: StyleUtils.theme().dividerColor, margin: "2rem 0" }} />
            <Row justify={"space-between"} className="footer-copyright">
                <Col></Col>
                <Col>2024 by <Link to={""}>drpool.io</Link> All Right Reserved</Col>
            </Row> */}
        </Row>
    )
}

