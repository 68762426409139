import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "../locales/en.json"
import zh from "../locales/zh.json"
import i18n, { TFunction } from "i18next";
import React from "react";

const resources = {
    en_US: {
        translation: en,
    },
    zh_CN: {
        translation: zh,
    },
};

i18n.use(LanguageDetector)  //默认当前浏览器语言
    .use(initReactI18next) // 将 i18n 向下传递给 react-i18next
    .init({ //初始化
        resources, //本地多语言数据
        fallbackLng: "zh_CN", //默认当前环境的语言
        detection: {
            caches: ['localStorage', 'sessionStorage'],
        }
    })


class IGlobalUtil {

    globalT!: TFunction;

    init(t: TFunction) {
        this.globalT = t
    }
    changeLanguage = (newLanguage: string): void => {
        i18n.changeLanguage(newLanguage, () => {

        })
    }

    getDefaultLanguage = (): string => {
        return i18n.language
    }
    t = (txt: string): string => {
        return this.globalT(txt)
    }
}

const GlobalUtil = new IGlobalUtil()

export { GlobalUtil }