import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Dropdown, Grid, Image, List, MenuProps, Modal, Radio, Row, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Account, ApiCode, Currency, CurrencyAddress, ServerWalletAddress } from '../../../../data/common';
import { UserInfoManager } from '../../../../utils/UserInfoManager';
import Api from '../../../../utils/Api';
import events, { useEventBus } from '../../../../utils/events';
import { AppEvent, AppMessageType } from '../../../../data/events';
import { GlobalUtil } from '../../../../utils/GlobalText';
import ChangeDescView from './ChangeDescView';
import AddOrUpdateCurrencyWalletView from '../../../../modal/AddOrUpdateCurrencyWalletView';
import SyncWalletAddressView from '../../../../modal/SyncWalletAddressView';
import { Action } from '@remix-run/router';
import { AntdTable, AppButton } from '../../../../views/commonview';
import { text } from 'stream/consumers';
import { StyleUtils } from '../../../../utils/Style';
import { formatStr } from '../../../../utils/Utils';
import './index.css';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Caurael } from '../../../../components/Caurael';
import moment from 'moment';

const { confirm } = Modal;

interface AccountProps {
  onSelectChange: (selectedRows: Account[]) => void
}


enum MoreAction {
  NONE,
  UPDATE_DESC,
  CHANGE_ADDRESS,
  ADD_ADDRESS,
  SYNC_ADDRESS,
  MORE,
  DELETE,
  HIDE
}

// const ExpandView = () => {
//   return 
// }

const AccountListView: React.FC<AccountProps> = (props) => {
  const [expandAccount, setExpandAccount] = useState<Account | null>()
  const [selectCurrency, setSelectCurrency] = useState<Currency | null>(null)
  const [accounts, setAccounts] = useState<Account[]>(UserInfoManager.accounts)
  const [currencyData, setCurrencyData] = useState<ServerWalletAddress | null>()
  const [updateWallet, setUpdateWallet] = useState(false)
  const [action, setAction] = useState<MoreAction>(MoreAction.NONE)
  const [actionAccount, setActionAccount] = useState<Account>()
  const { xs } = useBreakpoint()
  const items: MenuProps['items'] = [
    {
      label: <div onClick={() => { setAction(MoreAction.UPDATE_DESC) }}>{GlobalUtil.t("account.changeDesc")}</div>,
      key: '0',
    },
    {
      label: <div>{GlobalUtil.t("account.alertNotification")}</div>,
      key: '1',
    },
    {
      label: <div style={{ color: (!actionAccount || actionAccount?.isMain) ? "gray" : "" }} onClick={(e) => {
        if (actionAccount?.isMain) {
          return
        }
        setAction(MoreAction.NONE)
        events.emit(AppEvent.DELETE_SUB_ACCOUNT, actionAccount?.account)
      }}>{GlobalUtil.t("delete")}</div>,
      key: '2',
    },
    {
      label: <div style={{ color: (!actionAccount || actionAccount?.isMain) ? "gray" : "" }} onClick={() => {
        if (actionAccount?.isMain) {
          return
        }
        setAction(MoreAction.NONE)
        events.emit(AppEvent.HIDE_ACCOUNT, actionAccount?.account)
      }}>{GlobalUtil.t("account.hidden")}</div>,
      key: '3',
    },
  ];
  const handleExpand = (record: Account) => {
    if (record.account === expandAccount?.account) {
      setExpandAccount(null)
    } else {
      setSelectCurrency(UserInfoManager.currencyList[0])
      setCurrencyData(null)
      setExpandAccount(record)
    }
  }
  const columns: ColumnsType<Account> = [
    {
      title: GlobalUtil.t("account.account"),
      dataIndex: 'account',
      width: xs ? "90px" : "180px",
      render: (value: number) => {
        return <div style={{ fontWeight: "400", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }}>
          {value}
        </div>
      }
    },
    {
      title: GlobalUtil.t("account.remark"),
      dataIndex: 'desc',
      render: (value: number) => {
        return <div style={{ fontWeight: "400", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }}>
          {value}
        </div>
      }
    },
    {
      title: GlobalUtil.t("account.operation"),
      // width: "180px",
      render: (data) => <div style={{ display: "flex", flexDirection: "row" }}>
        <div onClick={() => { handleExpand(data) }} style={{ cursor: "pointer", fontWeight: "400", fontSize: "0.88rem", color: StyleUtils.theme().mainButtonColor }}>
          {GlobalUtil.t("account.paysetting")}
        </div>
        <div style={{ width: StyleUtils.getWidth(20) }}></div>
        <Dropdown
          open={action === MoreAction.MORE && actionAccount?.account === data.account}
          menu={{ items }}
          placement={"bottomCenter"}
          trigger={['click']} onOpenChange={(open) => {
            setActionAccount(open ? data : undefined)
            setAction(MoreAction.MORE)
          }}>
          <div style={{ cursor: "pointer", fontWeight: "400", fontSize: "0.88rem", color: StyleUtils.theme().mainButtonColor }}>
            {GlobalUtil.t("account.more")}
          </div>
        </Dropdown>
      </div>
    },
  ];
  useEventBus(AppEvent.ON_ACCOUNT_INFO_UPDATE, (changeType: string) => {
    setAccounts(UserInfoManager.accounts)
  })
  let loadData = async () => {
    UserInfoManager.getAccountInfo()
  }
  useEffect(() => {
    loadData()
  }, [])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Account[]) => {
      props.onSelectChange(selectedRows)
    },
    getCheckboxProps: (record: Account) => ({
      disabled: record.isMain,
      name: record.account,
    }),
  };

  const [loadingWallet, setLoadingWallet] = useState(false)
  const loadWalletData = async () => {
    setLoadingWallet(true)
    if (selectCurrency && expandAccount) {
      try {
        let res = await Api.getWalletAddress(expandAccount?.account!!, selectCurrency?.code!!)
        setLoadingWallet(false)
        if (res.code === ApiCode.SUCCESS) {
          setCurrencyData(res.data)
        }
      } catch (e) {
      }
    }
  }
  useEffect(() => {
    loadWalletData()
  }, [selectCurrency, expandAccount, updateWallet])

  return (
    <div style={{ paddingTop: "1rem", width: "100%" }}>
      {
        ChangeDescView(actionAccount?.account ?? "", actionAccount?.desc ?? "", action === MoreAction.UPDATE_DESC && actionAccount != null, () => {
          UserInfoManager.getAccountInfo()
          setActionAccount(undefined)
          setAction(MoreAction.NONE)
        })
      }
      <SyncWalletAddressView
        walletAddress={currencyData} account={expandAccount ?? null} currency={selectCurrency ?? null} show={action === MoreAction.SYNC_ADDRESS} onClick={
          (ok) => {
            if (ok) {
              loadWalletData()
            }
            setAction(MoreAction.NONE)
          }

        } />
      <AddOrUpdateCurrencyWalletView walletAddress={action === MoreAction.CHANGE_ADDRESS ? currencyData : null} account={expandAccount ?? null} currency={selectCurrency ?? null} show={action === MoreAction.CHANGE_ADDRESS || action === MoreAction.ADD_ADDRESS} onClick={
        (ok) => {
          if (ok) {
            loadWalletData()
          }
          setAction(MoreAction.NONE)
        }

      } />
      <AntdTable>
        <Table
          // bordered
          className={`${StyleUtils.isDark ? "tableD ckd" : "tableL ckl"} home-table`}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={(item) => { return item.account }}
          columns={columns}
          dataSource={accounts.filter((v) => {
            return !v.hide
          })}
          expandable={{
            expandedRowKeys: [expandAccount?.account ?? ""],
            showExpandColumn: false,
            expandRowByClick: false,
            expandedRowClassName: () => {
              return "expand_row"
            },
            rowExpandable: (record) => expandAccount?.account === record.account,
            expandedRowRender: (item: Account) => {
              let hasCurrencyData = currencyData && (currencyData.address || currencyData.awaitAddress)
              let currency = selectCurrency
              let showAddress = currencyData?.awaitAddress
              if (!showAddress) {
                showAddress = currencyData?.address
              }
              let showAddressFrom = currencyData?.awaitAddressDesc
              if (!showAddressFrom) {
                showAddressFrom = currencyData?.addressDesc
              }
              return <div style={{ width: "100%", margin: "0px" }}>
                <Caurael currency={currency} onClick={(isSelect, v) => {
                  if (!isSelect) {
                    setCurrencyData(null)
                    setSelectCurrency(v)
                  }
                }} />

                <div style={{ display: "flex" }}>
                  {hasCurrencyData ?
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {currencyData?.awaitAddress && <div style={{ marginTop: StyleUtils.getHeight(10), border: "solid", borderColor: "#2989FC", paddingLeft: StyleUtils.getWidth(20), paddingRight: StyleUtils.getWidth(20), }}>请查收邮件，24小时内确认</div>}
                      <div style={{ padding: StyleUtils.getWidth(10), display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ fontSize: "0.88rem", width: "80px", fontWeight: "500", color: StyleUtils.theme().fontColor_1 }}>{currency?.name}</div>
                          <div style={{ fontSize: "0.88rem", marginLeft: StyleUtils.getWidth(7), fontWeight: "500", color: StyleUtils.theme().fontColor_1 }}>{showAddress}</div>
                          {currencyData?.tip && <div style={{ fontSize: "0.88rem", marginLeft: StyleUtils.getWidth(7), fontWeight: "500", color: "red" }}>{ }{currencyData?.tip?.replaceAll("{time}", moment((parseFloat(currencyData?.resumeTime ?? "0"))).format("YYYY-MM-DD HH:mm:ss"))}</div>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ fontSize: "0.88rem", width: "80px", fontWeight: "500", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("addressFrom")}</div>
                          <div style={{ fontSize: "0.88rem", marginLeft: StyleUtils.getWidth(5), fontWeight: "500", color: StyleUtils.theme().fontColor_1 }}>{showAddressFrom}</div>
                        </div>
                      </div>
                      {
                        currencyData?.awaitAddress && <div style={{ display: "flex", flexDirection: "row" }}>
                          <AppButton onClick={async () => {
                            try {
                              let res = await Api.sendActiveEmail(item.account, currency?.code!!)
                              if (res.code === ApiCode.SUCCESS) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                  type: AppMessageType.SUCCESS,
                                  message: GlobalUtil.t("sendSuccessTip")
                                })
                                setUpdateWallet(!updateWallet)
                              } else {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                  type: AppMessageType.ERROR,
                                  message: res.msg ?? GlobalUtil.t("sendErrorTip")
                                })
                              }
                            } catch (e) {
                              events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.ERROR,
                                message: GlobalUtil.t("sendErrorTip")
                              })
                            }
                          }} text={GlobalUtil.t("account.reSendCode")} />
                          <AppButton onClick={async () => {
                            try {
                              let res = await Api.cancelAddress(item.account, currency?.code!!)
                              if (res.code === ApiCode.SUCCESS) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                  type: AppMessageType.SUCCESS,
                                  message: GlobalUtil.t("revocationSuccessTip")
                                })
                                setUpdateWallet(!updateWallet)
                              } else {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                  type: AppMessageType.ERROR,
                                  message: res.msg ?? GlobalUtil.t("revocationErrorTip")
                                })
                              }
                            } catch (e) {
                              events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.ERROR,
                                message: GlobalUtil.t("revocationErrorTip")
                              })
                            }
                          }} style={{ marginLeft: StyleUtils.getWidth(10) }} text={GlobalUtil.t("account.revocation")} />
                        </div>
                      }
                      {
                        !currencyData?.awaitAddress && <div style={{ display: "flex", flexDirection: "row" }}>
                          <div onClick={async () => {
                            let txt = currencyData?.isActivePayout ? GlobalUtil.t("account.pausePay") : GlobalUtil.t("account.resumePay")
                            confirm({
                              title: txt,
                              content: formatStr(GlobalUtil.t("account.payTip"), txt),
                              okText: GlobalUtil.t("confirm"),
                              type: "confirm",
                              cancelText: GlobalUtil.t("cancel"),
                              onOk: async () => {
                                try {
                                  let res = await Api.setPayoutStatus(item.account, currency?.code ?? "", !currencyData?.isActivePayout)
                                  if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                      type: AppMessageType.SUCCESS,
                                      message: GlobalUtil.t("successTip")
                                    })
                                    setUpdateWallet(!updateWallet)
                                  } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                      type: AppMessageType.ERROR,
                                      message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                  }

                                } catch (e) {
                                  events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                  })
                                }
                              },
                              onCancel() {
                              },
                            });
                          }} style={{ cursor: "pointer", padding: "4px 12px", border: "1px solid #2F65FF", borderRadius: "8px", color: StyleUtils.theme().mainButtonColor, fontSize: "0.88rem" }}  >{currencyData?.isActivePayout ? GlobalUtil.t("account.pausePay") : GlobalUtil.t("account.resumePay")}</div>
                          <div
                            style={{ cursor: "pointer", marginLeft: StyleUtils.getWidth(7), marginRight: StyleUtils.getWidth(7), padding: "4px 12px", border: "1px solid #2F65FF", borderRadius: "8px", color: StyleUtils.theme().mainButtonColor, fontSize: "0.88rem" }}
                            onClick={() => {
                              if (!UserInfoManager.mainAccount?.isTwofactorOpen) {
                                events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                              } else {
                                setAction(MoreAction.CHANGE_ADDRESS)
                              }
                            }} color={"geekblue"} >{GlobalUtil.t("account.editAddress")}</div>
                          <div
                            style={{ cursor: "pointer", padding: "4px 12px", border: "1px solid #2F65FF", borderRadius: "8px", color: StyleUtils.theme().mainButtonColor, fontSize: "0.88rem" }}
                            onClick={() => {
                              if (!UserInfoManager.mainAccount?.isTwofactorOpen) {
                                events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                              } else {
                                setAction(MoreAction.SYNC_ADDRESS)
                              }
                            }} color={"geekblue"} >{GlobalUtil.t("account.syncAddress")}</div>
                        </div>
                      }
                    </div> : !loadingWallet && <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ marginTop: StyleUtils.getHeight(20) }}>
                        <Tag onClick={() => {
                          if (!UserInfoManager.mainAccount?.isTwofactorOpen) {
                            events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                          } else {
                            setAction(MoreAction.ADD_ADDRESS)
                          }

                        }} color={"geekblue"} style={{ padding: "4px 12px", cursor: "pointer" }}>{GlobalUtil.t("account.addAddress")}</Tag>
                      </div>
                    </div>

                  }
                </div>
                <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, marginTop: StyleUtils.getHeight(10) }}>{GlobalUtil.t("account.tips")}</div>
              </div>
            }


          }}
        />
      </AntdTable >
    </div >
  );
};

export default AccountListView;