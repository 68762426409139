import { Button, DatePicker, Modal, message, Image, Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import type { Dayjs } from 'dayjs';
import { formatStr } from '../utils/Utils';
import { AntdButton, AntdDatePicker, AntdSelect } from '../views/commonview';

export interface ExportProps {
    show: boolean
    onClick: (ok: boolean) => void
    mode: string
}

const ExportModel: React.FC<ExportProps> = (props) => {
    const [loading, setLoading] = useState(false)
    let [syncAccount, setSyncAccount] = useState<string[] | null | undefined>()
    let [update, setUpdate] = useState(false)
    const [selectTime, setSelectTime] = useState<string[]>([])
    const [date, setDate] = useState<any>(null)
    events.on(AppEvent.ON_ACCOUNT_INFO_UPDATE, () => {
        setUpdate(!update)
    })
    const clear = () => {
        setDate(null)
        setSyncAccount([])
        setSelectTime([])
    }
    const disabledDate = (current: Dayjs) => {
        return current
    };
    const handleAccountChange = (value: string[]) => {
        setSyncAccount(value)
    };
    useEffect(() => {
        if (props.show) {
            UserInfoManager.getAccountInfo()
        }
        clear()
    }, [props.show])
    const disable = !syncAccount?.length || !selectTime.length

    return <Modal
        width={"50%"}
        centered={true}
        maskClosable={false}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {

            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", fontSize: "1rem", color: StyleUtils.theme().fontColor_2, marginBottom: StyleUtils.getHeight(9) }}>{GlobalUtil.t("income.export")}</div>
            <div style={{ color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", marginBottom: StyleUtils.getHeight(9) }}>{GlobalUtil.t("income.exportTime")}</div>
            <AntdDatePicker>
                <DatePicker.RangePicker value={date} onChange={(value, format) => {
                    setDate(value)
                    setSelectTime(format)
                }} />
            </AntdDatePicker>
            <div style={{ color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", marginBottom: StyleUtils.getHeight(9), marginTop: StyleUtils.getHeight(9) }}>{GlobalUtil.t('income.account')}</div>
            <div style={{}}>
                <AntdSelect>
                    <Select onChange={handleAccountChange} mode="multiple" style={{ width: "100%" }} value={syncAccount} options={UserInfoManager.accounts.flatMap((v) => { return { "value": v.account, label: v.account } })} />
                </AntdSelect>
            </div>
            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading}
                        disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let syncAccounts = ""
                                syncAccount?.forEach((v) => {
                                    if (syncAccounts.length) {
                                        syncAccounts += ","
                                    }
                                    syncAccounts += v
                                })
                                let res = await Api.exportRevenue(selectTime[0], selectTime[1], props.mode, syncAccounts)
                                const url = window.URL.createObjectURL(new Blob([res as any],
                                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }));
                                const link = document.createElement('a');
                                link.href = url;
                                const fileName = formatStr("{0}_{1}_{2}.xlsx", UserInfoManager.currency, selectTime[0], selectTime[1])
                                link.setAttribute('download', fileName);
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link)
                                props?.onClick(true)
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("confirm")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default ExportModel;