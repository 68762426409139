import { Row } from "antd";
import { StyleUtils } from "../utils/Style";


const StyleModeView: React.FC = () => {
    return <Row className="flex-grow" style={{margin:0}}>
        <div onClick={(e) => {
            StyleUtils.changeStyle()
        }} className="user-theme-box" style={{ background: StyleUtils.theme().mainColor,}}>

            <div className="user-theme" style={{ color: StyleUtils.theme().fontColor_1, fontWeight: "400", fontSize: "0.88rem", }}>
                {StyleUtils.isDark ?
                    <img src="/assets/icon_dark.svg" width={20} height={20} alt="dark mode" /> :
                    <img src="/assets/icon_light.svg" width={20} height={20} alt="light mode" />
                }
            </div>

        </div>
    </Row>
}

export default StyleModeView