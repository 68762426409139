import { Card, Radio, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import { IncomeTableView } from "../income/IncomeTableView";
import { PayoutTableView } from "../income/PayoutTableView";
import WorkInfoView from "../work/views/WorkInfoView";
import { WorkInfoManager } from "../../utils/WorkData";

import { UserInfoManager } from "../../utils/UserInfoManager";
import events from "../../utils/events";
import { AppEvent } from "../../data/events";
import { StyleUtils } from "../../utils/Style";
import { GlobalUtil } from "../../utils/GlobalText";
import WorkAccountTableView from "../work/views/AccountTableView";
import { AppButton } from "../../views/commonview";
import { IncomeCardView } from "../income/components/IncomeCardView";
import NotFoundPage from "../NotFoundPage";

const SharePage: React.FC = (props) => {
    const [changeStyle, setChangeStyle] = useState(true)
    const [error, setError] = useState(false)
    events.on(AppEvent.ChangeStyle, (changeType: string) => {
        setChangeStyle(!changeStyle)
    })
    let location = useLocation()
    let path = location.pathname.replace("miner/", "share/")
    const [defaultMode, setMode] = useState(1)
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.ERRPR_SHARE_URL, () => {
        setError(true)
    })
    events.on(AppEvent.SHARE_REFRES, () => {
        setUpdate(!update)
    })
    events.on(AppEvent.REFRES, () => {
        setUpdate(!update)
    })
    WorkInfoManager.shareUrl = path
    useEffect(() => {
        WorkInfoManager.init(path)
        UserInfoManager.getShareAccountInfo(path)
        UserInfoManager.getShareCurrencyList(path)
    }, [update])
    useEffect(() => {
        return () => {
            WorkInfoManager.shareUrl = ""
        }
    }, [])
    let view = null

    const navigator = useNavigate()

    if (error) {
        return <NotFoundPage text={
            <Typography.Text style={{ color: StyleUtils.theme().fontColor_2 }}>
                分享链接错误
            </Typography.Text>
        } />
    }


    let TAB = (UserInfoManager.shareAuth?.split(",") ?? []).flatMap((v) => {
        if (v === "2") {
            return { mode: 2, txt: GlobalUtil.t("onlyread.permission_2") }
        } else if (v === "3") {
            return { mode: 3, txt: GlobalUtil.t("onlyread.permission_3") }
        } else {
            return { mode: 1, txt: GlobalUtil.t("onlyread.permission_1") }
        }
    })
    let mode = defaultMode
    let hasMode = false
    let hasWorkManager = false

    TAB.forEach((value) => {
        if (value.mode === 1) {
            hasWorkManager = true
        }
        if (value.mode === mode) {
            hasMode = true
        }
    })

    if (!hasMode) {
        mode = TAB[0].mode
    }

    if (UserInfoManager.shareAuth) {
        if (mode === 2) {
            view = <IncomeTableView shareUrl={path} />
        } else if (mode === 3) {
            view = <PayoutTableView shareUrl={path} />
        } else {
            view = <div style={{ display: "flex", flexDirection: "column" }}>
                <WorkInfoView />
            </div>

        }
    }
    return <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: StyleUtils.getWidth(36), paddingBottom: "5%", background: StyleUtils.theme().mainColor }}>
        <div style={{ width: "100%", }}>
            <IncomeCardView shareUrl={path} />
            <div style={{ height: "3rem", width: "1px" }} />
            {hasWorkManager && <WorkAccountTableView />}
            <div style={{ display: "flex", marginTop: "3rem", background: StyleUtils.theme().boardBg, padding: `${StyleUtils.getWidth(12)} ${StyleUtils.getWidth(12)} 0`, borderRadius: "1rem 1rem 0 0" }}>
                {UserInfoManager.shareAuth && <div style={{ display: "flex", flexDirection: "row", background: StyleUtils.theme().mainColor, borderRadius: "8px" }}>
                    {TAB.flatMap((v, index) => {
                        let select = mode === v.mode
                        return <div key={index} style={{ padding: "10px 12px", cursor: "pointer", background: (!select) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: "8px", color: !select ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
                            setMode(v.mode)
                        }}>{v.txt}</div>
                    })}
                </div>
                }
            </div>
            <div style={{ width: "100%", position: "relative" }}>
                {view}
            </div>
        </div>
    </div>
};

export default SharePage;
