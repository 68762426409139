import { Avatar, Button, Col, Dropdown, Image, List, MenuProps, Row, Skeleton, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { ApiCode, ServerGroup } from "../../../data/common";
import { AppEvent } from "../../../data/events";
import events from "../../../utils/events";
import { UpdateGroupModal } from "../modal/UpdateGroupModal";
import { RemoveGroupModal } from "../modal/RemoveGroupModal";
import Api from "../../../utils/Api";
import { AddGroupModal } from "../modal/AddOrUpdateGroupModal";
import { WorkHashrateListReq } from "../../../data/request";
import { UserInfoManager } from "../../../utils/UserInfoManager";

import { WorkInfoManager } from "../../../utils/WorkData";
import { StyleUtils } from "../../../utils/Style";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { GlobalUtil } from "../../../utils/GlobalText";

interface SimpleListViewProps {
    style?: React.CSSProperties;
    dataSource?: ServerGroup[];
    renderItem: (item: ServerGroup, index: number) => React.ReactNode;
    itemLayout: string
}


const SimpleListView: React.FC<SimpleListViewProps> = (props) => {
    return <div style={{ display: "flex", flexDirection: "row", overflow: "auto", overflowX: "auto", paddingBottom: "40px" }}>
        {
            props.dataSource?.flatMap((v, i) => {
                return props.renderItem(v, i)
            })
        }
    </div>
}
export const WorkListView: React.FC = () => {
    const [updateGroup, setUpdateGroup] = useState<ServerGroup | null>(null)
    const [showUpdateGroup, setShowUpdateGroup] = useState(false)
    const [showRemoveGroup, setShowRemoveGroup] = useState(false)

    const [selectGroup, setSelectGroup] = useState(0)
    let groupData = (WorkInfoManager.getGroupData()?.group ?? [])
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setUpdate(!update)
    })
    const items: MenuProps['items'] = [
        {
            label: <a  style={{color:StyleUtils.theme().fontColor_1}} onClick={() => { setShowUpdateGroup(true) }}>{GlobalUtil.t("work.changeGroup")}</a>,
            key: '0',

        },
        {
            label: <a style={{color:StyleUtils.theme().fontColor_1}} onClick={() => { setShowRemoveGroup(true) }} >{GlobalUtil.t("work.removeGroup")}</a>,
            key: '1',
        }
    ];
    const [showAddGroup, setShowAddGroup] = useState(false)
    if (!WorkInfoManager.shareUrl) {
        groupData = groupData.concat({
            groupId: "-100",
            groupName: "",
            hashrateTotal: "",
            groupHashrate: "",
            allSize: 0,
            onlineSize: 0,
            offlineSize: 0,
            deadSize: 0

        })
    }

    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: StyleUtils.getHeight(20), marginLeft: StyleUtils.getWidth(12), marginRight: StyleUtils.getWidth(12) }}    >
        <AddGroupModal onClick={(ok) => {
            events.emit(AppEvent.GROUP_UPDATE)
            setShowAddGroup(false)
        }} show={showAddGroup} />
        <UpdateGroupModal group={updateGroup} onClick={(ok) => {
            events.emit(AppEvent.GROUP_UPDATE)
            setUpdateGroup(null)
            setShowUpdateGroup(false)
        }} show={showUpdateGroup} />
        <RemoveGroupModal group={updateGroup} onClick={(ok) => {
            events.emit(AppEvent.GROUP_UPDATE)
            setUpdateGroup(null)
            setShowRemoveGroup(false)
        }} show={showRemoveGroup} />
        <Row align={"middle"} style={{ gap: "8px", marginBottom: "1rem" }}>
            {
                groupData.map((item, index) => {
                    let isSelect = selectGroup === index
                    let name = item.groupName
                    if (!item.groupId) {
                        name =  GlobalUtil.t("work.all")
                    } else if (item.groupId === "-1") {
                        name = GlobalUtil.t("work.noGroup")
                    }
                    if (item.groupId === "-100") {
                        return (
                            <Button
                                style={{ borderRadius: "8px" }}
                                key={item.groupId + index}
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    setShowAddGroup(true)
                                }} />
                        )
                    }
                    return (
                        <Row key={item.groupId + index} style={{
                            padding: "4px 12px",
                            backgroundColor: StyleUtils.theme().work_group_bg_color,
                            border: `1px solid ${isSelect ? StyleUtils.theme().work_group_border_color : StyleUtils.theme().work_group_normal_border_color}`,
                            borderRadius: "8px",
                            gap: "1rem",
                            cursor: "pointer",
                        }} onClick={() => {
                            setSelectGroup(index)
                            WorkInfoManager.updateGroupId(item.groupId)
                        }}>
                            <div style={{ color: StyleUtils.theme().fontColor_1, fontWeight: "500", fontSize: "0.88rem", display: "flex", flexDirection: "row", ...StyleUtils.textWrap() }}>
                                {name ??  GlobalUtil.t("work.all")}({item.allSize})
                            </div>

                            <div style={{ justifyContent: "flex-end", alignItems: "center", display: "flex", flexDirection: "row" }}>
                                <div style={{ fontWeight: "500", color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", ...StyleUtils.textWrap() }}>
                                    {/* FIXME: 单位问题待议  MH/S*/}
                                    {`${item?.groupHashrate}`}
                                </div>
                                <div style={{ display: "flex" }}>
                                    {
                                        !WorkInfoManager.shareUrl && item.groupId !== "0" && item.groupId !== "-1" && <Dropdown 
                                        menu={{ items }} trigger={['click']}>
                                            <SettingOutlined 
                                            style={{color:StyleUtils.theme().fontColor_1, marginLeft: StyleUtils.getWidth(6), marginTop: StyleUtils.getHeight(0) }} onClick={(e) => {
                                                setUpdateGroup(item)
                                                e.preventDefault()
                                            }}>
                                            </SettingOutlined>
                                        </Dropdown>
                                    }
                                </div>
                            </div>
                        </Row>
                    )
                })
            }
        </Row>
    </div>
}
