import { Button, Input, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode } from '../../../../data/common';
import Api from '../../../../utils/Api';
import { GlobalUtil } from '../../../../utils/GlobalText';
import { StyleUtils } from '../../../../utils/Style';
import { AntdButton, AntdInput } from '../../../../views/commonview';


const ChangeDescView = (account: string, defaultDesc: string, show: boolean, onClick: (ok: boolean) => void) => {
    let [desc, setDesc] = useState(defaultDesc)
    let [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const clear = () => {
        setDesc("")
    }
    return <Modal
        open={show}
        onCancel={() => {
            onClick(false)
            clear()
        }}
        title={GlobalUtil.t("account.changeDesc")}
        footer={[
            <AntdButton>
                <Button
                    key="link"
                    type="primary"
                    disabled={!desc}
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        try {
                            let res = await Api.updateDesc(account, desc)
                            if (res.code === ApiCode.SUCCESS) {
                                onClick(true)
                                clear()
                            } else {
                                messageApi.error(res.msg ?? "")
                            }
                        } catch (e) {
                            messageApi.error(GlobalUtil.t("errorTip"))
                        }
                        setLoading(false)
                    }}
                    style={{ width: "100%" }}
                >
                    {GlobalUtil.t("confirm")}
                </Button>
            </AntdButton>
        ]}
    >
        {contextHolder}
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>修改挖矿帐户 {account} 的备注为：</div>
            <AntdInput>
                <Input
                    style={{ marginTop: StyleUtils.getHeight(5) }}
                    allowClear
                    value={desc}
                    onChange={(e) => {
                        setDesc(e.target.value.trim())
                    }}
                    placeholder={GlobalUtil.t("account.inputDesc")}
                />
            </AntdInput>
        </div>
    </Modal>



}

export default ChangeDescView;