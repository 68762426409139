import { Row } from "antd"
import { GlobalUtil } from "../../../utils/GlobalText"
import { StyleUtils } from "../../../utils/Style"
import { formatStr } from "../../../utils/Utils"
import { ServerIncomeInfo, ServerTotalRevenue } from "../../../data/common"
import { IncomeLabeView } from "./IncomeLabeView"
import { UserInfoManager } from "../../../utils/UserInfoManager"


interface PcProps {
    data?: ServerTotalRevenue | null | undefined
    incomeData?: ServerIncomeInfo[]
}
export const PcCardView = (props: PcProps) => {
    const { data, incomeData } = props
    return (
        <div className="pc-card-view">
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: "1.5rem" }}>
                <Row>
                    <IncomeLabeView icon={StyleUtils.ICON_INCOME_BALANCE} title={formatStr(GlobalUtil.t("income.balance"), UserInfoManager.getShowCurrency())} value={data?.balance ?? 0} />
                </Row>
                <Row >
                    <IncomeLabeView icon={StyleUtils.ICON_INCOME_ALL} title={GlobalUtil.t("income.today")} value={data?.todayEstRevenue ?? 0} list={incomeData} />
                </Row>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: "1.5rem" }}>
                <Row>
                    <IncomeLabeView icon={StyleUtils.ICON_INCOME_ALL} title={formatStr(GlobalUtil.t("income.allIncome"), UserInfoManager.getShowCurrency())} value={data?.totalIncome ?? 0} />
                </Row>
                <Row style={{ gap: "1.5rem", flexFlow: "row" }}>
                    <Row style={{ flex: "1 1 50%" }}>
                        <IncomeLabeView icon={StyleUtils.ICON_INCOME_PAYOUT} title={formatStr(GlobalUtil.t("income.allPayout"), UserInfoManager.getShowCurrency())} value={data?.totalPayout ?? 0} />
                    </Row>
                    <Row style={{ flex: "1 1 50%" }}>
                        <IncomeLabeView icon={StyleUtils.ICON_INCOME_yesterday} title={formatStr(GlobalUtil.t("income.yesterday"), UserInfoManager.getShowCurrency())} value={data?.yesterdayRevenue ?? 0} />
                    </Row>
                </Row>

            </div>
        </div>
    )
}