
import { GlobalUtil } from "./GlobalText";


const CheckUtils = {
    // isNotEmail(email: string) {
    //     return !(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email))
    // },
    // /**
    //  * 校验钱包地址
    //  * @param name
    //  * @param t
    //  */
    // isLegalName(name: string, t: TFunction) {
    //     if (!/^[\u4e00-\u9fa5_a-zA-Z-()~\d\s\.]+$/g.test(name)) {
    //         return t("create_farm_or_worker.name_not_has_special")
    //     }
    //     if (name.length < 2 || name.length > 24) {
    //         return t("create_farm_or_worker.char_length")
    //     }
    //     return ""
    // },
    /**
     * 校验钱包地址
     * @param arg
     * @param t
     */
    checkAddress(arg: string) {
        if (!(/^[0-9a-zA-Z]*$/.test(arg))) {
            return GlobalUtil.t("invalid_address")
        }
        return ""
    },
    checkRegistUserName(arg: string) {
        if (!(/^[^\d][a-z\d]{5,20}$/.test(arg))) {
            return GlobalUtil.t("register.nameError")
        }
        return ""
    },
    checkRemarks(arg: string) {
        if (!(/^[\u4e00-\u9fa5\d\w_-]{2,30}$/.test(arg))) {
            return GlobalUtil.t("invalid_address")
        }
        return ""
    },

}
export default CheckUtils