import { Button, Input, Modal, Select } from "antd"
import events from "../../../utils/events"
import { AppEvent, AppMessageType } from "../../../data/events"
import { CSSProperties, useEffect, useState } from "react"
import { ApiCode, ServerGroup, ServerWorker } from "../../../data/common"
import Api from "../../../utils/Api"
import { StyleUtils } from "../../../utils/Style"
import { GlobalUtil } from "../../../utils/GlobalText"
import { WorkInfoManager } from "../../../utils/WorkData"
import { AntdButton, AntdSelect } from "../../../views/commonview"
export interface Props {
    show: boolean
    onClick: (ok: boolean) => void
    groups: ServerGroup[] | undefined | null
    worker: ServerWorker[] | undefined | null
    style?: CSSProperties | undefined
}
export const MoveWorkerModal: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    const [selectGroup, setSelectGroup] = useState<string | null>()

    const onChange = (value: string) => {
        setSelectGroup(value)
    };

    useEffect(() => {
        if (props.show) {
            let defaultGroup = (props.groups ?? []).filter((item) => item.groupId !== '0')[0].groupId;
            setSelectGroup(defaultGroup)
        } else {
            setSelectGroup(null)
        }
    }, [props.show])
    const disable = !props.groups || !selectGroup || selectGroup === WorkInfoManager.groupId

    return <Modal
        // width={"50%"}
        maskClosable={false}
        style={{ ...props.style }}
        className="move-model"
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: StyleUtils.getFont(20), display: "flex", flexDirection: "row", fontWeight: 500, color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("work.moveWork")}</div>

            <div style={{ marginTop: StyleUtils.getHeight(8), width: "100%" }}>
                <AntdSelect>
                    <Select
                        value={selectGroup}
                        placeholder=""
                        optionFilterProp="children"
                        onChange={onChange}
                        style={{ width: "100%" }}
                        options={(props.groups ?? []).filter((v) => {
                            if (v.groupId !== "0") {
                                return (v.groupId)
                            }

                        }).flatMap((item) => {

                            return {
                                value: item.groupId,
                                label: item.groupName ?? GlobalUtil.t("work.noGroup")
                            }
                        })}
                    />
                </AntdSelect>
            </div>

            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let workerIds = ""
                                props.worker?.forEach((w) => {
                                    if (workerIds) {
                                        workerIds += ","
                                    }
                                    workerIds += w.workerId
                                })
                                let res = await Api.setWorkGroup(selectGroup ?? "", workerIds)
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("confirm")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}