import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Card, Carousel, Col, List, Radio, Row, Table } from "antd";
import EChartsReact from "echarts-for-react";
import moment from "moment";
import Api from "../../utils/Api";
import { ApiCode, ServerHistoryValue, ServerHomeHistory } from "../../data/common";
import events from "../../utils/events";
import { AppEvent } from "../../data/events";
import { AppMainText } from "../../views/commonview";
import { StyleUtils } from "../../utils/Style";
import { GlobalUtil } from "../../utils/GlobalText";
import * as echarts from 'echarts';
import { UserInfoManager } from "../../utils/UserInfoManager";

class HashrateData {
  data: ServerHistoryValue[] = []
  changeMode: boolean = false
}

const HomeAccountTableView: React.FC = () => {
  const [update, setUpdate] = useState(false)
  const [duration, setDuration] = useState(1)
  const [hashrateData, setHaserateData] = useState<HashrateData | null>(null)
  const data = hashrateData?.data ?? []
  const loadData = async (changeMode: boolean = false) => {
    let res = await Api.getPoolStatus(duration)
    if (res.code === ApiCode.SUCCESS) {
      let thisData = new HashrateData()
      thisData.changeMode = changeMode
      thisData.data = res.data ?? []
      setHaserateData(thisData)
    }
  }
  events.on(AppEvent.REFRES, ({ currency, language }: any) => {
    if (currency || language) {
      setUpdate(!update)
    }
  })
  useEffect(() => {
    loadData()
  }, [update, duration])
  const option = {
    color: [StyleUtils.theme().echart_line_color_1],
    textStyle:{
      color:StyleUtils.theme().fontColor_1
    },
    tooltip: {
      trigger: 'axis',
      // formatter: (params: any) => {
      //   if (!params.length) {
      //     return ""
      //   }
      //   console.log("hello ")
      //   var itemData = data[0].values[params[0].dataIndex]
      //   return moment(itemData.time).format('YYYY-MM-DD HH:mm:ss') + "<br>" + parseFloat(itemData.hashrate).toFixed(2) + " MH/s";
      // }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },

    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data.length && data?.flatMap((v) => {
          if (duration === 1) {
            return moment(v.time).format('HH:mm');
          }
          return moment(v.time).format('MM-DD');
        }),
      }
    ],

    yAxis: {
      type: 'value',
      minInterval: 1,
      gridIndex: 0, // 设置网格所在的索引
      splitLine: { // 分割线
        show: true // 显示网格线
      }
    },
    series: [
      {
        type: 'line',
        stack: 'Total',
        smooth: true,
        showSymbol: false,
        data: data.map((item) => item.hashrate),
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "rgba(47,101,255,0.33)"
            },
            {
              offset: 1,
              color: "rgba(47,101,255,0)"
            }
          ])
        },
      },
    ]
  };
  const select = duration === 1
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: StyleUtils.getWidth(36), marginBottom: StyleUtils.getWidth(18) }}>

        <div style={{ color: StyleUtils.theme().fontColor_1, fontSize: "2.4rem", fontWeight: "500", marginRight: "6px" }}>
          Pool
        </div>
        <div style={{ width: StyleUtils.getWidth(3) }} />
        <AppMainText text={GlobalUtil.t("home.hashrate")} />
      </div>
      <Card style={{ position: "relative", display: "flex", width: "100%", flexDirection: "column", background: StyleUtils.theme().boardBg, borderColor: StyleUtils.theme().boardBg }}>
        <Row justify={"space-between"} align={"middle"} style={{flexFlow:"row nowrap"}} >
          <Col  style={{ display:"flex",color: StyleUtils.theme().fontColor_2, fontWeight: "500", fontSize: StyleUtils.getFont(16) }}>
            {UserInfoManager.getShowCurrency()} - {select ? GlobalUtil.t("work.last24Hashrate") : GlobalUtil.t("work.days30Hashrate")}
          </Col>
          <Col  style={{ display: "flex", flexDirection: "row", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>
            <div style={{ padding: "4px 12px", cursor: "pointer", background: (!select) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: !select ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setDuration(1)
            }}>{GlobalUtil.t("home.24Hours")}</div>
            <div style={{ padding: "4px 12px", cursor: "pointer", background: (select) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: select ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setDuration(2)
            }}>{GlobalUtil.t("home.30Days")}</div>
          </Col>
        </Row>
        <div style={{ marginTop: StyleUtils.getHeight(20) }}>
          <EChartsReact
            notMerge={hashrateData?.changeMode ?? false}
            option={option}
          // style={{ height:StyleUtils.getHeight(268)}}
          />
        </div>
      </Card >
    </div>
  );
};

export default HomeAccountTableView;
