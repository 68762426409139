import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { MobileHeader } from "./MobileHeader"
import { PcHeader } from "./PcHeader"
import "./index.css"

export const Header = () => {
    const { xl } = useBreakpoint()
    return (
        xl ?
            <PcHeader />
            :
            <MobileHeader />
    )
}