import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ApiCode } from "../data/common";
import Api from "../utils/Api";
import { StyleUtils } from "../utils/Style";
import { GlobalUtil } from "../utils/GlobalText";
import NotFoundPage from "./NotFoundPage";
import { Typography } from "antd";
import { AppEvent } from "../data/events";
import { useEventBus } from "../utils/events";


const ActiveAwaitAddressPage = () => {
    const location = useLocation();
    const [msg, setMsg] = useState("")
    const [update, setUpdate] = useState(true)
    useEventBus(AppEvent.ChangeStyle, (changeType: string) => {
        setUpdate(!update)
    })
    useEventBus(AppEvent.REFRES, () => {
        setUpdate(!update)
    })
    const active = async () => {
        try {
            let search = location.search.replaceAll("&amp;", "&")
            const queryParams = new URLSearchParams(search)
            let code = queryParams.get("code")
            let currency = queryParams.get("currency")
            if (code && currency) {
                let res = await Api.activateWallet(currency, code)
                if (res.code === ApiCode.SUCCESS) {
                    setMsg(GlobalUtil.t("active.success"))
                } else {
                    setMsg(res.msg ?? GlobalUtil.t("active.error"))
                }
            }


        } catch (e) {
            setMsg(GlobalUtil.t("active.error"))
        }
    }
    useEffect(() => { active() }, [])

    return <NotFoundPage
        imgPath={msg === GlobalUtil.t("active.success") || !msg.endsWith("error") ? StyleUtils.getImage('active-success.svg') : undefined}
        text={
            <Typography.Text style={{ color: StyleUtils.theme().fontColor_2 }}>
                {msg}
            </Typography.Text>
        } />
}
export default ActiveAwaitAddressPage