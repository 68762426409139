import { Modal } from "antd";
import { FC } from "react";
import { GlobalUtil } from "../utils/GlobalText";
import { StyleUtils } from "../utils/Style";


interface DRProps {
    show: boolean
    handleClickOk: () => void
    handleClickCancel: () => void
}
export const DeleteReadOnly: FC<DRProps> = ({
    show,
    handleClickOk,
    handleClickCancel
}) => {
    return (
        <Modal
            maskClosable={false}
            title={GlobalUtil.t("tip")}
            open={show}
            onOk={handleClickOk}
            onCancel={handleClickCancel}
            okText={GlobalUtil.t('confirm')}
            cancelText={GlobalUtil.t('cancel')}
        >
            <p style={{ color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('account.deleteReadOnly')}</p>
        </Modal>
    )
}