import { StyleUtils } from "../utils/Style";
import { GlobalUtil } from "../utils/GlobalText";
import CustomInput from "./CustomInput";
import React, { ReactNode } from "react";
import { Row } from "antd";

interface LabelInputProps {
    topMargin: number
    label: ReactNode
    inputType: "password" | "text",
    placeholder: string
    onChange: (value: string) => void
}

export default function LabelInput(props: LabelInputProps) {
    const { topMargin, label, inputType, placeholder, onChange } = props
    return (<div style={{ width: "80%", marginTop: StyleUtils.getHeight(topMargin) }}>
        <Row align={"middle"} style={{
            fontSize: "0.88rem",
            color: StyleUtils.theme().fontColor_1,
            marginBottom: StyleUtils.getWidth(5),
            columnGap:".5rem"
        }}>
            {label}
        </Row>
        <CustomInput
            inputType={inputType} placeholder={placeholder}
            onChange={onChange} />
    </div>)
}