import { Button, Input, Modal, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode } from '../../../../data/common';
import Api from '../../../../utils/Api';
import { GlobalUtil } from '../../../../utils/GlobalText';
import { StyleUtils } from '../../../../utils/Style';
import { AntdButton, AntdCard, AntdInput } from '../../../../views/commonview';
import CheckUtils from '../../../../utils/checkUtils';


const AddAccountView = (show: boolean, onClick: (ok: boolean) => void) => {
    let [account, setAccount] = useState("")
    let [desc, setDesc] = useState("")
    let [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        if (!show) {
            setAccount("")
            setDesc("")
        }
    }, [show])
    return <Modal
        open={show}
        getContainer={false}
        maskClosable={false}
        onCancel={() => {
            onClick(false)
        }}
        title={GlobalUtil.t("account.addAccount")}
        footer={[
            <AntdButton>
                <Button
                    key="link"
                    type="primary"
                    disabled={CheckUtils.checkRegistUserName(account) !== "" || !desc }
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        try {
                            let res = await Api.addAccount(account, desc)
                            if (res.code === ApiCode.SUCCESS) {
                                onClick(true)
                            } else {
                                messageApi.error(res.msg ?? "")
                            }
                        } catch (e) {
                            messageApi.error(GlobalUtil.t("errorTip"))
                        }
                        setLoading(false)
                    }}
                    style={{ width: "100%" }}
                >
                    {GlobalUtil.t("confirm")}
                </Button>
            </AntdButton>
        ]}
    >
        {contextHolder}
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Row style={{ columnGap: ".5rem", ...StyleUtils.fontStyle_1() }}>
                {GlobalUtil.t("account.accountName")}
                {account && CheckUtils.checkRegistUserName(account) &&
                    <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t("register.nameError")}</div>
                }
            </Row>
            <div style={{ marginTop: StyleUtils.getHeight(5) }}>
                <AntdInput>
                    <Input
                        style={{ marginTop: StyleUtils.getHeight(5) }}
                        allowClear
                        value={account}
                        onChange={(e) => {
                            setAccount(e.target.value.trim())
                        }}
                        placeholder={GlobalUtil.t("account.inputAccount")}
                    />
                </AntdInput>
            </div>
            <div style={{ ...StyleUtils.fontStyle_1(), marginTop: StyleUtils.getHeight(15) }}>{GlobalUtil.t('account.desc')}</div>
            <AntdInput>

                <Input
                    style={{ marginTop: StyleUtils.getHeight(5) }}
                    allowClear
                    value={desc}
                    showCount
                    maxLength={50}
                    onChange={(e) => {
                        setDesc(e.target.value.trim())
                    }}
                    placeholder={GlobalUtil.t("account.inputDesc")}
                />
            </AntdInput>

        </div>
    </Modal>

}

export default AddAccountView;