import { Button, Input, Modal, message, Image, Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Account, ApiCode, Currency, GoogleBindInfo, ServerUserWalletStatus, ServerWalletAddress } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import CustomInput from '../components/CustomInput';
import { AntdButton, AntdInput } from '../views/commonview';
import CheckUtils from '../utils/checkUtils';

export interface DoubleCheckProps {
    show: boolean
    onClick: (ok: boolean) => void,
    account: Account | null | undefined,
    currency: Currency | null | undefined,
    walletAddress: ServerWalletAddress | null | undefined
}

const AddOrUpdateCurrencyWalletView: React.FC<DoubleCheckProps> = (props) => {
    const walletAddress = props.walletAddress
    const [loading, setLoading] = useState(false)
    let [address, setAddress] = useState("")
    let [addressFrom, setAddressFrom] = useState("")
    let [syncAccount, setSyncAccount] = useState<string[] | null>(null)
    let [code, setCode] = useState("")
    let [userWallets, setUserWallets] = useState<ServerUserWalletStatus[]>([])
    const disable = !address || !code
    const clear = () => {
        setAddress("")
        setAddressFrom("")
        setCode("")
        setSyncAccount([])
        setUserWallets([])
    }
    const loadData = async () => {
        try {
            if (!props.show || !props.account || !props.currency) {
                return
            }
            let res = await Api.getUserWalletState(props.account?.account ?? "", props.currency?.code ?? "")
            if (res.code === ApiCode.SUCCESS) {
                setUserWallets(res.data ?? [])
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        setAddress(walletAddress?.address ?? "")
        setAddressFrom(walletAddress?.addressDesc ?? "")
        loadData()
    }, [props.show])
    const handleChange = (value: CheckboxValueType[]) => {
        setSyncAccount(value as string[])
    };
    return <Modal
        width={"50%"}
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            clear()
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t(walletAddress ? "edit" : "add")}{props.currency?.name}地址</div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('address')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10) }}>
                <AntdInput>
                    <Input
                        value={address}
                        style={{}}
                        onChange={(e) => {
                            if (/[0-9a-eA-E]+/.test(e.target.value)){
                                setAddress(e.target.value.trim())
                            }
                        }}
                        placeholder={GlobalUtil.t("currency.payAddress")}
                    />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('currency.addressFrom')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10), }}>
                <AntdInput>
                    <Input
                        value={addressFrom}
                        style={{}}
                        showCount
                        maxLength={50}
                        onChange={(e) => {
                            setAddressFrom(e.target.value.trim())
                        }}
                        placeholder={GlobalUtil.t("currency.addressTip")}

                    />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("currency.addressSync")}</div>
            <div style={{ marginTop: StyleUtils.getHeight(8), }}>
                <AntdInput>
                    <Select value={syncAccount} dropdownRender={() => {
                        return <Checkbox.Group value={syncAccount as CheckboxValueType[]} onChange={handleChange} style={{ width: '100%', display: "flex", flexDirection: "column" }} >
                            {
                                userWallets.flatMap((v, k) => {
                                    let sameAddress = v.currentAddress === props.walletAddress?.address
                                    let disable = sameAddress || !v.status || v.account === props.account?.account
                                    return <div style={{ padding: StyleUtils.getWidth(5), display: "flex", flexDirection: "row" }}>
                                        <Checkbox disabled={disable} value={v.account} style={{}}>
                                            {v.account}
                                        </Checkbox>
                                        {disable && v.account !== props.account?.account && <div style={{ marginTop: StyleUtils.getWidth(3), alignItems: "center", fontSize: StyleUtils.getWidth(6), marginLeft: StyleUtils.getWidth(5), color: "red" }}>({sameAddress ? GlobalUtil.t("currency.sameAddress") : !v.status ? GlobalUtil.t("currency.waitActiveAddress") : ""})</div>
                                        }
                                    </div>
                                })

                            }
                        </Checkbox.Group>
                    }} onChange={handleChange} mode="multiple" style={{ width: "100%" }} options={userWallets.flatMap((v) => { return { "value": v.account, label: v.account } })} />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('checkCode')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10) }}>
                <AntdInput>
                    <Input
                        style={{}}
                        value={code}
                        placeholder={"000000"}
                        onChange={(e) => {
                            setCode(e.target.value.trim())
                        }}
                    />
                </AntdInput>
            </div>

            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            const checkAddress = CheckUtils.checkAddress(address)
                            if (checkAddress) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: checkAddress
                                })
                                return
                            }
                            try {
                                let syncAccounts = ""
                                syncAccount?.forEach((v) => {
                                    if (syncAccounts.length) {
                                        syncAccounts += ","
                                    }
                                    syncAccounts += v
                                })
                                let res = await Api.saveWalletAddress(props.account?.account ?? "", props.currency?.code ?? "", address, addressFrom, syncAccounts ?? "", code)
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    clear()
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("confirm")}
                    </Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default AddOrUpdateCurrencyWalletView;