import { Alert, Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { GlobalUtil } from "../utils/GlobalText";
import { useNavigate } from "react-router-dom";
import Api from "../utils/Api";
import { isValidEmail, isValidPwd, isValidUserName } from "../utils/Utils";
import { StyleUtils } from "../utils/Style";
import { AntdButton, AntdInput, AppButton } from "../views/commonview";
import { ApiCode } from "../data/common";
import LabelInput from "../components/LabelInput";
import "./register.css"
import events from "../utils/events";
import { AppEvent, AppMessageType } from "../data/events";
import CheckUtils from "../utils/checkUtils";
import { log } from "console";

const RegisterPage: React.FC = () => {
    const navigate = useNavigate();
    let [isLoading, setLoading] = useState(false)
    let [email, setEmail] = useState("")
    let [pwd, setPwd] = useState("")
    let [confirmPwd, setConfirmPwd] = useState("")
    let [account, setAccount] = useState("")
    let [code, setCode] = useState("")
    let [isSendCode, setIsSendCode] = useState(false)
    let [sending, setSending] = useState(false)
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (count > 0) {
            const timer = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [count]);
    let registerContinue = !CheckUtils.checkRegistUserName(account) && (pwd === confirmPwd && isValidPwd(pwd)) && isValidEmail(email) && code !== "" && isSendCode

    return (

        <div className="top-doc " style={{
            background: `url(${StyleUtils.getImage("top_bg.png")}) no-repeat center center`,
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            padding: `${StyleUtils.getHeight(36)} 0`,
            justifyContent: "center"
        }}>
            <div className={"registercard"} style={{
                borderRadius: StyleUtils.getWidth(12),
                minHeight: StyleUtils.getWidth(460),
                background: StyleUtils.theme().boardBg,
            }}>
                <div style={{
                    color: StyleUtils.theme().fontColor_2,
                    fontSize: StyleUtils.getFont(28),
                    fontWeight: "500"
                }}>
                    {GlobalUtil.t('register.title')}
                </div>

                <LabelInput
                    topMargin={9}
                    inputType={"text"}
                    placeholder={GlobalUtil.t("register.nameTip")}
                    onChange={(value) => setAccount(value)}
                    label={<>
                        {GlobalUtil.t('register.name')}
                        {account && CheckUtils.checkRegistUserName(account) &&
                            <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t("register.nameError")}</div>
                        }
                    </>}
                />

                <LabelInput
                    topMargin={9}
                    inputType={"text"}
                    placeholder={GlobalUtil.t('register.emaliTip')}
                    onChange={(value) => setEmail(value)}
                    label={
                        <>
                            {GlobalUtil.t('register.emali')}
                            {email && !isValidEmail(email) &&
                                <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t('register.emaliErrorTip')}</div>
                            }
                        </>
                    } />

                <div style={{ width: "80%", marginTop: StyleUtils.getHeight(12) }}>
                    <div style={{
                        fontSize: "0.88rem",
                        color: StyleUtils.theme().fontColor_1,
                        fontWeight: "500",
                        marginBottom: StyleUtils.getWidth(5)
                    }}>{GlobalUtil.t('register.emailCode')}</div>
                    <div style={{ display: "flex", flex: "row" }}>
                        <AntdInput>
                            <Input
                                size={"large"}
                                style={{ fontSize: "0.88rem", color: "#899199", fontWeight: "400" }}
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }}
                                allowClear
                                placeholder={GlobalUtil.t('register.emailCodeTip')}
                            />
                        </AntdInput>
                        <AppButton
                            disabled={!isValidEmail(email) || count > 0} style={{ width: "120px", marginLeft: StyleUtils.getWidth(8) }}
                            onClick={async () => {
                                try {
                                    if (sending) {
                                        return
                                    }
                                    setSending(true)
                                    let res = await Api.sendRegisterCode(email)
                                    if (res.code === ApiCode.SUCCESS) {
                                        setIsSendCode(true)
                                        setCount(60)
                                    } else {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.ERROR,
                                            message: res.msg ?? GlobalUtil.t('register.errorTip')
                                        })
                                    }
                                } catch (error) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: GlobalUtil.t('register.errorTip')
                                    })
                                }
                                setSending(false)

                            }}
                            text={(!isSendCode || count === 0) ? GlobalUtil.t(!isSendCode ? 'register.sendCode' : "register.reSendCode") : count + "s"}
                        />
                    </div>
                </div>

                <LabelInput
                    topMargin={9}
                    inputType={"password"}
                    placeholder={GlobalUtil.t('register.passwordTip')} onChange={(value) => setPwd(value)}
                    label={
                        <>
                            {GlobalUtil.t('register.password')}
                            {pwd && !isValidPwd(pwd) &&
                                <div style={{ fontSize: 12, color: "red", marginTop: "3px" }}>{GlobalUtil.t('register.pwdTip')}</div>
                            }
                        </>
                    }
                />
                <LabelInput
                    topMargin={9}
                    inputType={"password"}
                    placeholder={GlobalUtil.t('register.confirmPwdTip')} onChange={(value) => setConfirmPwd(value)}
                    label={
                        <>
                            {GlobalUtil.t('register.confirmPwd')}
                            {confirmPwd !== "" && pwd !== confirmPwd &&
                                <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t('register.passwordErrorTip')}</div>
                            }
                        </>
                    }
                />

                <AntdButton>
                    <Button
                        size={"large"}
                        style={{
                            width: "80%", padding: 0, marginTop: StyleUtils.getHeight(23), fontSize: "1rem"
                        }}
                        type="primary"
                        disabled={!registerContinue}
                        loading={isLoading}
                        onClick={() => {
                            setLoading(true)
                            setTimeout(async () => {
                                try {
                                    let res = await Api.register(email, pwd, code, account)
                                    if (res.code !== ApiCode.SUCCESS) {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.ERROR,
                                            message: res.msg ?? GlobalUtil.t('register.errorTip')
                                        })
                                    } else {
                                        events.emit(AppEvent.SHOW_MESSAGE, {
                                            type: AppMessageType.SUCCESS,
                                            message: GlobalUtil.t('register.successTip')
                                        })
                                        setTimeout(() => {
                                            navigate("/user/login")
                                        }, 1000)
                                    }
                                } catch (e) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: GlobalUtil.t('register.errorTip')
                                    })
                                }
                                setLoading(false)
                            }, 2000)
                        }}
                    >
                        {GlobalUtil.t("register.btRegister")}
                    </Button>
                </AntdButton>

                <div style={{
                    marginTop: StyleUtils.getHeight(20),
                    position: "relative",
                    display: "flex", justifyContent: "space-between", width: "80%",
                    height: "50px"
                }}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "80%"}}>
                        <div style={{
                            fontSize: StyleUtils.getFont(12),
                            cursor: "pointer",
                            display: "flex",
                            position: "absolute",
                            right: "0",
                            color: StyleUtils.theme().mainButtonColor
                        }} onClick={() => {
                            navigate("/reset-password/step1")
                        }}> {GlobalUtil.t("login.forgetPwd")}</div>
                        <div style={{
                            fontSize: StyleUtils.getFont(12),
                            cursor: "pointer",
                            display: "flex",
                            position: "absolute",
                            left: "0",
                            color: StyleUtils.theme().mainButtonColor
                        }} onClick={() => {
                            navigate("/user/login")
                        }}>{GlobalUtil.t("register.btLogin")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;