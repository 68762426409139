export class AppEvent {
    static SHARE_REFRES = "share_refresh"
    static ChangeStyle="changestyle"
    static REFRES = "refresh"
    static LOGOUT = "logout"
    static Unauthorized = "Unauthorized"
    static ON_ACCOUNT_INFO_UPDATE = "account_update"
    static ERRPR_SHARE_URL = "error_share"
    static SHOW_MESSAGE = "show_message"
    static HIDE_ACCOUNT = "hide_account"
    static UNHIDE_ACCOUNT = "unhide_account"
    static DELETE_SUB_ACCOUNT = "delete_sub_account"
    static UPDATE_USERINFO = "update_userinfo"
    static SHOW_DOUBLE_CHECC = "show_double_check"
    static SHOW_TIP_DOUBLE_CHECC = "show_tip_double_check"
    static CLOSE_DOUBLE_CHECC = "close_double_check"
    static DELETE_MAIN_ACCOUNT = "delete_main_account"
    static ADD_WALLET_ADDRESS = "add_wallet_address"
    static CHANGE_PWD = "change_pwd"
    static GROUP_UPDATE = "group_update"
    static GROUP_SELECT = "group_select"
    static GROUP_HASHRATE_CHANGE = "group_hasrate_change"
    static GROUP_WOR_STATUS_TAB_CHANGE="group_work_status_tab_change"
    static GROUP_DATA_CHANGE = "group_data_change"
    static GROUP_HASHRATE_DATA_CHANGE = "group_hasrate_data_change"
    static REPORT_UPDATE_LIST="report_update_list"
    static REPORT_EDIT_ITEM="report_edit_item"
}

export enum AppMessageType {
    ERROR,
    INFO,
    SUCCESS
}
export interface AppMessageEvent {
    type: AppMessageType
    message: string
}