import { Button, Col, Row, Typography } from "antd";
import React, { ReactNode, useState } from "react";
import { StyleUtils } from "../utils/Style";
import { AntdButton } from "../views/commonview";
import { AppEvent } from "../data/events";
import { useEventBus } from "../utils/events";


interface ErrorProps {
  imgPath?: string;
  text?: ReactNode;
  returnButton?: ReactNode;
}
const NotFoundPage: React.FC<ErrorProps> = ({ imgPath, text, returnButton }) => {
  const [update, setUpdate] = useState(true)
  useEventBus(AppEvent.ChangeStyle, (changeType: string) => {
    setUpdate(!update)
  })
  useEventBus(AppEvent.REFRES, () => {
    setUpdate(!update)
  })

  return (
    <Row align={"middle"} justify={"center"} style={{
      // height: "100vh",
      minHeight: StyleUtils.getWidth(400),
      flexDirection: "column",
      rowGap: "1rem"
    }}>
      <img src={imgPath ?? StyleUtils.getLightImage("404.svg")} width={StyleUtils.getWidth(334)} height={StyleUtils.getHeight(124)} alt="404" />
      {text ?
        text
        :
        <Typography.Text style={{ color: StyleUtils.theme().fontColor_2 }}>
          页面显示出新错误， 请
          <Typography.Link onClick={() => {
            window.location.reload();
          }}>
            刷新
          </Typography.Link>
          当前页面
        </Typography.Text>
      }

      <AntdButton>
        {returnButton ?
          returnButton
          :
          <Button style={{
            color: "#fff",
            backgroundColor: StyleUtils.theme().mainButtonColor,
          }} onClick={() => {
            window.location.replace("/")
          }}>
            返回首页
          </Button>
        }
      </AntdButton>
    </Row>
  );
};

export default NotFoundPage;
