import { Button, Input, Modal, Row } from "antd"
import events from "../../../utils/events"
import { AppEvent, AppMessageType } from "../../../data/events"
import { useEffect, useState } from "react"
import { ApiCode, ServerGroup } from "../../../data/common"
import { UserInfoManager } from "../../../utils/UserInfoManager"
import Api from "../../../utils/Api"
import { StyleUtils } from "../../../utils/Style"
import { AntdButton, AntdInput } from "../../../views/commonview"
import { GlobalUtil } from "../../../utils/GlobalText"
import { formatStr } from "../../../utils/Utils"
import CheckUtils from "../../../utils/checkUtils"
export interface Props {
    show: boolean
    onClick: (ok: boolean) => void
}
export const AddGroupModal: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const disable = CheckUtils.checkRemarks(name) !== ""

    useEffect(() => {
        setName("")
    }, [props.show])
    return <Modal
        width={"50%"}
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", fontWeight: 500, color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("work.addGroup")}</div>

            <Row align={"middle"} style={{columnGap:'.5rem', marginTop: StyleUtils.getHeight(12) }}>
                <div style={{ fontSize: StyleUtils.getFont(10), color: StyleUtils.theme().fontColor_1 }}>{formatStr(GlobalUtil.t("work.addInGroup"), UserInfoManager.mainAccount?.account)}</div>
                {name && CheckUtils.checkRemarks(name) &&
                    <div style={{ fontSize: 12, color: "red" }}>{GlobalUtil.t("work.addErrorTip")}</div>
                }
            </Row>
            <div style={{ marginTop: StyleUtils.getHeight(8) }}>
                <AntdInput>
                    <Input
                        value={name}
                        placeholder={GlobalUtil.t("work.groupTip")}
                        onChange={(e) => {
                            setName(e.target.value.trim())
                        }}
                    />
                </AntdInput>
            </div>

            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let res = await Api.addGroup(name)
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("confirm")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}