import { Modal } from "antd"
import { StyleUtils } from "../utils/Style"
import { GlobalUtil } from "../utils/GlobalText"
export interface Props {
    show: boolean
    onClick: (ok: boolean) => void
}
export const TipDoubleCheckView:React.FC<Props> = (props) => {
    return <Modal
        maskClosable={false}
        title={GlobalUtil.t('tip')}
        open={props.show}
        onOk={()=>{
            props.onClick(true)
        }}
        onCancel={()=>{
            props.onClick(false)
        }}
        okText={GlobalUtil.t('confirm')}
        cancelText={GlobalUtil.t('cancel')}
    >
        <p style={{color:StyleUtils.theme().fontColor_1}}>{GlobalUtil.t('account.confirm_double_check')}</p>
    </Modal>
}