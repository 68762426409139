import { FC, ReactNode } from "react";

interface Props {
    children: ReactNode
}

export const Pc: FC<Props> = ({ children }) => {
    return (
        <div className="PC">
            {children}
        </div>
    )
}

export const Mobile: FC<Props> = ({ children }) => {
    return (
        <div className="Mobile">
            {children}
        </div>
    )
}