import axios, { AxiosResponse } from 'axios'
import {
    ServerAccountInfo,
    ApiCode,
    BaseResponse,
    ServerAccountListInfo,
    GoogleBindInfo,
    ServerUserInfo,
    BaseListResponse,
    LoginHistory,
    ServerUserSetting,
    Currency,
    ServerUserWalletStatus,
    ServerWalletAddress,
    OnlyReadData,
    ServerGroup,
    ServerWorkerInfoResult,
    ServerHashrateHistory,
    ServerHomeHistory,
    ServerTotalRevenue,
    ServerIncomeInfo,
    ServerPayoutInfo,
    ServerNonPayoutReasons,
    ServerAccountListInfoWithHashrate,
    ServerHomeInfo,
    ServerShareAddress,
    ServerHistoryValue,

    ServerWorkerState,
    ServerHomeBlockInfoContain,
    ReportListItem,
} from '../data/common'
import { AppEvent } from '../data/events'
import events from './events'
import { UserInfoManager } from './UserInfoManager'
import { WorkHashrateListReq } from '../data/request'
axios.defaults.withCredentials = true

console.log(window.location.hostname);
const instance = axios.create({
    baseURL: '',
    // baseURL: 'https://test.pool9527.com/',
    timeout: 30000,
    withCredentials: true, // 允许携带cookie
})
// 请求拦截器
instance.interceptors.request.use(config => {
    const currentHostname = window.location.hostname;
    config.baseURL = `https://${currentHostname}`;

    if (!config.url?.startsWith("/share")) {
        config.headers["x-currency"] = UserInfoManager.currency || localStorage.getItem('shareCurrency');
        config.headers["x-language"] = UserInfoManager.language;
        config.headers["x-account"] = UserInfoManager.currentAccount;
    } else {
        config.headers["x-currency"] = localStorage.getItem('shareCurrency');
        config.headers["x-language"] = UserInfoManager.language;
        config.headers["x-account"] = UserInfoManager.shareAccount;
    }
    config.headers["KeepMeLoggedIn"] = true;
    config.headers["Access-Control-Allow-Origin"] = "";
    return config;
}, err => {
    return err;
})
instance.interceptors.response.use((resp: AxiosResponse) => {
    const siteKey = resp.headers['site-key'];
    if (siteKey != null && siteKey.length > 0) {
        return {
            "code": ApiCode.NEED_CHECK,
            "data": siteKey as string
        }
    }
    const { responseURL } = resp.request as XMLHttpRequest
    if (resp.data === "401 Unauthorized") {
        events.emit(AppEvent.Unauthorized);
        return {
            "code": ApiCode.NEED_LOGIN
        }
    }
    if (responseURL.indexOf("sso/api") > 0) {
        if (resp.status === 200) {//
            const { status, message, data } = resp.data
            return {
                "code": (status === "OK") ? ApiCode.SUCCESS : ApiCode.FAIL,
                "msg": message,
                "data": data
            }
        }
        return {
            "code": resp.status,

        }
    }
    const { msg, data, succ } = resp.data;
    if (resp.status === 200) {
        // blob格式返回 => 错误处理
        if (resp.config.responseType === 'blob') {
            return resp.data
        }
        if (!resp.config.url?.startsWith("/share")) {
            const currency = resp.headers["x-currency"]// 从业务请求获取
            const account = resp.headers["x-account"]// 当前用户
            const language = resp.headers["x-language"]
            UserInfoManager.updateWithHeader(account, currency, language);
        } else {
            const currency = resp.headers["x-currency"]// 从业务请求获取
            const account = resp.headers["x-account"]// 当前用户
            const auth = resp.headers["x-authoritys"]// 当前用户
            UserInfoManager.updateShareHeader(account, currency, auth)
        }
        return {
            "code": succ ? ApiCode.SUCCESS : ApiCode.FAIL,
            "data": data,
            "msg": msg
        }
    }
    if (false) {
        return resp.data
    }
    return Promise.reject(new Error(msg ?? ""))
}, err => {
    return err
})

class IApi {
    getHomeInfo = (): Promise<BaseResponse<ServerHomeInfo>> => {
        return instance.post("/api/info")
    }

    getShareAddress = (walletAddr: string): Promise<BaseResponse<ServerShareAddress>> => {
        return instance.post("/api/searchWalletAddr", {
            "walletAddr": walletAddr
        })
    }

    getMindBlocks = (): Promise<BaseResponse<ServerHomeBlockInfoContain>> => {
        ///api/minedBlocks
        return instance.post("/api/minedBlocks")

    }

    ///api/poolStatus
    //1.24h 2.30天
    getPoolStatus = (type: number): Promise<BaseResponse<ServerHistoryValue[]>> => {
        return instance.post("/api/poolStatus", {
            "type": type
        })
    }
    getCurrencyList = (shareUrl: string = ""): Promise<BaseResponse<Currency[]>> => {
        return instance.post(shareUrl + "/api/currencys")
    }

    getUserSetting = (): Promise<BaseResponse<ServerUserSetting>> => {
        return instance.post("/api/security/getSetting")
    }

    changePwd = (oldPwd: string, newPwd: string): Promise<BaseResponse<string>> => {
        return instance.post("/sso/api/user/change_password", {
            "old_password": oldPwd,
            "password": newPwd
        },)

    }
    setDoubleCheck = async (emailCode: string, code: string, password: string, enableTwoFactor: boolean): Promise<BaseResponse<string>> => {
        return instance.post("/api/security/twoFactor", {
            "enableTwoFactor": enableTwoFactor,
            "password": password,
            "code": emailCode
        }, {
            headers: {
                "x-totp-token": code
            }
        })
    }

    updateDesc = (account: string, desc: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/user/updateDesc", {
            "account": account,
            "description": desc
        })
    }

    getUserInfo = (): Promise<BaseResponse<ServerUserInfo>> => {
        return instance.get("/sso/api/user/info")
    }
    login = (emai: string, password: string, token: string): Promise<BaseResponse<string>> => {
        return instance.post("/sso/api/firstfactor", {
            "username": emai,
            "password": password,
            "KeepMeLoggedIn": true,
            "token": token,
        }, {
            withCredentials: true
        })
    }
    logout = (): Promise<BaseResponse<string>> => {
        return instance.post("/sso/api/logout")
    }
    deleteSubAccount = (accounts: string, code: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/user/deleteSub", {
            "accounts": accounts,
        }, {
            headers: {
                "x-totp-token": code
            }
        })
    }
    hideAccount = (name: string, isHide: Boolean): Promise<BaseResponse<string>> => {
        return instance.post("/api/user/setHide", {
            "accounts": name,
            "isHide": isHide
        })
    }
    deleteAccount = (code: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/user/deleteAccount", {
        }, {
            headers: {
                "x-totp-token": code
            }
        })
    }
    addAccount = (name: string, desc: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/user/addAccount", {
            "name": name,
            "description": desc
        })
    }

    getResetPasswordInfo = (token: string): Promise<BaseResponse<string>> => {
        ///api/reset-password/identity/finish
        return instance.post("/sso/api/reset-password/identity/finish", {
            "token": token
        }, {
            withCredentials: true
        })
    }

    resetPassword = (password: string): Promise<BaseResponse<string>> => {
        return instance.post("/sso/api/reset-password", {
            "password": password,
        })
    }
    register = (username: string, password: string, verifycode: string, nickname: string): Promise<BaseResponse<string>> => {

        return instance.post("/sso/api/user/register", {
            "username": username,
            "password": password,
            "verifycode": verifycode,
            "nickname": nickname

        })
    }
    sendForgetCode = (email: string): Promise<BaseResponse<String>> => {
        return instance.post("/sso/api/reset-password/identity/start", {
            "username": email
        })
    }
    sendRegisterCode = (email: string): Promise<BaseResponse<String>> => {
        return instance.post("/sso/api/user/check_register", {
            "username": email
        })
    }
    sendDoubleCheckCode = (email: string): Promise<BaseResponse<String>> => {
        return instance.post("/api/security/sendCode", {
            "email": email
        })
    }

    getAccountsWithHashrate = (shareUrl: string = ""): Promise<BaseResponse<ServerAccountListInfoWithHashrate[]>> => {
        return instance.post(shareUrl + "/api/accounts", {
        })
    }
    getAccounts = (isHide: Boolean = false): Promise<BaseResponse<ServerAccountListInfo[]>> => {
        return instance.post("/api/user/accounts", {
            isHide: isHide
        })
    }

    getGoogleBindInfo = (): Promise<BaseResponse<GoogleBindInfo>> => {
        return instance.post("/sso/api/secondfactor/totp/identity/api_finish", {

        })
    }

    getLoginHistory = (): Promise<BaseListResponse<LoginHistory>> => {
        return instance.post("/api/loginHistory", {
            "current": 1,
            "size": 100
        })
    }


    getWalletAddress = (account: string, currency: string): Promise<BaseResponse<ServerWalletAddress>> => {
        return instance.post("/api/wallet/getAddress", {
            "account": account,
            "currency": currency
        })
    }

    getUserWalletState = (account: string, currency: string): Promise<BaseResponse<ServerUserWalletStatus[]>> => {
        return instance.post("/api/wallet/getWalletList", {
            "account": account,
            "currency": currency
        })
    }
    cancelAddress = (account: string, currency: string): Promise<BaseResponse<string>> => {
        ///api/wallet/cancelAddress
        return instance.post("/api/wallet/cancelAddress", {
            "account": account,
            "currency": currency
        })
    }
    sendActiveEmail = (account: string, currency: string): Promise<BaseResponse<string>> => {
        ///api/wallet/cancelAddress
        return instance.post("/api/wallet/sendActiveEmail", {
            "account": account,
            "currency": currency
        })
    }

    saveWalletAddress = (account: string, currency: string, address: string, addressDesc: string, syncAccounts: string, code: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/wallet/saveAddress", {
            "account": account,
            "currency": currency,
            "address": address,
            "addressDesc": addressDesc,
            "syncAccounts": syncAccounts,
        }, {
            headers: {
                "x-totp-token": code
            }
        })
    }
    syncWalletAddress = (account: string, currency: string, address: string, syncAccounts: string, code: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/wallet/syncAddress", {
            "account": account,
            "currency": currency,
            "address": address,
            "syncAccounts": syncAccounts,
        }, {
            headers: {
                "x-totp-token": code
            }
        })
    }

    ///api/wallet/setPayoutStatus

    setPayoutStatus = (account: string, currency: string, isActivePayout: boolean): Promise<BaseResponse<string>> => {
        return instance.post("/api/wallet/setPayoutStatus", {
            "account": account,
            "currency": currency,
            "isActivePayout": isActivePayout ? '1' : "0"
        })
    }
    addOnlyRead = (accounts: string, currencies: string, desc: string, accessPermission: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/linker/addReadonly", {
            "accounts": accounts,
            "currencies": currencies,
            "accessPermission": accessPermission,
            "name": desc
        })
    }

    updateOnlyRead = (id: string, accounts: string, currencies: string, desc: string, accessPermission: string): Promise<BaseResponse<string>> => {

        return instance.post("/api/linker/updateReadonly", {
            "accounts": accounts,
            "currencies": currencies,
            "id": id,
            "accessPermission": accessPermission,
            "name": desc
        })
    }

    deleteOnlyRead = (id: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/linker/deleteReadonly", {
            "ids": id
        })
    }
    getOnlyRead = (): Promise<BaseResponse<OnlyReadData[]>> => {
        return instance.post("/api/linker/list", {
        })
    }
    activateWallet = (currency: string, code: string): Promise<BaseListResponse<string>> => {
        return instance.post("/api/wallet/activate", {
            "code": code,
            "currency": currency
        })
    }

    addGroup = (name: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/group/addGroup", {
            "name": name
        })
    }
    updateGroup = (id: string, name: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/group/updateGroup", {
            "name": name,
            "id": id
        })
    }
    getGroup = (): Promise<BaseResponse<ServerGroup[]>> => {
        return instance.post("/api/group/list", {
            "account": UserInfoManager.mainAccount?.account ?? "",
            "currency": UserInfoManager.currency
        })
    }
    deleteGroup = (id: string): Promise<BaseResponse<ServerGroup[]>> => {
        return instance.post("/api/group/deleteGroup", {
            "id": id,
        })
    }

    getWorkState = (shareurl: string): Promise<BaseResponse<ServerWorkerState>> => {
        ///api/worker/state
        return instance.post(shareurl + "/api/worker/state")
    }
    //
    getWorkHashrateList = (shareUrl: string, req: WorkHashrateListReq): Promise<BaseResponse<ServerWorkerInfoResult>> => {
        return instance.post(shareUrl + "/api/worker/detail", {
            "size": req.size,
            "current": req.current,
            "state": req.workerStatus,
            "groupId": req.groupId,
            "workerName": req.searchWorker
        })
    }

    ///api/hashrate/singleWorkerHashrateHistory
    getWorkHashrateHistory = (shareUrl: string, workerName: string): Promise<BaseResponse<ServerHashrateHistory[]>> => {
        return instance.post(shareUrl + "/api/worker/singleHistory", {
            "workerName": workerName
        })
    }

    setWorkGroup = (group: string, workerId: string): Promise<BaseResponse<string>> => {
        ///api/group/setWorkerGroup
        return instance.post("/api/worker/moveGroup", {
            "groupId": group,
            "ids": workerId
        })
    }

    deleteWorkers = (ids: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/worker/delete", {
            "ids": ids
        })
    }

    ///api/hashrate/userHashrateHistory
    getUserHashrateHistory = (shareUrl: string, duration: number, account: string): Promise<BaseResponse<ServerHomeHistory[]>> => {
        return instance.post(shareUrl + "/api/worker/history", {
            "type": duration,
            "account": account
        })
    }

    getHomeUserInfo = (): Promise<BaseResponse<string>> => {
        return instance.post("/api/userHomeInfo", {
        })
    }

    ///api/curEnvInfo
    setCurEnvInfo = (account: string, currency: string, language: string, shareUrl: string = ""): Promise<BaseResponse<string>> => {
        return instance.post(shareUrl + "/api/curEnvInfo", {
            "language": language,
            "account": account,
            "currency": currency
        })
    }

    //


    getTotalRevenue = (shareUrl: string): Promise<BaseResponse<ServerTotalRevenue>> => {
        return instance.post(shareUrl + "/api/revenue/total")
    }

    ///api/revenue/incomes
    getIncomeList = (shareUrl: string, current: number = 1, size: number = 10): Promise<BaseListResponse<ServerIncomeInfo>> => {
        return instance.post(shareUrl + "/api/revenue/incomes", {
            "current": current,
            "size": size
        })
    }
    getPayoutList = (shareUrl: string, current: number = 1, size: number = 10): Promise<BaseListResponse<ServerPayoutInfo>> => {
        return instance.post(shareUrl + "/api/revenue/payouts", {
            "current": current,
            "size": size
        })
    }

    ///api/revenue/nonPayoutReasons
    nonPayoutReasons = (): Promise<BaseResponse<ServerNonPayoutReasons>> => {
        return instance.post("/api/revenue/nonPayoutReasons")
    }



    ///api/revenue/export 
    //date 2023-04-21
    //excelType  1:收入，2:支出
    exportRevenue = (startDate: string, endDate: string, excelType: string, account: string) => {
        return instance.post("/api/revenue/export", {
            "startDate": startDate,
            "endDate": endDate,
            "excelType": excelType,
            "accounts": account,
        }, {
            // 设置responseType对象格式为blob
            responseType: "blob"
        })
    }

    ///api/report/list
    reportList = (): Promise<BaseResponse<ReportListItem[]>> => {
        return instance.post("/api/report/list")
    }

    ///api/report/delete

    deleteReport = (id: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/report/delete", {
            "id": id
        })
    }

    exportSampleReport = (week: boolean) => {
        return instance.post("/api/report/exportSample", {
            "type": week ? "1" : "2"
        }, {
            // 设置responseType对象格式为blob
            responseType: "blob"
        })
    }
    //周报weekly，月报monthly
    addReportList = (week: boolean, currency: string, pushAccounts: string, language: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/report/add", {
            "language": language,
            "currency": currency,
            "pushAccounts": pushAccounts,
            "type": week ? "1" : "2"
        })
    }

    ///api/report/update
    updateReportList = (id: string, pushAccounts: string, language: string): Promise<BaseResponse<string>> => {
        return instance.post("/api/report/update", {
            "language": language,
            "pushAccounts": pushAccounts,
            "id": id
        })
    }

    ///api/report/pushStatus
    updatePushStatus = (id: string, status: boolean): Promise<BaseResponse<string>> => {
        return instance.post("/api/report/pushStatus", {
            "isPushNotice": status,
            "id": id
        })
    }
}

var Api = new IApi()

export default Api