import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons"
import { Row, Space, Image, Button } from "antd"
import { StyleUtils } from "../utils/Style"

import { Currency } from "../data/common";
import { Carousel } from "@trendyol-js/react-carousel";
import { UserInfoManager } from "../utils/UserInfoManager";

interface CauProps {
    onClick?: (isSelect: boolean, v: Currency) => void;
    currency?: Currency | null

}

export const Caurael = (props: CauProps) => {
    const { onClick, currency } = props

    return (
        <Row justify={"space-between"} align={"middle"} style={{ flexFlow: "row nowrap", gap: ".5rem", paddingBottom: "1rem", width: "100%", borderBottom: `1px dashed ${StyleUtils.getBorderColor()}` }}>
            {/* 
                @document: https://trendyol.github.io/react-carousel/docs/carousel
            */}
            <Carousel
                className="carousel"
                slide={4}
                show={13}
                swiping={true}
                infinite={false}
                responsive={true}
                useArrowKeys={true}
                dynamic={true}
                leftArrow={<LeftCircleOutlined style={{ color: StyleUtils.theme().mainButtonColor, fontSize: "1.25rem" }} />}
                rightArrow={<RightCircleOutlined style={{ color: StyleUtils.theme().mainButtonColor, fontSize: "1.25rem" }} />}
            >
                {   // 如果要看效果可将下方注释打开 //FIXME: remove commentary
                    // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].flatMap((v, index) => {
                    // return 
                    UserInfoManager.currencyList.flatMap((v, index) => {

                        let isSelect = currency?.code === v.code
                        return (
                            <Row
                                key={v.name}
                                style={{
                                    cursor: "pointer",
                                    flexFlow: "row nowrap",
                                    columnGap: ".25rem",
                                    background: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().disabledButtoinColor,
                                    padding: "4px 12px",
                                    borderRadius: "8px",
                                    marginRight: ".75rem"
                                }}
                                justify={"center"}
                                align={"middle"}
                                onClick={(e) => {
                                    onClick && onClick(isSelect, v)
                                }} >
                                <Image style={{ width: "1.125rem", height: "1.125rem", display: "flex", alignItems: "center" }} src={v?.icon ?? ""} preview={false} />

                                <div style={{ color: !isSelect ? "#899199" : "white", display: "flex", alignItems: "center",  }}>{v?.name}</div>
                            </Row>
                        )
                        // })
                    }
                        // return <Button type="primary" key={v} style={{ padding: "4px 32px" }}>
                        //     {v}
                        // </Button>
                    )
                }
            </Carousel>


        </Row>
    )
}
