import { Card, Row, Timeline } from "antd"
import { FC, useState } from "react"
import { StyleUtils } from "../../../utils/Style"
import { AntdCard, AppMainText } from "../../../views/commonview"
import '../index.css';
import { AppEvent } from "../../../data/events"
import events, { useEventBus } from "../../../utils/events"
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { cb as dark, base16AteliersulphurpoolLight as light, } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { GlobalUtil } from "../../../utils/GlobalText";


function HelpDoc() {

    return (
        `* ${GlobalUtil.t("miner_help.parmas_desc")}
    ~~~shell

    USAGE:
        dr-worker [FLAGS] [OPTIONS]

    FLAGS:
            --debug
                Enable debug logging

        -h, --help
                Prints help information

            --list-devices
                List available mining devices and exit

            --new
                new aleo address

        -V, --version
                Number of threads, GPU defaults is 2, CPU defaults is 7
    OPTIONS:
        -a, --algorithm <algorithm>
                Select the algorithm Currently supported: aleo (Aleo) [default: aleo]

        -d, --devices <cuda>
                Indexes of GPUs to use (starts from 0) Example: -d 0
                Specify multiple times to use multiple GPUs Example: -d 0,1,2
                Note: Pure CPU proving will be disabled as each GPU job requires one CPU thread as well

        -l, --log-file <log>
                Enable logging output of the worker to the specified log file

        -u, --url <poolurl>
                Set the pool URL Format: <working protocol>://<pool>:<port>

        -s, --solo_address <solo-address>
                solo address

        -t, --threads <threads>
                Number of threads, GPU defaults is 2, CPU defaults is 7

        -w, --worker <worker>
                Sets the worker name [default: accountName.minerName]
    ~~~
    
* ${GlobalUtil.t("miner_help.start")}
    ~~~shell
    # ${GlobalUtil.t("miner_help.set")}
    chmod +x dr-worker

    # ${GlobalUtil.t("miner_help.start_command")}
    1.wallet address：
    ./dr-worker -w aleo1234567abcdefgh75a628wvj55klu9cgswxuc4c6v4gyh8avrsc0w998  -u stratum+tcp://aleo.drpool.io:30127 -d 0

    2.accountName + "." + minerName
    ./dr-worker -w <accountName>.<minerName> -u stratum+tcp://aleo.drpool.io:30127 -d 0

    ~~~
`
    );
}

export const AleoGuidePage: FC = () => {
    const [changeStyle, setChangeStyle] = useState(true)

    events.on(AppEvent.ChangeStyle, (changeType: string) => {
        setChangeStyle(!changeStyle)
    })
    useEventBus(AppEvent.REFRES, (changeType: string) => {
        setChangeStyle(!changeStyle)
    })

    return (
        <>
            <Row justify={"center"} style={{ color: StyleUtils.theme().fontColor_1, fontSize: "2.4rem", alignItems: "center", marginTop: StyleUtils.getWidth(36), marginBottom: StyleUtils.getWidth(18) }}>
                <div style={{ fontWeight: "500" }}>

                    <AppMainText text={GlobalUtil.t("miner_help.miner_help")} />
                </div>
            </Row>
            <Timeline className="tml"
                style={{ marginBottom: '64px' }}
                items={[
                    {
                        children: <AntdCard><Card>
                            <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("miner_help.prepare")}</div>
                            <div style={{ ...StyleUtils.fontStyle_3() }}>
                                <p>{GlobalUtil.t("miner_help.miner_device")} {GlobalUtil.t("miner_help.miner_device_desc")}</p>
                                <p> {GlobalUtil.t("miner_help.system")} {GlobalUtil.t("miner_help.system_desc")}</p>
                                {/* TODO: 下载地址缺失 */}
                                <p> {GlobalUtil.t("miner_help.software")} <a href="" target="_blank">{GlobalUtil.t("miner_help.software_download")}</a></p>
                            </div>
                        </Card>
                        </AntdCard>
                    },
                    {
                        children: <AntdCard><Card>
                            <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("miner_help.regist")}</div>
                            <div style={{ ...StyleUtils.fontStyle_3() }}>
                                <p>{GlobalUtil.t("miner_help.regist_desc")}</p>
                            </div>
                        </Card>
                        </AntdCard>
                    },
                    {
                        children: <AntdCard><Card>
                            <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("miner_help.start_and_set")}</div>
                            <div style={{ ...StyleUtils.fontStyle_3() }}>
                                <p></p>

                                <ReactMarkdown
                                    className=""
                                    children={String(HelpDoc())}
                                    components={{
                                        code({ node, inline, className, children, ...props }) {
                                            const match = /language-(\w+)/.exec(className || '')
                                            return (
                                                !inline && match ?
                                                    <SyntaxHighlighter
                                                        {...props}
                                                        customStyle={{
                                                            fontSize: StyleUtils.getFont(14),
                                                            padding: "8px 16px",
                                                            borderRadius: '.5rem'
                                                        }}
                                                        children={String(children).replace(/\n$/, '')}
                                                        style={StyleUtils.isDark ? dark : light}
                                                        language={match[1]}
                                                        PreTag={"div"}
                                                    />
                                                    :
                                                    <code {...props} style={{ fontSize: StyleUtils.getFont(0.88) }} className={className}>
                                                        {children}
                                                    </code>
                                            )
                                        }
                                    }}
                                />


                            </div>
                        </Card >
                        </AntdCard >
                    },
                ]}
            />

        </>
    )
}