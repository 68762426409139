import { Button, ConfigProvider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { LoginHistory, ReportListItem } from '../../../data/common';
import { GlobalUtil } from '../../../utils/GlobalText';
import { UserInfoManager } from '../../../utils/UserInfoManager';
import { AppButton } from '../../../views/commonview';
import { StyleUtils } from '../../../utils/Style';
import Api from '../../../utils/Api';
import { ReportListView } from './report/ReportListView';
import AddReportModel from '../../../modal/AddReportModel';
import events from '../../../utils/events';
import { AppEvent, AppMessageType } from '../../../data/events';
import { ok } from 'assert';
import { formatStr } from '../../../utils/Utils';



const TabReportView = () => {
    let loadData = async () => {
        UserInfoManager.getAccountInfo()
    }
    useEffect(() => {
        loadData()
    }, [])
    let [editItem, setEditItem] = useState<ReportListItem | null>()
    events.on(AppEvent.REPORT_EDIT_ITEM, (item) => {
        setEditItem(item)
        setShow(true)
    })
    let [show, setShow] = useState(false)
    let [week, setWeek] = useState(false)
    return <div style={{ width: "100%", background: StyleUtils.theme().boardBg, paddingTop: "1rem" }}>
        <AddReportModel show={show} data={editItem} week={week} onClick={(ok) => {
            setEditItem(null)
            setShow(false)
            if (ok) {
                events.emit(AppEvent.REPORT_UPDATE_LIST)
            }
        }} />
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Row justify={"space-between"} align={"middle"}>
                    <div style={{ fontWeight: "500", fontSize: "0.9rem", color: StyleUtils.theme().fontColor_2 }}>{GlobalUtil.t("report.week")}</div>
                    <div style={{ display: "flex", }}>
                        <AppButton
                            style={{ padding: "4px 12px" }}
                            disabled={false} text={GlobalUtil.t("add")} onClick={() => {
                                setWeek(true)
                                setShow(true)
                            }} />
                    </div>
                </Row>

                <div style={{ fontSize: "0.8rem", color: StyleUtils.theme().fontColor_1, marginTop: StyleUtils.getWidth(7) }}>
                    {GlobalUtil.t("report.weekTip")}
                    <div style={{ cursor: "pointer", color: StyleUtils.theme().mainButtonColor }} onClick={async () => {
                        try {
                            let res = await Api.exportSampleReport(true)
                            const url = window.URL.createObjectURL(new Blob([res as any],
                                { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }));
                            const link = document.createElement('a');
                            link.href = url;
                            const fileName = formatStr("week_report.xlsx")
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link)
                        } catch (e) {
                            events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.ERROR,
                                message: GlobalUtil.t("errorTip")
                            })
                        }
                    }}>
                        {GlobalUtil.t("report.weekTemplate")}
                    </div>
                </div>

            </div>
        </div>
        <ReportListView week={true} />
        <div style={{ height: StyleUtils.getWidth(1), width: "100%", background: StyleUtils.theme().mainColor, marginTop: StyleUtils.getWidth(11) }} />
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1rem" }}>
            <Row justify={"space-between"} align={"middle"}>
                <div style={{ fontWeight: "500", fontSize: "0.9rem", color: StyleUtils.theme().fontColor_2 }}>{GlobalUtil.t("report.month")}</div>

                <div style={{ display: "flex" }}>
                    <AppButton
                        style={{ padding: "4px 12px" }}
                        disabled={false} text={GlobalUtil.t("add")}
                        onClick={() => {
                            setWeek(false)
                            setShow(true)
                        }} />
                </div>
            </Row>
            <div style={{ fontSize: "0.8rem", color: StyleUtils.theme().fontColor_1, marginTop: StyleUtils.getWidth(7) }}>
                {GlobalUtil.t("report.monthTip")}
                <div style={{ cursor: "pointer", color: StyleUtils.theme().mainButtonColor }} onClick={async () => {
                    try {
                        let res = await Api.exportSampleReport(false)
                        const url = window.URL.createObjectURL(new Blob([res as any],
                            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }));
                        const link = document.createElement('a');
                        link.href = url;
                        const fileName = formatStr("month_report.xlsx")
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link)
                    } catch (e) {
                        events.emit(AppEvent.SHOW_MESSAGE, {
                            type: AppMessageType.ERROR,
                            message: GlobalUtil.t("errorTip")
                        })
                    }
                }}>  {GlobalUtil.t("report.monthTemplate")}</div>
            </div>
        </div>
        <ReportListView week={false} />
    </div>
}

export default TabReportView