import { useState } from "react"
import { AppEvent } from "../data/events"
import events from "../utils/events"
import { UserInfoManager } from "../utils/UserInfoManager"
import DoubleCheckView from "../modal/DoubleCheckView"
import { TipDoubleCheckView } from "../modal/TipDoubleCheckView"
import ChangePwdView from "../modal/ChangePwdView"
import CloseDoubleCheckView from "../modal/CloseDoubleCheckView"
import DeleteAccountView from "../modal/DeleteAccountView"

export const GlobalModal = () => {
    const [showDobuleCheck, setShowDoubleCheck] = useState(false)
    const [showTipDobuleCheck, setTipShowDoubleCheck] = useState(false)
    const [changePwd, setChangePwd] = useState(false)
    const [closeDobuleCheck, setCloseDoubleCheck] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const isLogin = UserInfoManager.isLogin()

    events.on(AppEvent.SHOW_DOUBLE_CHECC, () => {
        setShowDoubleCheck(true)
    })
    events.on(AppEvent.SHOW_TIP_DOUBLE_CHECC, () => {
        setTipShowDoubleCheck(true)
    })
    events.on(AppEvent.CHANGE_PWD, () => {
        setChangePwd(true)
    })
    events.on(AppEvent.CLOSE_DOUBLE_CHECC, () => {
        setCloseDoubleCheck(true)
    })
    events.on(AppEvent.DELETE_MAIN_ACCOUNT, () => {
        setShowDelete(true)
    })

    return <div>
        <DeleteAccountView show={showDelete && isLogin} onClick={
            (ok) => {
                setShowDelete(false)
            }
        } />
        <DoubleCheckView show={showDobuleCheck && isLogin} onClick={
            (ok) => {
                setShowDoubleCheck(false)
            }
        } />
        <TipDoubleCheckView show={showTipDobuleCheck && isLogin} onClick={
            (ok) => {
                if (ok) {
                    setShowDoubleCheck(true)
                }
                setTipShowDoubleCheck(false)
            }
        } />
        <ChangePwdView show={changePwd && isLogin} onClick={
            (ok) => {
                setChangePwd(false)
            }
        } />
        <CloseDoubleCheckView show={closeDobuleCheck && isLogin} onClick={
            (ok) => {
                setCloseDoubleCheck(false)
            }
        } />


    </div>
}