import { Button, Card, Modal, Row } from "antd";
import React, { CSSProperties, useState } from "react";
import { Account, ApiCode } from "../../../data/common";
import { AppEvent, AppMessageType } from "../../../data/events";
import Api from "../../../utils/Api";
import events, { useEventBus } from "../../../utils/events";
import { UserInfoManager } from "../../../utils/UserInfoManager";
import AccountHideListView from "./account/AccountHideListView";
import AccountListView from "./account/AccountListView";
import AddAccountView from "./account/AddAccountView";
import { AppButton } from "../../../views/commonview";
import { StyleUtils } from "../../../utils/Style";
import { GlobalUtil } from "../../../utils/GlobalText";
import DeleteAccountView from "./account/DeleteAccountView";
const { confirm } = Modal;
interface TabProps {
    label: string;
    selected: boolean;
    onClick: () => void;
    style?: CSSProperties
}

const Tab = ({ label, selected, onClick, style }: TabProps) => {

    return (
        <AppButton
            text={label}
            disabled={!selected}
            canClick={true}
            onClick={onClick}
            style={{ padding:'4px 12px',background: selected ? StyleUtils.theme().mainButtonColor : "", ...style }} />
    );
};

const AccountView = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectAccount, setSelectAccount] = useState<Account[]>([]);

    const tabData = [
        {
            "label": GlobalUtil.t("account.normalAccount"),
            "element": <AccountListView onSelectChange={(selects) => {
                setSelectAccount(selects)
            }} />
        },
        {
            "label": GlobalUtil.t("account.hiddenAccount"),
            "element": <AccountHideListView onSelectChange={(selects) => {
                setSelectAccount(selects)
            }} />
        }
    ]
    const handleTabClick = (tabIndex: number) => {
        setSelectAccount([])
        setSelectedTab(tabIndex);
    };
    const buttonStyle = { padding: "4px 12px" }
    const [openAddAccount, setOpenAddAccount] = useState(false)
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState("")

    const setAccountHide = async (accounts: string, hide: Boolean) => {
        try {
            let res = await Api.hideAccount(accounts, hide)
            if (res.code === ApiCode.SUCCESS) {
                setSelectAccount([])
                UserInfoManager.getAccountInfoWithHashrate()
                UserInfoManager.getAccountInfo()
            } else {
                events.emit(AppEvent.SHOW_MESSAGE, {
                    type: AppMessageType.ERROR,
                    message: GlobalUtil.t("errorTip")
                })
            }
        } catch (e) {
            events.emit(AppEvent.SHOW_MESSAGE, {
                type: AppMessageType.ERROR,
                message: GlobalUtil.t("errorTip")
            })
        }
    }
    useEventBus(AppEvent.HIDE_ACCOUNT, (hideAccounts) => {
        setAccountHide(hideAccounts, true)
    })
    useEventBus(AppEvent.UNHIDE_ACCOUNT, (hideAccounts) => {
        setAccountHide(hideAccounts, false)
    })
    useEventBus(AppEvent.DELETE_SUB_ACCOUNT, (account) => {
        if (!UserInfoManager.mainAccount?.isTwofactorOpen) {
            events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
        } else {
            setDeleteAccount(account)
            setOpenDeleteAccount(true)
        }

    })
    const getSelectAccounts = (): string => {
        let accounts = ""
        selectAccount.forEach(v => {
            if (accounts.length) {
                accounts += ","
            }
            accounts += v.account
        })
        return accounts
    }
    const hideOrUnHideAccounts = async () => {
        setAccountHide(getSelectAccounts(), selectedTab === 0)
    }

    return (
        <div style={{ background: StyleUtils.theme().boardBg, paddingTop: "1rem", width: "100%" }}>
            {AddAccountView(openAddAccount, (ok) => {
                if (ok) {
                    UserInfoManager.getAccountInfoWithHashrate()
                    UserInfoManager.getAccountInfo()
                }
                setOpenAddAccount(false)
                return
            })}
            {
                <DeleteAccountView show={openDeleteAccount} account={deleteAccount} onClick={() => {
                    setDeleteAccount("")
                    setOpenDeleteAccount(false)
                }} />
            }

            <Row justify={"space-between"} style={{gap:'.5rem'}}>
                <div style={{ display: "flex", background: StyleUtils.theme().mainColor, borderRadius: "8px" }}>
                    {
                        tabData.flatMap((item, index) => {
                            return <Tab
                                label={item.label}
                                selected={selectedTab === index}
                                onClick={() => handleTabClick(index)}
                            />
                        })
                    }
                </div>

                <div style={{ display: "flex", gap: "8px" }}>
                    <AppButton  onClick={hideOrUnHideAccounts} style={buttonStyle} disabled={!selectAccount.length} text={selectedTab === 0 ? GlobalUtil.t("account.hidden") : GlobalUtil.t("account.noHidden")} />
                    <AppButton onClick={() => {
                        if (!UserInfoManager.mainAccount?.isTwofactorOpen) {
                            events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                        } else {
                            setDeleteAccount(getSelectAccounts())
                            setOpenDeleteAccount(true)
                        }
                    }} style={buttonStyle} disabled={!selectAccount.length} text={GlobalUtil.t("delete")} />
                    <AppButton onClick={() => { setOpenAddAccount(true) }} style={buttonStyle} text={GlobalUtil.t("add")} />
                    {/* {selectedTab === 0 && <Button style={buttonStyle}>排序</Button>} */}
                </div>

            </Row>
            <div style={{ marginTop: "0px", width: "100%" }}>
                {
                    tabData.filter((item, index) => {
                        return index === selectedTab
                    })[0].element
                }
            </div>
        </div>
    );
};

export default AccountView;
