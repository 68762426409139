import { Avatar, Button, Card, Dropdown, Image, List, MenuProps, Skeleton, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { ApiCode, HashrateDistribution, ServerGroup, ServerWorkerInfoResult } from "../../data/common";
import events, { useEventBus } from "../../utils/events";
import { AppEvent } from "../../data/events";
import WorkInfoView from "./views/WorkInfoView";
import { WorkInfoManager } from "../../utils/WorkData";
import { StyleUtils } from "../../utils/Style";
import { GlobalUtil } from "../../utils/GlobalText";
import WorkAccountTableView from "./views/AccountTableView";
import { AppMainText } from "../../views/commonview";

interface TopViewProp {
    title: string
    number?: number
}
const TopView: React.FC<TopViewProp> = (props) => {
    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
        <AppMainText fontSize={"2.25rem"} text={(props.number ?? 0).toString()} />
        <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }}>
            {props.title}
        </div>
    </div>
}
const WorkManagerPage = () => {
    const [changeStyle, setChangeStyle] = useState(true)
    events.on(AppEvent.ChangeStyle, (changeType: string) => {
        setChangeStyle(!changeStyle)
    })
    const [update, setUpdate] = useState(false)
    const [groupDataChange, setGroupDataChange] = useState(false)

    const [noData, setIsNoData] = useState<Boolean>(!WorkInfoManager.getGroupData())
    const itemData = WorkInfoManager.getWorkState()
    events.on(AppEvent.GROUP_UPDATE, () => {
        setUpdate(!update)
    })
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setIsNoData(false)
        setGroupDataChange(!groupDataChange)
    })
    events.on(AppEvent.UPDATE_USERINFO, () => {
        setUpdate(!update)
    })
    events.on(AppEvent.REFRES, () => {

        WorkInfoManager.reset()
        setUpdate(!update)
    })
    useEffect(() => {
        WorkInfoManager.reset()
    }, [])
    useEffect(() => {
        WorkInfoManager.init()
    }, [update])
    if (noData) {
        return <div style={{ height: "500px" }}></div>
    }
    return <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: StyleUtils.getWidth(36), paddingBottom: StyleUtils.getWidth(36), background: StyleUtils.theme().mainColor }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Card style={{ border: "none", background: StyleUtils.theme().boardBg }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <TopView title={GlobalUtil.t("work.all")} number={itemData?.allSize} />
                    <TopView title={GlobalUtil.t("work.online")} number={itemData?.onlineSize} />
                    <TopView title={GlobalUtil.t("work.offline")} number={itemData?.offlineSize} />
                    <TopView title={GlobalUtil.t("work.dead")} number={itemData?.deadSize} />

                </div>
            </Card>
            <div style={{ width: "100%", justifySelf: "center", justifyContent: "center", display: "flex", marginBottom: StyleUtils.getWidth(18), marginTop: StyleUtils.getWidth(36) }}>
                <AppMainText text={GlobalUtil.t('work.hashrate')} />
            </div>
            {!WorkInfoManager.shareUrl && <WorkAccountTableView />}
            <div style={{ fontWeight: 500, fontSize: "2.5rem", width: "100%", justifySelf: "center", justifyContent: "center", display: "flex", marginBottom: StyleUtils.getWidth(18), marginTop: StyleUtils.getWidth(36) }}>
                <AppMainText text={GlobalUtil.t('work.workers')} />
            </div>
            <div style={{ width: "100%", }}>
                <WorkInfoView />
            </div>
        </div>

    </div>
}

export default WorkManagerPage