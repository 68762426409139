import { Card, Dropdown, Input, Row, Space } from "antd";
import { useState } from "react";
import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { UserInfoManager } from "../utils/UserInfoManager";
import { ServerAccountListInfoWithHashrate } from "../data/common";
import { useNavigate } from "react-router-dom";
import { StyleUtils } from "../utils/Style";
import { GlobalUtil } from "../utils/GlobalText";
import { EllipsisMiddle } from "../components";
import { AntdInput } from "../views/commonview";
import events from "../utils/events";
import { AppEvent } from "../data/events";

const UserItem = (v: ServerAccountListInfoWithHashrate, onclick: () => void) => {
    let isSelect = v.account === UserInfoManager.currentAccount

    return <div key={v.account} style={{ display: "flex", padding: "8px 24px ", columnGap: "2rem", background: isSelect ? StyleUtils.theme().mainItemColor : StyleUtils.theme().mainColor, marginBottom: StyleUtils.getWidth(2) }} onClick={() => {
        onclick()
    }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "500", color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {EllipsisMiddle({ suffixCount: 4, children: v?.account || '' })}</div>
            <div style={{ fontSize: StyleUtils.getFont(14), fontWeight: "400", whiteSpace: "nowrap", marginTop: StyleUtils.getHeight(2), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> ({GlobalUtil.t("account.desc")}: {EllipsisMiddle({ suffixCount: 4, children: v?.description || '' })})</div>
        </div>
        <div style={{ display: "flex", width: "100%", ...StyleUtils.textWrap(), overflow: "inherit", paddingTop: StyleUtils.getHeight(8) }}>
            <div style={{ justifyContent: "right", alignContent: "center", display: "flex", fontSize: StyleUtils.getFont(14), color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_1 }}> {v.hashrate}</div>
        </div>
    </div>
}
const UserView: React.FC = () => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [searchValuse, setSearchValuse] = useState("")

    const handleSearchValue = (v: string) => {
        setSearchValuse(v)
        UserInfoManager.setAccountInfoWithHashrate(v)
    }
    events.on(AppEvent.ON_ACCOUNT_INFO_UPDATE, () => {
        setRefresh(!refresh)
    })
    events.on(AppEvent.REFRES, ({ account }) => {
        if (account) {
            setRefresh(!refresh)
        }
    })
    if (UserInfoManager == null || !UserInfoManager.isLogin()) {
        return <Row className="flex-grow">
            <div onClick={(e) => {
                navigate("/user/login")
            }} style={{ cursor: "pointer", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>

                <Space className="user-login" style={{ color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(12) }}>
                    {GlobalUtil.t('appbar.login')}
                </Space>

            </div>
        </Row>
    }
    return <Dropdown
        getPopupContainer={(node) => {
            return document.getElementById('account')!
        }}
        dropdownRender={() => {
            return <Card
                id="users"
                bodyStyle={{ padding: 0 }}
                style={{
                    borderColor: StyleUtils.theme().boardBg,
                    background: StyleUtils.theme().boardBg,
                    padding: 0,
                    maxHeight: '500px',
                    overflowY: 'auto',
                }}
                hoverable
            >
                <div style={{}}>
                    <AntdInput>
                        <Input
                            style={{
                                fontSize: "0.88rem",
                                display: "flex",
                                flex: "auto",
                                borderRadius: "8px",
                                // border: `1px solid ${StyleUtils.theme().mainColor}`,
                                border: "none",
                                boxShadow: "none",
                                padding: "4px 24px"
                            }}
                            value={searchValuse}
                            autoComplete="off"
                            placeholder={GlobalUtil.t("onlyread.searchTip")}
                            suffix={
                                <SearchOutlined style={{ color: "#899199" }} />
                            }
                            onChange={(e) => {
                                handleSearchValue(e.target.value.trim())
                            }}
                        />
                    </AntdInput>
                    {UserInfoManager.accountsWithHashrate.map((v, index) => {
                        return UserItem(v, () => {
                            setOpen(false);
                            UserInfoManager.updateAccount(v.account ?? "");
                        })
                    })}
                </div>
                <Row justify="space-around" style={{ padding: "8px 16px 16px" }}>
                    <div
                        style={{ cursor: "pointer", textAlign: "center", border: "1px solid #2F65FF", padding: "4px 0", borderRadius: "8px", width: "80px", color: StyleUtils.theme().mainButtonColor, fontSize: StyleUtils.getFont(13), justifyContent: "center", alignItems: "center", display: "flex" }}
                        onClick={() => {
                            setOpen(false)
                            navigate("/user/setting")
                        }}>{GlobalUtil.t("appbar.accountSetting")}</div>
                    <div
                        style={{ cursor: "pointer", border: "1px solid #2F65FF", padding: "4px 0", borderRadius: "8px", width: "80px", color: StyleUtils.theme().mainButtonColor, fontSize: StyleUtils.getFont(13), justifyContent: "center", alignItems: "center", display: "flex" }}
                        onClick={() => {
                            setOpen(false)
                            UserInfoManager.logout()
                        }} >{GlobalUtil.t("appbar.logout")}</div>
                </Row>
            </Card>
        }}
        open={isOpen}
        placement={"bottomRight"}
        trigger={['click']}
        onOpenChange={(open) => {
            if (open) {
                UserInfoManager.getAccountInfoWithHashrate()
            }
            setOpen(open)
        }}
    >
        <Row className="flex-grow">
            <div onClick={(e) => {
                if (!UserInfoManager.accountsWithHashrate.length) {
                    return
                }
                e.preventDefault()
            }} style={{ cursor: "pointer", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>
                <Space className="user-login" style={{ color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(13), }}>
                    {EllipsisMiddle({ suffixCount: 4, children: UserInfoManager.currentAccount || '' })}
                    {isOpen ? <UpOutlined /> : <DownOutlined />}
                </Space>
            </div>
        </Row>
    </Dropdown >
}

export default UserView