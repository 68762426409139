import { Button, Input, Modal, message, Image, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode, GoogleBindInfo } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import QRCode from 'qrcode.react';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { isValidEmail } from '../utils/Utils';
import { UserInfoManager } from '../utils/UserInfoManager';
import { AntdButton, AntdInput } from '../views/commonview';

export interface DoubleCheckProps {
    show: boolean
    onClick: (ok: boolean) => void
}
const Step2View = (onPrev: () => void, onClick: () => void) => {
    let [pwd, setPwd] = useState("")
    let [emaliCode, setEmailCode] = useState("")
    let [code, setCode] = useState("")
    let [isSendCode, setIsSendCode] = useState(false)
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (count > 0) {
            const timer = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [count]);
    const [loading, setLoading] = useState(false)
    const disable = !pwd || !code  //|| !emaliCode || !isSendCode 
    const clear = () => {
        setPwd("")
        setEmailCode("")
        setCode("")
        setIsSendCode(false)
    }
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}><div>{GlobalUtil.t("google_verification.enable_double_verfivication")}</div><div>{GlobalUtil.t("google_verification.step_2")}</div></div>
        <div style={{ marginTop: StyleUtils.getHeight(10), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("security.pwdTitle")}</div>
        <div style={{ marginTop: StyleUtils.getHeight(5) }}>
            <AntdInput>
                <Input.Password
                    value={pwd}
                    style={{}}
                    allowClear
                    onChange={(e) => {
                        setPwd(e.target.value.trim())
                    }}
                    placeholder={GlobalUtil.t("google_verification.input_login_pwd")}
                />
            </AntdInput>
        </div>
        <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('register.emailCode')}</div>
        <div>
            <div style={{ display: "flex", flex: "row", marginTop: StyleUtils.getHeight(5) }}>
                <AntdInput>
                    <Input
                        value={emaliCode}
                        onChange={(e) => {
                            setEmailCode(e.target.value.trim())
                        }}
                        allowClear
                        placeholder={GlobalUtil.t("register.emailCodeTip")}
                    />
                </AntdInput>
                <AntdButton>
                    <Button disabled={isSendCode && !(isSendCode && count === 0)} style={{ marginLeft: StyleUtils.getWidth(10) }} onClick={async () => {

                        try {
                            let res = await Api.sendDoubleCheckCode(UserInfoManager.mainAccount?.email ?? "")
                            if (res.code === ApiCode.SUCCESS) {
                                setIsSendCode(true)
                                setCount(60)
                            } else {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                        } catch (error) {

                        }
                    }}>{(!isSendCode || count === 0) ? GlobalUtil.t(!isSendCode ? 'register.sendCode' : "register.reSendCode") : count + "s"}</Button>
                </AntdButton>
            </div>
        </div>
        <div style={{ marginTop: StyleUtils.getHeight(10), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("checkCode")}</div>
        <div style={{ marginTop: StyleUtils.getHeight(5) }}>
            <AntdInput>
                <Input
                    style={{}}
                    value={code}
                    allowClear
                    onChange={(e) => {
                        setCode(e.target.value.trim())
                    }}
                    placeholder={"000000"}
                />
            </AntdInput>
        </div>
        <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
            <div onClick={() => {
                clear()
                onPrev()
            }} style={{ color: true ? "#2A88FC" : "#D0D7DD", display: "flex", fontSize: "1.2rem" }}>
                {GlobalUtil.t("google_verification.go_back")}
            </div>
            <AntdButton>
                <Button loading={loading} disabled={disable} onClick={async () => {
                    setLoading(true)
                    try {
                        let res = await Api.setDoubleCheck(emaliCode, code, pwd, true)
                        if (res.code === ApiCode.SUCCESS) {
                            events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.SUCCESS,
                                message: GlobalUtil.t("google_verification.enable_gv_success")
                            })
                            UserInfoManager.getUserInfo()
                            onClick()
                            clear()
                        } else {
                            events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.ERROR,
                                message: res.msg ?? GlobalUtil.t("google_verification.enable_gv_failed")
                            })
                        }
                    } catch (e) {
                        events.emit(AppEvent.SHOW_MESSAGE, {
                            type: AppMessageType.ERROR,
                            message: GlobalUtil.t("google_verification.enable_gv_failed")
                        })
                    }
                    setLoading(false)
                }} type="primary" style={{ position: "absolute", right: StyleUtils.getWidth(20) }}>{GlobalUtil.t("google_verification.enable_double_verfivication")}</Button>
            </AntdButton>
        </div>

    </div>
}
const Step1View = (data: GoogleBindInfo | null, onClick: () => void) => {
    const [check, setCheck] = useState(false)
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <div><div style={{ color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("google_verification.enable_double_verfivication")}</div><div style={{ color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('google_verification.step_1')}</div></div>
        <div style={{ color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("google_verification.tips")}</div>
        <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: '1rem', justifyContent: 'center', marginTop: StyleUtils.getHeight(20) }}>
            {
                <div style={{ background: "white", padding: "0.5rem" }}>
                    <QRCode
                        id="qrCode"
                        value={data?.otpauth_url ?? ""}
                        size={120} // 二维码的大小
                        fgColor="#000000" // 二维码的颜色
                        style={{}}
                    />
                </div>
            }
            <div style={{ color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("google_verification.or")}</div>
            <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ display: "flex", width: "120px", padding: "4px 8px 4px 8px", borderRadius: StyleUtils.getWidth(8), background: "#F1F4FF", borderColor: "#2989FC", color: "#2989FC", ...StyleUtils.textWrap() }}>
                    {data?.base32_secret}
                </div>
                <AntdButton>
                    <Button onClick={(e) => {
                        if (data?.base32_secret) {
                            navigator.clipboard.writeText(data?.base32_secret ?? "")
                            events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.INFO,
                                message: GlobalUtil.t("google_verification.copy_succ")
                            })
                        }
                    }} style={{ marginLeft: StyleUtils.getWidth(8) }}>{GlobalUtil.t('copy')}</Button>
                </AntdButton>
            </div>
        </div>
        <div style={{ marginTop: StyleUtils.getHeight(20) }}>
            <Checkbox style={{ color: StyleUtils.theme().fontColor_1 }} checked={check} onChange={() => {
                setCheck(!check)
            }}>
                {GlobalUtil.t("google_verification.save_key_tips")}
            </Checkbox>
        </div>
        <div style={{ color: StyleUtils.theme().fontColor_1, padding: StyleUtils.getWidth(20) }}>
            <div onClick={() => {
                if (check) {
                    onClick()
                }
            }} style={{ cursor: "pointer", color: check ? "#2A88FC" : "#D0D7DD", display: "flex", bottom: "1rem", position: "absolute", right: StyleUtils.getWidth(20), fontSize: "1rem" }}>
                {GlobalUtil.t("google_verification.next")}
            </div>
        </div>
    </div>
}

const DoubleCheckView: React.FC<DoubleCheckProps> = (props) => {
    const clear = () => {
        setStep(1)
        setData(null)
    }
    const [step, setStep] = useState(1)
    const [data, setData] = useState<GoogleBindInfo | null>()
    const loadData = async () => {
        try {
            if (!props.show) {
                setData(null)
                return
            }
            let res = await Api.getGoogleBindInfo()
            if (res.code === ApiCode.SUCCESS) {
                setData(res.data ?? null)
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        loadData()
    }, [props.show])
    return <Modal
        width={"50%"}
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            props.onClick(false)
            clear()
        }}
        footer={[]}
    >
        <div style={{ display: (step === 1 ? "" : "none") }}>
            {Step1View(data ?? null, () => {
                setStep(step + 1)
            })}
        </div>
        <div style={{ display: (step === 2 ? "" : "none") }}>
            {Step2View(() => {
                setStep(step - 1)
            }, () => {
                props.onClick(true)
                clear()
            })}
        </div>
    </Modal>
}


export default DoubleCheckView;