import {Alert, Button} from "antd";
import React, {useState} from "react";
import {GlobalUtil} from "../utils/GlobalText";
import {UserInfoManager} from "../utils/UserInfoManager";
import {useNavigate} from "react-router-dom";
import Api from "../utils/Api";
import {ApiCode} from "../data/common";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {StyleUtils} from "../utils/Style";
import {AntdButton} from "../views/commonview";
import events from "../utils/events";
import {AppEvent} from "../data/events";
import CustomInput from "../components/CustomInput";
import "./login.css"

const LoginPage: React.FC = () => {
    const [changeStyle, setChangeStyle] = useState(true)
    events.on(AppEvent.ChangeStyle, (changeType: string) => {
        setChangeStyle(!changeStyle)
    })
    const navigate = useNavigate();
    let [isLoading, setLoading] = useState(false)
    const [emali, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [siteKey, setSiteKey] = useState("")
    const [token, setToken] = useState("")
    const [error, setError] = useState("")
    const canLogin = ((siteKey && token) || !siteKey) && password && emali
    return (
        <div className="top-doc " style={{
            background: `url(${StyleUtils.getImage("top_bg.png")}) no-repeat center center`,
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            padding:`${StyleUtils.getHeight(36)} 0`,
            justifyContent: "center"
        }}>
            <div className={"logincard"} style={{
                borderRadius: StyleUtils.getWidth(12),
                minHeight:StyleUtils.getWidth(280),
                background: StyleUtils.theme().boardBg,}}>
                <div style={{
                    marginTop: StyleUtils.getHeight(14),
                    color: StyleUtils.theme().fontColor_2,
                    fontSize: StyleUtils.getFont(28),
                    fontWeight: "500"
                }}>{GlobalUtil.t("login.title")}</div>
                {error && <Alert message={error} type="error"/>}
                <div style={{width: "80%", marginTop: StyleUtils.getHeight(9)}}>
                    <div style={{
                        marginBottom: StyleUtils.getWidth(14),
                        color: StyleUtils.theme().fontColor_1,
                        fontSize: StyleUtils.getFont(14),
                        fontWeight: "500"
                    }}>{GlobalUtil.t("login.emali")}</div>
                    <CustomInput
                        inputType={"text"}
                        placeholder={GlobalUtil.t("login.emaliTip")}
                        onChange={(value) => setEmail(value)}
                    />
                </div>
                <div style={{width: "80%", marginTop: StyleUtils.getHeight(12)}}>
                    <div style={{
                        marginBottom: StyleUtils.getWidth(12),
                        color: StyleUtils.theme().fontColor_1,
                        fontSize: StyleUtils.getFont(14),
                        fontWeight: "500"
                    }}>{GlobalUtil.t("login.password")}</div>
                    <CustomInput
                        inputType={"password"}
                        placeholder={GlobalUtil.t("login.passwordTip")}
                        onChange={(value) => setPassword(value)}
                    />
                </div>
                {
                    siteKey && <div style={{marginTop: StyleUtils.getHeight(12)}}>
                        <HCaptcha
                            sitekey={siteKey}
                            onVerify={(token, ekey) => {
                                setToken(token)
                            }}
                        />
                    </div>
                }
                <AntdButton>
                    <Button
                        size={"large"}
                        style={{
                            width: "80%",
                            padding: 0,
                            color: "white",
                            marginTop: StyleUtils.getHeight(20),
                            fontSize: StyleUtils.getFont(18)
                        }}
                        type="primary"
                        disabled={!canLogin}
                        loading={isLoading}
                        onClick={async () => {
                            document.cookie = "pool9527=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                            setLoading(true)
                            try {
                                if (emali.length === 0 || password.length == 0) {
                                    return
                                }
                                let res = await Api.login(emali, password, token)
                                if (res.code === 200) {//
                                    UserInfoManager.login()
                                    setLoading(false)
                                    navigate("/home")
                                } else {
                                    if (res.code === ApiCode.NEED_CHECK) {
                                        setSiteKey(res.data ?? "")
                                    }
                                    setError(res.msg ?? GlobalUtil.t("account_or_pwd_error"))
                                }
                            } catch (e) {
                                setError(GlobalUtil.t("account_or_pwd_error"))

                                console.log(e)
                            }
                            setLoading(false)
                        }}
                    >
                        {GlobalUtil.t("login.button")}
                    </Button>
                </AntdButton>

                <div style={{
                    marginTop: StyleUtils.getHeight(20),
                    position: "relative",
                    display: "flex", justifyContent: "space-between", width: "80%",
                    height: "50px"
                }}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "80%"}}>
                        <div style={{
                            fontSize: StyleUtils.getFont(12),
                            cursor: "pointer",
                            display: "flex",
                            position: "absolute",
                            right: "0",
                            color: StyleUtils.theme().mainButtonColor
                        }} onClick={() => {
                            navigate("/reset-password/step1")
                        }}> {GlobalUtil.t("login.forgetPwd")}</div>
                        <div style={{
                            fontSize: StyleUtils.getFont(12),
                            cursor: "pointer",
                            display: "flex",
                            position: "absolute",
                            left: "0",
                            color: StyleUtils.theme().mainButtonColor
                        }} onClick={() => {
                            navigate("/user/register")
                        }}>{GlobalUtil.t("login.register")}</div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default LoginPage;