import { Space, Table, Image, Typography } from "antd"
import EChartsReact from "echarts-for-react"
import * as echarts from 'echarts';
import moment from "moment"
import { ServerHashrateHistory, ServerWorker, ServerWorkerInfoResult } from "../../../../data/common"
import { GlobalUtil } from "../../../../utils/GlobalText"
import { StyleUtils } from "../../../../utils/Style"
import { AntdTable } from "../../../../views/commonview"
import { ColumnsType } from "antd/es/table"
import { Dispatch, useEffect, useState } from "react";
import { IWorkInfoManager } from "../../../../utils/WorkData";
import events from "../../../../utils/events";
import { AppEvent } from "../../../../data/events";


interface PcWorkerProps {
    // checkProps: object
    setSelectWorker: Dispatch<ServerWorker[]>
    selectWorkers: ServerWorker[],
    WorkInfoManager: IWorkInfoManager
    itemData: ServerWorkerInfoResult | null
    handleExpand: (record: ServerWorker) => void
    expandWorker?: ServerWorker | null | undefined
    historyData?: ServerHashrateHistory | null | undefined
}
export const PcWorkerInfoViewTable = (props: PcWorkerProps) => {
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setUpdate(!update)
    })
    const { selectWorkers, setSelectWorker, WorkInfoManager, itemData, handleExpand, expandWorker, historyData } = props
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: ServerWorker[]) => {
            setSelectWorker(selectedRows)
        },
        getCheckboxProps: (record: ServerWorker) => ({
            disabled: false,
            name: record.workerName,
        }),
    };
    let checkProps = {}
    if (!WorkInfoManager.shareUrl) {
        checkProps = {
            type: "checkbox",
            selectedRowKeys: selectWorkers.map((v, i) => { return v.workerName }),
            ...rowSelection,

        }
    }

    const columns: ColumnsType<ServerWorker> = [
        {
            title: GlobalUtil.t("miner"),
            dataIndex: 'workerName',
            align: "left",

            render(value: number, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                    {value}
                </Space>
            },
        },
        {
            title: GlobalUtil.t("work.15mHashrate"),
            dataIndex: 'hashrate15M',
            align: "left",
            render(value: number, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                    {value}
                </Space>
            },
        },
        {
            title: GlobalUtil.t("work.24hHashrate"),
            dataIndex: 'hashrate24H',
            align: "left",
            render(value: number, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                    {value}
                </Space>
            },
        },
        {
            title: GlobalUtil.t("work.rejectRate"),
            dataIndex: 'rejectedRate',
            align: "left",
            render(value, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} >
                    {value}%
                </Space>
            },
        },
        {
            title: GlobalUtil.t("work.submitTime"),
            dataIndex: 'lastTime',
            align: "left",

            render(value: number, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                    {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                </Space>
            },
        },
        {
            title: GlobalUtil.t("work.groupName"),
            dataIndex: 'groupName',
            align: "center",
            render(value: string, record, index) {
                return <Space style={{ display: "flex", fontSize: "0.8rem", justifyContent: "center", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                    {value && value.length ? value : "-"}
                </Space>
            },
        },
        {
            title: "",
            width: "56px",
            render(value: string, record, index) {
                let expand = expandWorker?.workerName === record.workerName
                return <Image preview={false} width={28} style={{ cursor: "pointer", }} src={!expand ? StyleUtils.ICON_EXPAND_DOWN : StyleUtils.ICON_EXPAND_UP} onClick={() => { handleExpand(record) }} />
            },

        }
    ];

    return (
        <AntdTable>
            <Table
                // bordered
                className={`WorkerTable  ${StyleUtils.isDark ? "tableD ckd" : "tableL ckl"}`}

                style={{ marginRight: StyleUtils.getWidth(12), marginLeft: StyleUtils.getWidth(12) }}
                rowSelection={checkProps}
                rowKey={(item) => { return item.workerName }}
                columns={columns}
                dataSource={itemData?.hashrate?.records ?? []}
                expandable={
                    {
                        expandedRowKeys: [expandWorker?.workerName ?? ""],
                        showExpandColumn: false,
                        expandRowByClick: false,
                        expandedRowClassName: () => {
                            return "expand_row"
                        },
                        rowExpandable: (record) => expandWorker?.workerName === record.workerName,
                        expandedRowRender: (item: ServerWorker) => {
                            const historyItems = historyData?.values ?? []
                            const option = {
                                color: ["#2F65FF"],
                                textStyle: {
                                    color: StyleUtils.theme().fontColor_1
                                },
                                tooltip: {
                                    trigger: "axis",
                                    axisPointer: {
                                        type: "shadow",
                                    },
                                    formatter: (params: any) => {
                                        var d = historyItems[params[0].dataIndex]
                                        return moment(d.time).format('YYYY-MM-DD HH:mm:ss') + `<br>  ${GlobalUtil.t("work.hashrate")}: ` + parseFloat(d.hashrate).toFixed(2) + ` ${historyData?.unit}`;
                                    }
                                },
                                toolbox: {
                                    show: false,
                                    feature: {
                                        brush: {
                                            type: ['lineX'], // 可以设置为 'line' 表示启用直线选择
                                        }
                                    }
                                },
                                brush: {
                                    toolbox: ['lineX'],
                                    xAxisIndex: 0,
                                    nameLocation: "start",
                                    brushLink: 'all',
                                    throttleType: 'debounce',
                                    throttleDelay: 300,
                                },
                                xAxis: {
                                    type: 'category',
                                    alignTicks: true,
                                    data: historyItems.map((item, index) => {

                                        return moment(item.time).format('HH:mm');
                                    }),

                                },
                                yAxis: {
                                    type: 'value',
                                    minInterval: 1,
                                },
                                series: [
                                    {
                                        name: GlobalUtil.t("home.workerNumber"),
                                        type: 'line',
                                        data: historyItems.map((item) => item.hashrate),
                                        barWidth: '100%',
                                        smooth: true,
                                        showSymbol: false,
                                        areaStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: "rgba(47,101,255,0.33)"
                                                },
                                                {
                                                    offset: 1,
                                                    color: "rgba(47,101,255,0)"
                                                }
                                            ])
                                        },
                                    },
                                ]
                            };

                            return <div style={{}}>
                                <Space size={20} style={{ width: '100%', justifyContent: "center", fontSize: "1rem", fontWeight: "500", textAlign: "center", color: StyleUtils.theme().fontColor_1 }}>
                                    <Typography.Text>
                                        {item.workerName} -
                                    </Typography.Text>
                                    <Typography.Text>
                                        {GlobalUtil.t("work.last24Hashrate")}
                                    </Typography.Text>

                                </Space>
                                <EChartsReact
                                    showLoading={!expandWorker}
                                    option={option}
                                    style={{ height: "18rem" }}
                                />
                            </div>
                        }

                    }
                }
            />
        </AntdTable>
    )
}