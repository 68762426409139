import { useState, useEffect } from "react"
import { ServerTotalRevenue, ServerIncomeInfo, ApiCode } from "../../../data/common"
import { AppEvent } from "../../../data/events"
import Api from "../../../utils/Api"
import events from "../../../utils/events"
import { PcCardView } from "./PcCardView"
import { MobileCardView } from "./MobileCardView"

interface IncomeCardViewProp {
    shareUrl: string
}

export const IncomeCardView: React.FC<IncomeCardViewProp> = (props) => {
    const [data, setData] = useState<ServerTotalRevenue | null>()
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.REFRES, () => {
        setUpdate(!update)
    })
    const [incomeData, setIncomeData] = useState<ServerIncomeInfo[]>([])

    const loadIncomeData = async () => {
        try {
            let res = await Api.getIncomeList(props.shareUrl,1,100)
            if (res.code) {
                setIncomeData((res.data?.records ?? []))

            }
        } catch (error) {

        }

    }
    useEffect(() => {
        loadData();
        loadIncomeData()
    }, [update])
    const loadData = async () => {
        try {
            let res = await Api.getTotalRevenue(props.shareUrl)
            if (res.code === ApiCode.SUCCESS) {
                setData(res.data)
            }
        } catch (error) {

        }
    }
    return <>
        <PcCardView data={data} incomeData={incomeData} />
        <MobileCardView data={data} incomeData={incomeData}/>
    </ >
}