const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const usernameRegex=/^(?!^[0-9])([a-z0-9]{2,30})$/;
const limtPasswordRegex =
/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/


const isValidPwd=(pwd:string):boolean=>{
  return limtPasswordRegex.test(pwd)
}
const isValidUserName=(name:string):boolean=>{
  return usernameRegex.test(name)
}
const isValidEmail = (email: string): boolean => {
  return emailRegex.test(email);
}
const formatStr = (...params: any[]): string => { //字符串中赋值变量
  if (params.length === 0)
    return "";
  var str = params[0] as string;
  for (var i = 1; i < params.length; i++) {
    var re = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
    str = str.replace(re, params[i]);
  }
  return str;
};
export { isValidEmail, formatStr,isValidUserName ,isValidPwd}