import { Button, Card, Col, Input, Row, Timeline } from "antd"
import { GlobalUtil } from "../../utils/GlobalText"
import { StyleUtils } from "../../utils/Style"
import { UserInfoManager } from "../../utils/UserInfoManager"
import { AntdButton, AntdCard, AppButton, AppMainText } from "../../views/commonview"
import { ChangeEvent, useEffect, useState } from "react"
import { deepCopy } from "../../utils/deepCopy"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface SecurityData {
    title: string | undefined
    value: string | undefined
}
interface InfoItemViewProp {
    title?: string
    text?: string,
    leftSpan?: number,
    rightSpan?: number,
    isCopy?: boolean;
    onChange?: (value: string, title?: string) => void
}

const InfoItemView: React.FC<InfoItemViewProp> = (props) => {
    const handleInputChange = (value: string) => {
        props.onChange && props.onChange(value, props.title)
    }
    return <Row style={{background: StyleUtils.theme().home_guide_item_color_2, border: "solid", borderColor: StyleUtils.theme().home_guide_item_color_1, borderWidth: "0.5px", width: "100%", display: "flex", flexFlow: "row nowrap", borderRadius: "8px" }}>
        <Col span={props.leftSpan ?? 8} style={{ display: "flex", whiteSpace: "nowrap", alignItems: "center", justifyContent: "center", color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", fontWeight: "500", borderRadius: "8px 0px 0px 8px", background: StyleUtils.theme().home_guide_item_color_1, height: "100%", padding: '4px 12px', maxWidth: "180px" }}>
            {props.title}
        </Col>
        <Input
            className="example-input"
            style={{
                ...StyleUtils.textWrap(),
                color: StyleUtils.theme().fontColor_1,
            }} value={props?.text} onChange={(evt) => {
                handleInputChange(evt.target.value);
            }} />
    </Row>
}

export const HomeGuideView = () => {

    const [expInputs, setExpInputs] = useState<SecurityData[]>([
        {
            "title": GlobalUtil.t("home_guide.account_name"),
            "value": "account1",
        },
        {
            "title": GlobalUtil.t("home_guide.worker_name"),
            "value": "miner1",
        },
        {
            "title": GlobalUtil.t("home_guide.pool_address"),
            "value": "stratum+tcp://aleo.drpool.io:30127",
        }
    ]);
    const Items2: SecurityData[] = [
        {
            "title": "leoMiner",
            "value": `./dr-worker -w ${expInputs[0].value}.${expInputs[1].value} -u ${expInputs[2].value} -d 0 `,
        }
    ]
    const handleExpInputs = (value: string, title?: string) => {
        const findIndex = expInputs.findIndex((item) => item.title === title);
        if (findIndex !== -1) {
            const expDup = deepCopy(expInputs);
            expDup[findIndex].value = value;
            setExpInputs(expDup);
        }
    }
    useEffect(() => {
        const transExp = [
            {
                "title": GlobalUtil.t("home_guide.account_name"),
                "value": expInputs[0].value,
            },
            {
                "title": GlobalUtil.t("home_guide.worker_name"),
                "value": expInputs[1].value,
            },
            {
                "title": GlobalUtil.t("home_guide.pool_address"),
                "value": expInputs[2].value,
            }
        ]
        setExpInputs(transExp);
    }, [GlobalUtil.globalT])
    return <div id="startMning">
        <Row justify={"center"} style={{ color: StyleUtils.theme().fontColor_1, fontSize: "2.4rem", alignItems: "center", marginTop: StyleUtils.getWidth(36), marginBottom: StyleUtils.getWidth(18) }}>

            <div style={{ fontWeight: "500" }}>
                Start mining
            </div>
            <div style={{ width: StyleUtils.getWidth(3) }} />
            <AppMainText text={UserInfoManager.getShowCurrency()} />
            <div style={{ width: StyleUtils.getWidth(3) }} />
            <div style={{ fontWeight: "500" }}>
                now
            </div>

        </Row>
        <Timeline
            className="tml"
            items={[
                {
                    children: <AntdCard><Card>
                        <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("home_guide.create_wallet")}</div>
                        <a
                            href="https://chrome.google.com/webstore/detail/leo-wallet/nebnhfamliijlghikdgcigoebonmoibm?utm_source=app-launcher"
                            target="_blank"
                            style={{ marginTop: StyleUtils.getHeight(2) }} rel="noreferrer">
                            Leo {GlobalUtil.t("home_guide.wallet")}
                        </a>
                    </Card>
                    </AntdCard>
                },
                {
                    children: <AntdCard><Card>
                        <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("home_guide.download")}</div>
                        <div style={{ ...StyleUtils.fontStyle_3(), marginTop: StyleUtils.getHeight(5) }}>{GlobalUtil.t("home_guide.download_desc")}</div>
                        <Row style={{ marginTop: StyleUtils.getHeight(3), columnGap: ".5rem" }}>
                            <a href="https://ipfs.io/ipfs" target="_blank">leoMiner</a>
                            {/*                             <a href="111" >BZMiner</a> */}
                            {/*                             <a href="111" >SRBMiner-MULTI</a> */}
                            {/*                             <a href="111" >Rigel</a> */}
                            {/*                             <a href="111" >TeamRed Miner</a> */}
                        </Row>
                    </Card>
                    </AntdCard>
                },
                {
                    children: <AntdCard><Card>
                        <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("home_guide.input_data")}</div>
                        <div style={{ ...StyleUtils.fontStyle_3(), marginTop: StyleUtils.getHeight(5) }}>{GlobalUtil.t("home_guide.input_account_and_workername")} </div>
                        <div style={{ ...StyleUtils.fontStyle_3(), marginTop: StyleUtils.getHeight(5) }}>{GlobalUtil.t("home_guide.change_for_start_miner")}</div>
                        {/* <div style={{ ...StyleUtils.fontStyle_2(), marginTop: StyleUtils.getHeight(9) }}>{GlobalUtil.t("home_guide.example")}</div> */}
                        <div style={{ marginTop: StyleUtils.getHeight(3), display: "flex",  }}>
                            <div style={{ flexWrap: "nowrap", display: "flex", width: '100%'}}>
                                {
                                    expInputs.flatMap((v, index) => {
                                        return <div key={v.title} style={{flex:'1 1 33%',  marginRight: StyleUtils.getWidth(20), marginBottom: StyleUtils.getHeight(7) }} >
                                            <InfoItemView title={v.title} text={v.value} onChange={handleExpInputs} />
                                        </div>
                                    })

                                }
                            </div>
                        </div>
                    </Card>
                    </AntdCard>
                },
                {
                    children: <AntdCard> <Card>
                        <div style={{ ...StyleUtils.fontStyle_2(), fontSize: StyleUtils.getFont(14) }}>{GlobalUtil.t("home_guide.start_miner")}</div>
                        <div style={{ ...StyleUtils.fontStyle_3(), marginTop: StyleUtils.getHeight(5) }}>{GlobalUtil.t("home_guide.start_miner_desc1")} sudo chmod +x dr-worker</div>
                        <div style={{ ...StyleUtils.fontStyle_3(), marginTop: StyleUtils.getHeight(5) }}>{GlobalUtil.t("home_guide.start_miner_desc2")}</div>
                        <div style={{ ...StyleUtils.fontStyle_2(), marginTop: StyleUtils.getHeight(9) }}>{GlobalUtil.t("home_guide.command_below")}</div>
                        <div style={{ marginTop: StyleUtils.getHeight(3), display: "flex", flexDirection: 'row' }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                {
                                    Items2.flatMap((v, index) => {
                                        return <div key={v.title} style={{ marginTop: StyleUtils.getWidth(11), width: "100%" }} >
                                            <InfoItemView title={v.title} text={v.value} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        <div style={{ width: 'fit-content', marginTop: StyleUtils.getHeight(5) }}>
                            {/* <AppButton
                                style={{
                                    display: 'block'
                                }}
                                onClick={() => {
                                    setOpenHelp(true)
                                }}
                                text=" Miner Help" /> */}
                            <Link to="/help/aleo" >{GlobalUtil.t("home_guide.miner_help")}</Link>
                        </div>

                    </Card>
                    </AntdCard>
                }
            ]}
        />
        {/* <MinerHelp open={true} onClose={handleCloseHelpDrawer} /> */}
    </div>
}