import { Button, Input, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode } from '../../../../data/common';
import Api from '../../../../utils/Api';
import { GlobalUtil } from '../../../../utils/GlobalText';
import { StyleUtils } from '../../../../utils/Style';
import { UserInfoManager } from '../../../../utils/UserInfoManager';
import events from '../../../../utils/events';
import { AppEvent, AppMessageType } from '../../../../data/events';
import { AntdButton, AntdInput } from '../../../../views/commonview';


const DeleteAccountView = ({ account, show, onClick }: any) => {
    let [loading, setLoading] = useState(false)
    let [code, setCode] = useState("")
    useEffect(() => {
        if (!show) {
            setCode("")
        }
    }, [show])
    return <Modal
        open={show}
        maskClosable={false}
        onCancel={() => {
            onClick && onClick(false)
        }}
        title={GlobalUtil.t("account.deleteAccount")}
        footer={[
            <AntdButton>
                <Button
                    key="link"
                    type="primary"
                    disabled={!code}
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        try {
                            let res = await Api.deleteSubAccount(account, code)
                            if (res.code === ApiCode.SUCCESS) {
                                UserInfoManager.getAccountInfoWithHashrate()
                                UserInfoManager.getAccountInfo()
                                onClick && onClick(true)
                            } else {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: res.msg ?? GlobalUtil.t("errorTip")
                                })
                            }

                        } catch (e) {
                            events.emit(AppEvent.SHOW_MESSAGE, {
                                type: AppMessageType.ERROR,
                                message: GlobalUtil.t("errorTip")
                            })
                        }

                        setLoading(false)
                    }}
                    style={{ width: "100%" }}
                >
                    {GlobalUtil.t("confirm")}
                </Button>
            </AntdButton>
        ]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginTop: StyleUtils.getHeight(10), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("checkCode")}</div>
            <div style={{ marginTop: StyleUtils.getHeight(8) }}>
                <AntdInput>
                    <Input
                        style={{}}
                        value={code}
                        placeholder={"000000"}
                        onChange={(e) => {
                            setCode(e.target.value.trim())
                        }}
                    />
                </AntdInput>
            </div>
        </div>
    </Modal>



}

export default DeleteAccountView;