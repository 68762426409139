import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { MobileFooter } from "./MobileFooter"
import { PcFooter } from "./PcFooter"
import "./index.css"

export const Footer = () => {
    const { xl } = useBreakpoint()
    return (
        xl ?
            <PcFooter /> :
            <MobileFooter />

    )
}