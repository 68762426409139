import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomePage from "./page/home/HomePage";
import LoginPage from "./page/LoginPage";
import NotFoundPage from "./page/NotFoundPage";
import AccountSettings from "./page/setting/SettingPage"
import { UserInfoManager } from "./utils/UserInfoManager";
import { GlobalUtil } from "./utils/GlobalText";
import { useEventBus } from "./utils/events";
import RegisterPage from "./page/RegisterPage";
import { AppEvent, AppMessageEvent, AppMessageType } from "./data/events";
import { message } from "antd";
import { GlobalModal } from "./page/GlobalModal";
import ActiveAwaitAddressPage from "./page/ActiveAwaitAddressPage";
import WorkManagerPage from "./page/work/WorkManagerPage";
import IncomePage from "./page/income/IncomePage";
import SharePage from "./page/share/SharePage";
import { StyleUtils } from "./utils/Style";
import ForgetStep1Page from "./page/ForgetStep1Page";
import ForgetStep2Page from "./page/ForgetStep2Page";
import { useTranslation } from "react-i18next";

import { Footer, Header } from "./components";
import { AleoGuidePage } from "./page/home/MinerGuide/AleoGuidePage";

const ROUTERS = [
  {
    path: "/",
    element: <HomePage />,
    login: false
  },
  {
    path: '/help/aleo',
    element: <AleoGuidePage />,
    login: false
  },
  {
    path: "/revenue",
    element: <IncomePage />,
    login: true
  },
  {
    path: "/miner/:id",
    element: <SharePage />,
    login: false
  },
  {
    path: "/home",
    element: <HomePage />,
    login: false
  }, {
    path: "/work",
    element: <WorkManagerPage />,
    login: true
  }, {
    path: "/user/setting",
    element: <AccountSettings />,
    login: true
  }, {
    path: "/user/login",
    element: <LoginPage />,
    login: false
  }, {
    path: "/user/register",
    element: <RegisterPage />,
    login: false
  }, {
    path: "/reset-password/step1",
    element: <ForgetStep1Page />,
    login: false
  }, {
    path: "/reset-password/step2",
    element: <ForgetStep2Page />,
    login: false
  }, {
    path: "/user/activate_await_address",
    element: <ActiveAwaitAddressPage />,
    login: false
  }

]

const App: React.FC = () => {
  let { t } = useTranslation();
  GlobalUtil.init(t)
  const location = useLocation();
  const [refresh, setRefresh] = useState(0)
  const [messageApi, contextHolder] = message.useMessage();
  StyleUtils.init()
  const navigator = useNavigate()

  async function initUserInfo() {
    if (!location.pathname.startsWith("/share")) {
      await UserInfoManager.initWithLogin()
    }
  }

  useEffect(() => {
    initUserInfo()
  }, [UserInfoManager._login, location.pathname])

  useEventBus(AppEvent.SHOW_MESSAGE, (message: AppMessageEvent) => {
    if (message.type === AppMessageType.ERROR) {
      messageApi.error(message.message)
    } else if (message.type === AppMessageType.INFO) {
      messageApi.info(message.message)
    } else if (message.type === AppMessageType.SUCCESS) {
      messageApi.success(message.message)
    }
  })
  useEventBus(AppEvent.SHARE_REFRES, (changeType: string) => {
    setRefresh(refresh + 1)
  })
  useEventBus(AppEvent.LOGOUT, (changeType: string) => {
    if (ROUTERS.find((v) => {
      return location.pathname && location.pathname.startsWith(v.path)
    })?.login === false) {
      setRefresh(refresh + 1)
      return
    }
    navigator("/user/login")
  })
  useEventBus(AppEvent.REFRES, (changeType: string) => {
    setRefresh(refresh + 1)
  })
  const [changeStyle, setChangeStyle] = useState(true)
  useEventBus(AppEvent.ChangeStyle, (changeType: string) => {
    setChangeStyle(!changeStyle)
  })
  useEventBus(AppEvent.ON_ACCOUNT_INFO_UPDATE, (changeType: string) => {
    setRefresh(refresh + 1)
  })

  return (
    <div style={{ display: "flex", flexDirection: "column", background: StyleUtils.theme().mainColor, width: "100%", minHeight: "100vh", position: 'relative' }}>
      {contextHolder}
      <GlobalModal />
      <Header />
      {
        <div className={`main-box ${StyleUtils.isDark ? "scrollD" : "scrollL"}`} >
          <Routes location={location}>
            {
              ROUTERS.map((value, index) => {
                let isLogin = (location.pathname === "/user/login")
                return <Route
                  key={value.path}
                  path={value.path}
                  element={
                    value.login && !UserInfoManager.isLogin() ? <Navigate to={"/user/login"} /> : ((isLogin) && UserInfoManager.isLogin() ? <Navigate to={"/home"} /> : value.element)
                  }
                />
              })
            }
          </Routes>
        </div>
      }
      <Footer />
    </div>
  );
};

export default App;
