


import { Card, Col, Image, Row } from "antd"
import { CSSProperties, useEffect, useState } from "react"
import Api from "../../../utils/Api"
import { ApiCode, ServerIncomeInfo, ServerTotalRevenue } from "../../../data/common"
import events from "../../../utils/events"
import { AppEvent } from "../../../data/events"
import { StyleUtils } from "../../../utils/Style"
import { GlobalUtil } from "../../../utils/GlobalText"
import { formatStr } from "../../../utils/Utils"
import EChartsReact from "echarts-for-react"
import * as echarts from 'echarts';
import moment from "moment"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"

interface IncomeLabeViewProp {
    title: string
    value: number
    icon: string,
    list?: ServerIncomeInfo[]
}
export const IncomeLabeView: React.FC<IncomeLabeViewProp> = (props) => {
    const data = props.list ?? []
    const { xs } = useBreakpoint()
    let option = {
        tooltip: {
            trigger: 'axis',
        },

        xAxis: {
            type: 'category',
            show: false,
            data: (data).reverse().flatMap((v) => { return moment(v.settleDate).format('YYYY-MM-DD HH:mm:ss'); })
        },
        yAxis: {
            type: 'value',
            axisLine: {
                onZero: false
            },
            splitLine: {
                show: false
            },
            scale: true,
            // min: function (value: { max: number, min: number }) {
            //     return (value.min).toFixed(0);
            // },
            splitNumber: 2,
        },
        grid: {
            bottom: 0,
            top: "10%",
            left: 0,
            right: 0,
            containLabel: true
        },
        series: [
            {
                data: (data).flatMap((v) => {
                    return v.amount
                }),
                type: 'line',
                smooth: true,
                showSymbol: false,
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(47,101,255,0.33)"
                        },
                        {
                            offset: 1,
                            color: "rgba(47,101,255,0)"
                        }
                    ])
                },
            }
        ]
    };

    return <Card className="card-view-item" bodyStyle={{ padding: 0, width: "100%" }} style={{ background: StyleUtils.theme().boardBg, borderColor: StyleUtils.theme().boardBg, width: "100%", borderRadius: StyleUtils.getWidth(6), padding: "0px" }}>
        <Row className="view-item-row" align={"middle"} style={{ padding: xs ? 0 : StyleUtils.getWidth(18), rowGap: "1rem" }}>
            <Col className="view-item-text" flex={"1 1 auto"}>
                {xs && !props.list ?
                    <div>
                        <Row justify={"center"} align={"middle"} style={{ columnGap: "1rem" }}>
                            <div style={{ width: "2rem", height: "2rem", display: "flex", alignItems: "center" }}>
                                <Image preview={false} style={{ display: "flex", height: "auto", maxWidth: "3rem", maxHeight: "3rem", }} src={props.icon} />
                            </div>
                            <div style={{ fontWeight: "500", color: "#899199", fontSize: "1rem", ...StyleUtils.textWrap() }}>
                                {props.title}
                            </div>
                        </Row>
                        <Row justify={"center"} style={{ color: StyleUtils.theme().fontColor_2, fontSize: "1.2rem", fontWeight: "bold", marginTop: "0.5rem" }}>
                            {props.value ? props.value.toLocaleString(undefined, { minimumFractionDigits: 4 }).replace(/\.?0+$/, '') : props.value}
                        </Row>
                    </div>
                    :
                    <>
                        <div >
                            <Image className="image" preview={false} style={{ display: "flex", height: StyleUtils.getHeight(46), width: StyleUtils.getWidth(45.5) }} src={props.icon} />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", }}>
                            <div style={{ fontWeight: "500", color: "#899199", fontSize: "1rem", ...StyleUtils.textWrap() }}>
                                {props.title}
                            </div>
                            <div style={{ color: StyleUtils.theme().fontColor_2, fontSize: "1.2rem", fontWeight: 500, marginTop: "0.25rem" }}>
                                {props.value ? props.value.toLocaleString(undefined, { minimumFractionDigits: 7 }).replace(/\.?0+$/, '') : props.value}
                            </div>
                        </div>
                    </>
                }

            </Col>
            {
                props.list &&
                <div style={{ flex: "1 1 50%", width: "auto", height: "100%" }}>
                    <EChartsReact
                        className="echarts"
                        option={option}
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                    />

                </div>
            }
        </Row>
    </Card>

}
