import { Col, Dropdown, MenuProps, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { UserInfoManager } from "../utils/UserInfoManager";
import { StyleUtils } from "../utils/Style";
import { GlobalUtil } from "../utils/GlobalText";
import events from "../utils/events";
import { AppEvent } from "../data/events";
import Api from "../utils/Api";

const LanguageView: React.FC = () => {
    const [isOpen, setOpen] = useState(false)
    const itemValues = [
        { name: "English", url: "/assets/english.png" },
        { name: "简体中文", url: "/assets/chinese.png" },
    ]
    const values = [
        "en_US","zh_CN"
    ]
    let index = (UserInfoManager.language && UserInfoManager.language.startsWith("zh") ? 1 : 0) ?? 0
    const items: MenuProps['items'] = itemValues.map((v, index) => {
        return {
            key: index,
            label: <div style={{ display: "flex", alignItems: "center", color: StyleUtils.theme().fontColor_1 }} onClick={async () => {
                setOpen(false)
                if (values[index] === UserInfoManager.language) {
                    return
                }
                try {
                    UserInfoManager.language = values[index]
                    let res = await Api.setCurEnvInfo(UserInfoManager.currentAccount, UserInfoManager.currency, values[index])
                    GlobalUtil.changeLanguage(values[index])
                    // if (res.code === ApiCode.SUCCESS) {
                    events.emit(AppEvent.REFRES, { "language": true });
                    events.emit(AppEvent.ChangeStyle, "")
                    // }
                } catch (error) {
                    console.log(error);
                }

            }}><img style={{ width: 20, height: 20, margin: "4px 4px 0 0" }} src={v.url} alt={values[index]} /> {v.name}</div>,
        }
    })
    return <Dropdown
        menu={{ items }}
        trigger={['click']}
        getPopupContainer={(node) => {
            return document.getElementById('language')!
        }}
        onOpenChange={(open) => {
            setOpen(open)
        }}>
        <Row className="flex-grow">
            <div onClick={(e) => {
                e.preventDefault()
            }} style={{
                background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5),
                textDecoration: "none",
                cursor: "pointer"
            }} className="user">
                <Space className="user-lang" style={{ color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", display: "flex", alignItems: "center", }}>
                    <img style={{ width: 19, height: 19 }} src={itemValues[index].url} alt="" /> {itemValues[index].name}
                    {isOpen ? <UpOutlined /> : <DownOutlined />}
                </Space>
            </div>
        </Row>
    </Dropdown>
}

export default LanguageView