import {AntdInput} from "../views/commonview";
import {Input} from "antd";
import {StyleUtils} from "../utils/Style";
import React from "react";
import { EyeTwoTone ,EyeInvisibleOutlined} from "@ant-design/icons";


interface CustomInputProps {
    inputType: "password" | "text",
    placeholder: string
    onChange: (value: string) => void
}

export default function CustomInput(props: CustomInputProps) {
    const {inputType, onChange, placeholder} = props
    return (<AntdInput
    >
        {
            inputType === "password" ?
                <Input.Password
                    size={"large"}
                    style={{
                        fontSize: StyleUtils.getFont(14),
                        color: "#899199",
                        fontWeight: "400"
                    }}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    placeholder={placeholder}
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                /> : <Input
                    allowClear
                    size={"large"}
                    style={{
                        fontSize: StyleUtils.getFont(14),
                        color: "#899199",
                        fontWeight: "400"
                    }}
                    onChange={(e) => {
                        onChange(e.target.value)
                    }}
                    placeholder={placeholder}

                />
        }

    </AntdInput>)
}