import React, { useEffect, useState } from "react";
import { StyleUtils } from "../../utils/Style";
import { NewBlockView } from "./NewBlockView";
import { HomeTopView } from "./HomeTopView";
import HomeAccountTableView from "./HomeAccountTableView";
import events, { useEventBus } from "../../utils/events";
import { AppEvent } from "../../data/events";
import { UserInfoManager } from "../../utils/UserInfoManager";
import { HomeGuideView } from "./HomeGuideView";
import "./index.css"
import { AppButton, AppMainText } from "../../views/commonview";



const HomePage: React.FC = () => {
  const [changeStyle, setChangeStyle] = useState(true)
  events.on(AppEvent.ChangeStyle, (changeType: string) => {
    setChangeStyle(!changeStyle)
  })
  useEventBus(AppEvent.REFRES, (changeType: string) => {
    setChangeStyle(!changeStyle)
  })

  return (
    <div style={{ width: "100%", justifyContent: "center", paddingBottom: StyleUtils.getWidth(36), background: StyleUtils.theme().mainColor }}>
      {(UserInfoManager.currency || localStorage.getItem('currency')) && <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="top-doc" style={{ background: `url(${StyleUtils.getImage("top_bg.png")}) no-repeat center center`, width: "auto", paddingTop: StyleUtils.getHeight(45), paddingBottom: StyleUtils.getHeight(45) }}>
           <div style={{ color: StyleUtils.theme().fontColor_1, zIndex: 1, display: "flex", flexFlow: "row wrap", columnGap: ".5rem", justifyContent: "center" }}>
            <div style={{ ...StyleUtils.fontStyle_2, fontSize: StyleUtils.getFont(32), whiteSpace: "nowrap" }}>
                Your All-in-One
            </div>
          </div>
          <div style={{ color: StyleUtils.theme().fontColor_1, zIndex: 1, display: "flex", flexFlow: "row wrap", columnGap: ".5rem", justifyContent: "center" }}>
            <AppMainText text="Solution for Crafting Your Digital Destiny" style={{  overflow: "visible", whiteSpace:'pre-wrap', textAlign:'center'}} fontSize={StyleUtils.getFont(32)} />
          </div>
          <div style={{ color: StyleUtils.theme().fontColor_1, lineHeight: "22px", zIndex: 1, display: "flex", padding: "0 1rem", flexDirection: "column", alignItems: "center", marginTop: StyleUtils.getHeight(7) }}>
            <div style={{ ...StyleUtils.fontStyle_1, display: "flex", marginTop: StyleUtils.getHeight(2) }}>
              We are building a decentralized ecosystem to empower the global digital economy.
            </div>
            <div style={{ ...StyleUtils.fontStyle_1, display: "flex", marginTop: StyleUtils.getHeight(2) }}>
              Leveraging blockchain technology for transparent, secure, and seamless digital interactions,
            </div>
            <div style={{ ...StyleUtils.fontStyle_1, display: "flex", marginTop: StyleUtils.getHeight(2) }}>
              We are opening a new chapter of global connectivity.
            </div>
          </div>
          <div style={{ zIndex: 1, display: "flex", alignItems: "center", justifyContent: "center", marginTop: StyleUtils.getHeight(27) }} >
            <AppButton onClick={() => {
              //
              let element = document.getElementById("startMning")
              window.scrollTo({ top: element?.offsetTop, behavior: "smooth" })
            }} style={{ padding: "0.6rem 1rem 0.6rem 1rem" }} text="Start Mining Now" />
          </div>
        </div>
        <HomeTopView />
        <HomeAccountTableView />
        <NewBlockView />
        <HomeGuideView />
      </div>}
    </div>
  )
};

export default HomePage;
