import { Card, Row } from "antd"
import { GlobalUtil } from "../../utils/GlobalText"
import { useEffect, useState } from "react"
import events from "../../utils/events"
import { AppEvent } from "../../data/events"
import { ApiCode, ServerHomeInfo } from "../../data/common"
import Api from "../../utils/Api"
import { StyleUtils } from "../../utils/Style"
import { AppMainText } from "../../views/commonview"

interface TopViewProp {
    title?: string
    value?: string
}


const TopView: React.FC<TopViewProp> = (props) => {
    let v: string[] | "" = []
    // 对有符号的值进行切分，以达到符号以更小字号显示要求
    if (props.value?.endsWith("%")) {
        const num = parseInt(props.value.slice(0, props.value.length - 1));
        const symbol = props.value.slice(props.value.length - 1);
        v = [num.toString(), symbol]
    } else {
        v = props.value?.split(/[\s]+/) ?? ""
    }

    return <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
        <Row style={{ alignItems: "baseline", columnGap: ".5rem" }}>
            <AppMainText text={v[0] ?? ""} />
            {v[1] && <AppMainText fontSize="1.5rem" text={v[1]} />}
        </Row>
        <Row style={{ color: StyleUtils.theme().fontColor_1, textAlign: "center" }}>
            {props.title}
        </Row>
    </div>
}
export const HomeTopView = () => {
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.REFRES, ({ currency, language }: any) => {
        if (currency || language) {
            setUpdate(!update)
        }
    })
    const [homeInfo, setHomeInfo] = useState<ServerHomeInfo | null>()
    const loadData = async () => {
        try {
            let res = await Api.getHomeInfo()
            if (res.code === ApiCode.SUCCESS) {
                setHomeInfo(res.data)
            }
        } catch (error) {

        }
    }
    useEffect(() => { loadData() }, [update])

    return <Card style={{ width: "100%", background: StyleUtils.theme().boardBg, border: "none", }}>
        <div className="top-view">
            <TopView title={GlobalUtil.t("home.hashrate")} value={homeInfo?.hashrate} />
            <TopView title={GlobalUtil.t("home.activeMiners")} value={homeInfo?.miners?.toString()} />
            <TopView title={GlobalUtil.t("home.workers")} value={homeInfo?.workers?.toString()} />
            <TopView title={GlobalUtil.t("home.blocks")} value={homeInfo?.blocks} />
            <TopView title={GlobalUtil.t("home.price")} value={homeInfo?.feeRate} />
        </div>
    </Card>
}